import IFormModel from "@/models/IFormModel";
import TranslatedValueCreate from "@/models/translated-value/TranslatedValueCreate";
import {ValidationRule} from "@/declarations/ValidationRule";

export default class CategoryCreate implements IFormModel {

	public externalId: string | null = null;
	public name: TranslatedValueCreate = new TranslatedValueCreate();
	public description: TranslatedValueCreate = new TranslatedValueCreate();

	public reset() {
		this.externalId = null;
		this.name = new TranslatedValueCreate();
		this.description = new TranslatedValueCreate();
	}

	public rules(property: string): ValidationRule[] {
		//TODO: add rules
		switch (property) {
			default:
				return [];
		}
	}
}
