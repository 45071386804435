import {ValidationRule} from "@/declarations/ValidationRule";
import ModelUpdate from "@/models/ModelUpdate";
import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";

export default class CustomerUpdateNumbers extends ModelUpdate<ICustomerAdmin> {

	public customerNumber!: string | null;
	public externalId!: string | null;

	public constructor(model: ICustomerAdmin) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: ICustomerAdmin): void {
		this.customerNumber = detailModel.customerNumber;
		this.externalId = detailModel.externalId;
	}

	public rules(): ValidationRule[] {
		return [];
	}

}
