import ItemListFilter from "@/models/item/ItemListFilter";
import {StockStatus} from "@/helpers/constants";

export default class ItemListFilterShop extends ItemListFilter {

	public stockStatus: StockStatus[] = [];
	public relatedToItemIds: string[] = [];

	public clear() {
		super.clear();
		this.stockStatus = [];
		this.relatedToItemIds = [];
	}

	public hasFilter(): boolean {
		return super.hasFilter() || this.stockStatus.length > 0;
	}

}
