import {IAddressFields} from "@/models/address/AddressModels";
import StringHelper from "@/helpers/StringHelper";
import i18n from "@/plugins/i18n";
import CountryHelper from "@/helpers/CountryHelper";
import {SampleOrderSummaryDisplay} from "@/models/sample-order/SampleOrderAddress";
import {Salutation} from "@/helpers/constants";
import EnumHelper from "@/helpers/EnumHelper";
import ContactFormSummary from "@/models/contact/ContactFormModels";

export default class AddressHelper {

	public static addressLines(model: IAddressFields): string[] {
		return [
			model.company,
			this.fieldsToLine(this.getSalutation(model.salutation), model.title, model.firstname, model.middlename, model.lastname),
			model.address1,
			model.address2,
			this.fieldsToLine(model.street, model.houseNumber),
			model.poBox,
			this.fieldsToLine(model.zip, model.city),
			CountryHelper.countryName(model.countryCode),
		].filter(l => l !== null) as string[];
	}

	public static addressLinesSampleOrder(model: SampleOrderSummaryDisplay): string[] {
		return [
			model.company,
			this.fieldsToLine(this.getSalutation(model.salutation), model.firstname, model.lastname),
			this.fieldsToLine(model.street, model.houseNumber),
			model.poBox,
			this.fieldsToLine(model.zip, model.city),
			CountryHelper.countryName(model.countryCode),
		].filter(l => l !== null) as string[];
	}

	public static addressLinesContactForm(model: ContactFormSummary): string[] {
		return [
			model.company,
			this.fieldsToLine(this.getSalutation(model.salutation), model.firstname, model.lastname),
			this.fieldsToLine(model.street, model.houseNumber),
			this.fieldsToLine(model.zip, model.city),
		].filter(l => l !== null) as string[];
	}

	public static assertedLabel(model: IAddressFields): string {
		if (model.label !== null) return model.label;
		const lines = [
			this.fieldsToLine(model.street, model.houseNumber),
			model.poBox,
			this.fieldsToLine(model.zip, model.city),
			model.countryCode,
		].filter(l => l !== null) as string[];

		return lines.length > 0 ? lines.join(', ') : i18n.t('address.singular').toString();
	}

	private static fieldsToLine(...data: (string | null)[]): string | null {
		const ret = data.filter(e => e !== null).join(' ');
		return StringHelper.isEmpty(ret) ? null : ret;
	}

	public static geocodingString(model: IAddressFields, separator: string = ',', urlEncode: boolean = true): string {
		const str = [
			model.company,
			model.address1,
			model.address2,
			this.fieldsToLine(model.street, model.houseNumber),
			this.fieldsToLine(model.zip, model.city),
			model.countryCode,
		]
			.filter(l => l !== null)
			.join(separator);

		return urlEncode ? encodeURI(str) : str;
	}

	public static googleMapsLink(model: IAddressFields): string {
		return `https://www.google.ch/maps/search/${this.geocodingString(model, ',', true)}`;
	}

	public static hasBusinessFieldData(model: IAddressFields): boolean {
		return [
			model.company,
			model.companySector,
		].some(v => !StringHelper.isEmpty(v));
	}

	public static sort(a: IAddressFields, b: IAddressFields): number {
		return (a.label ?? 'zzz').localeCompare(b.label ?? 'zzz');
	}

	public static areIdentical(a: IAddressFields | null, b: IAddressFields | null): boolean {
		if (a === null && b === null) return true;
		if (a === null || b === null) return false;

		return a.label === b.label
			&& a.salutation === b.salutation
			&& a.title === b.title
			&& a.firstname === b.firstname
			&& a.middlename === b.middlename
			&& a.lastname === b.lastname
			&& a.company === b.company
			&& a.companySector === b.companySector
			&& a.role === b.role
			&& a.address1 === b.address1
			&& a.address2 === b.address2
			&& a.street === b.street
			&& a.houseNumber === b.houseNumber
			&& a.poBox === b.poBox
			&& a.zip === b.zip
			&& a.city === b.city
			&& a.county === b.county
			&& a.state === b.state
			&& a.countryCode === b.countryCode;
	}

	private static getSalutation(salutation: Salutation | null): string {
		return EnumHelper.textFromValue(Salutation, salutation, true) ?? '';
	}

}
