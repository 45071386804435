import IListFilter from "@/models/IListFilter";
import StringHelper from "@/helpers/StringHelper";

export default class PromotionListFilterAdmin implements IListFilter {

	public fulltext: string | null = null;

	public validAtFrom: string | null = null;
	public validAtTo: string | null = null;

	public clear(): void {
		this.fulltext = null;
		this.validAtFrom = null;
		this.validAtTo = null;
	}

	public hasFilter(): boolean {
		return !StringHelper.allEmpty([this.fulltext, this.validAtFrom, this.validAtTo]);
	}

}
