import Vue from "vue";
import ICustomerServiceShop from "@/services/definition/ICustomerServiceShop";
import {ICustomerShop} from "@/models/customer/CustomerShopModels";
import AxiosService from "@/services/AxiosService";
import AvatarCreate from "@/models/attachment/AvatarCreate";
import AddressListFilter from "@/models/address/AddressListFilter";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import {IAddressListEntry} from "@/models/address/AddressModels";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import AddressCreate from "@/models/address/AddressCreate";
import ICreateResponse from "@/models/ICreateResponse";
import AddressUpdate from "@/models/address/AddressUpdate";
import UserListFilter from "@/models/user/UserListFilter";
import {IUserShopListEntry} from "@/models/user/UserShopModels";
import UserCreate from "@/models/user/UserCreate";

export default class AxiosCustomerServiceShop extends AxiosService implements ICustomerServiceShop {

	public static readonly BASE_URL = '/shop/customers';

	protected defineBaseUrl(): string {
		return AxiosCustomerServiceShop.BASE_URL;
	}

	public customer(id: string): Promise<ICustomerShop> {
		return Vue.$axios.get<ICustomerShop>(this.url(id)).then(res => res.data);
	}

	public users(id: string, filter: UserListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IUserShopListEntry>> {
		return Vue.$axios.get<IPaginatedResponse<IUserShopListEntry>>(this.url(`${id}/users`), {
			params: {
				...filter,
				...options
			}
		}).then(res => res.data);
	}

	public createUser(id: string, model: UserCreate): Promise<ICreateResponse> {
		return Vue.$axios.post<ICreateResponse>(this.url(`${id}/users`, '/customers'), model).then(res => res.data);
	}

	public addresses(id: string, filter: AddressListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IAddressListEntry>> {
		return Vue.$axios.get<IPaginatedResponse<IAddressListEntry>>(this.url(`${id}/addresses`), {
			params: {
				...filter,
				...options
			}
		}).then(res => res.data);
	}

	public createAddress(id: string, model: AddressCreate): Promise<ICreateResponse> {
		return Vue.$axios.post<ICreateResponse>(this.url(`${id}/addresses`, '/customers'), model).then(res => res.data);
	}

	public updateAddress(id: string, addressId: string, model: AddressUpdate): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/addresses/${addressId}`, '/customers'), model).then(res => res.data);
	}

	public deleteAddress(id: string, addressId: string): Promise<null> {
		return Vue.$axios.delete(this.url(`${id}/addresses/${addressId}`, '/customers')).then(res => res.data);
	}

	public setDefaultShippingAddress(id: string, addressId: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/default-shipping-address`, '/customers'), {
			addressId: addressId,
		}).then(res => res.data);
	}

	public setDefaultBillingAddress(id: string, addressId: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/default-billing-address`, '/customers'), {
			addressId: addressId,
		}).then(res => res.data);
	}

	public uploadAvatar(model: AvatarCreate, onProgress: (event: ProgressEvent) => void): Promise<null> {
		const formData = new FormData();
		formData.append('avatar', model.file as Blob, model.file?.name);

		return Vue.$axios.put<null>(this.url(`${model.model?.id}/avatar`, '/customers'), formData, {
			onUploadProgress: onProgress,
		}).then(res => res.data);
	}

	public deleteAvatar(model: ICustomerShop): Promise<null> {
		return Vue.$axios.delete(this.url(`${model.id}/avatar`, '/customers')).then(res => res.data);
	}

}
