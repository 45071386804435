import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;

export default class CountryEntryTranslated {

	public code!: string;
	public labelTranslated!: string | TranslateResult | null;

	public constructor(code: string, label: string | TranslateResult | null) {
		this.code = code;
		this.labelTranslated = label;
	}

}
