import ModelUpdate from "@/models/ModelUpdate";
import {ValidationRule} from "@/declarations/ValidationRule";
import {IItemAdmin} from "@/models/item/ItemAdminModels";

export default class ItemUpdateMetaData extends ModelUpdate<IItemAdmin> {

	public metaDescription!: string | null;
	public metaKeywords!: string | null;
	public canonical!: string | null;

	public constructor(model: IItemAdmin) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IItemAdmin): void {
		this.metaDescription = detailModel.metaDescription;
		this.metaKeywords = detailModel.metaKeywords;
		this.canonical = detailModel.canonical;
	}

	public rules(): ValidationRule[] {
		return [];
	}

}
