import Vue from 'vue';
import Vuex from 'vuex';
import ui, {UI} from '@/store/modules/ui';
import user, {listenAuthStorageChange, UserState} from "@/store/modules/user";
import createPersistedState from "vuex-persistedstate";
import login, {Login} from "@/store/modules/login";
import shortcut, {Shortcut} from "@/store/modules/shortcut";
import category, {Category} from "@/store/modules/category";
import cart, {Cart} from "@/store/modules/cart";
import globalConfig, {GlobalConfig} from "@/store/modules/global-config";
import shoppingList from "@/store/modules/shopping-list";
import sampleOrderCart, {SampleOrderCart} from "@/store/modules/sample-order-cart";
import sampleOrderFormData from "@/store/modules/sample-order-form-data";

Vue.use(Vuex);

export interface State {
	login: Login;
	ui: UI;
	user: UserState;
	globalConfig: GlobalConfig;
	shortcut: Shortcut;
	category: Category;
	cart: Cart;
	sampleOrderCart: SampleOrderCart;
}

const store = new Vuex.Store<State>({
	mutations: {},
	actions: {},
	modules: {
		login: login,
		ui: ui,
		user: user,
		globalConfig: globalConfig,
		shortcut: shortcut,
		category: category,
		cart: cart,
		shoppingList: shoppingList,
		sampleOrder: sampleOrderCart,
		sampleOrderFormData: sampleOrderFormData,
	},
	plugins: [
		createPersistedState({
			storage: window.localStorage,
			key: 'poesia-store',
			reducer: (persistedState: State) => {
				const stateFilter = JSON.parse(JSON.stringify(persistedState)) as State;

				// @ts-ignore
				delete stateFilter.ui.snackbarMessage;
				// @ts-ignore
				delete stateFilter.ui.numFullscreenDialogsOpen;
				// @ts-ignore
				delete stateFilter.ui.tenant;

				// @ts-ignore
				delete stateFilter.user.isRefreshingToken;
				// @ts-ignore
				delete stateFilter.user.isAxiosRefreshingToken;

				// @ts-ignore
				delete stateFilter.shortcut;

				// @ts-ignore
				delete stateFilter.category.loading;
				// @ts-ignore
				delete stateFilter.category.categories;
				// @ts-ignore
				delete stateFilter.category.activeRootCategory;

				// @ts-ignore
				delete stateFilter.cart.loading;
				// @ts-ignore
				delete stateFilter.cart.expanded;
				// @ts-ignore
				delete stateFilter.cart.carts;


				return stateFilter;
			}
		}),
	]
});

listenAuthStorageChange(store);
export default store;
