import AxiosService from "@/services/AxiosService";
import Vue from "vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import IOrderServiceAdmin from "@/services/definition/IOrderServiceAdmin";
import OrderListFilterAdmin from "@/models/order/OrderListFilterAdmin";
import {IOrderAdmin, IOrderAdminListEntry, IOrderAdminSalesGrowthChart} from "@/models/order/OrderAdminModels";

export default class AxiosOrderServiceAdmin extends AxiosService implements IOrderServiceAdmin {

	public static readonly BASE_URL = '/admin/orders';
	public static readonly BASE_URL_COMMON = '/orders';

	protected defineBaseUrl(): string {
		return AxiosOrderServiceAdmin.BASE_URL;
	}

	public order(id: string): Promise<IOrderAdmin> {
		return Vue.$axios.get<IOrderAdmin>(this.url(id)).then(res => res.data);
	}

	public orders(filter: OrderListFilterAdmin | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IOrderAdminListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['orderNumber'];
			options.sortDesc = [true];
		}

		return Vue.$axios.get<IPaginatedResponse<IOrderAdminListEntry>>(this.url(), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public paid(id: string): Promise<string> {
		return Vue.$axios.post(this.url(`${id}/paid`, AxiosOrderServiceAdmin.BASE_URL_COMMON), {
			id: id,
		}).then(res => res.data);
	}

	public orderGrowth(startDate: string, endDate: string): Promise<IOrderAdminSalesGrowthChart> {
		return Vue.$axios.get(this.url(`/growth`), {
			params: {
				startDate: startDate,
				endDate: endDate,
			}
		}).then(res => res.data);
	}

	public exportExcel(filter: OrderListFilterAdmin | null, options: AsiListTableOptions): Promise<Blob> {
		return Vue.$axios.get<Blob>(this.url(`/export`), {
			params: {
				...filter,
				...options,
			},
			responseType: 'blob',
		}).then(res => res.data);
	}

}
