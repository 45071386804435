import ModelUpdate from "@/models/ModelUpdate";
import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
import {ValidationRule} from "@/declarations/ValidationRule";

export default class CategoryUpdateMetaData extends ModelUpdate<ICategoryAdmin> {

	public metaDescription!: string | null;
	public metaKeywords!: string | null;

	public constructor(model: ICategoryAdmin) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: ICategoryAdmin): void {
		this.metaDescription = detailModel.metaDescription;
		this.metaKeywords = detailModel.metaKeywords;
	}

	public rules(): ValidationRule[] {
		return [];
	}

}
