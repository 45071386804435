import {RawLocation} from "vue-router";
import {OrderExportState, OrderPaymentState, OrderShipmentState, Routes} from "@/helpers/constants";
import Icon from "@/plugins/icons";
import {IOrderAdminListEntry} from "@/models/order/OrderAdminModels";

export default class OrderHelper {

	public static detailRoute(id: string): RawLocation {
		return {
			name: Routes.order,
			params: {
				id: id,
			},
		};
	}

	public static detailRouteWithPaymentUrl(id: string, paymentUrl: string): RawLocation {
		return {
			name: Routes.order,
			params: {
				id: id,
				paymentUrl: paymentUrl,
			},
		};
	}

	public static detailRouteAdmin(id: string): RawLocation {
		return {
			name: Routes.adminOrder,
			params: {
				id: id,
			},
		};
	}

	public static paymentStateColor(paymentState: OrderPaymentState): string {
		switch (paymentState) {
			case OrderPaymentState.orderPaymentUnpaid:
				return 'error';
			case OrderPaymentState.orderPaymentPaid:
				return 'success';
			default:
				return 'warning';
		}
	}

	public static paymentStateIcon(paymentState: OrderPaymentState): string {
		switch (paymentState) {
			case OrderPaymentState.orderPaymentUnpaid:
				return Icon.paymentType;
			case OrderPaymentState.orderPaymentPaid:
				return Icon.success;
			default:
				return Icon.error;
		}
	}

	public static shipmentStateColor(shipmentState: OrderShipmentState): string {
		switch (shipmentState) {
			case OrderShipmentState.orderShipmentNotShipped:
				return 'error';
			case OrderShipmentState.orderShipmentShipped:
				return 'success';
			default:
				return 'warning';
		}
	}

	public static shipmentStateIcon(shipmentState: OrderShipmentState): string {
		switch (shipmentState) {
			case OrderShipmentState.orderShipmentNotShipped:
				return Icon.paymentType;
			case OrderShipmentState.orderShipmentShipped:
				return Icon.success;
			default:
				return Icon.error;
		}
	}

	public static exportStateIcon(model: IOrderAdminListEntry): string {
		switch (model.exportState) {
			case OrderExportState.orderExportWaiting:
				return Icon.waiting;
			case OrderExportState.orderExportReady:
				return Icon.export;
			case OrderExportState.orderExportExporting:
				return Icon.export;
			case OrderExportState.orderExportExported:
				return Icon.yes;
			case OrderExportState.orderExportFailed:
			default:
				return Icon.error;
		}
	}

	public static exportStateColor(model: IOrderAdminListEntry): string {
		switch (model.exportState) {
			case OrderExportState.orderExportWaiting:
				return 'secondary';
			case OrderExportState.orderExportReady:
			case OrderExportState.orderExportExporting:
				return 'info';
			case OrderExportState.orderExportExported:
				return 'success';
			case OrderExportState.orderExportFailed:
			default:
				return 'error';
		}
	}

}
