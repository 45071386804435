import PathEntry from "@/models/PathEntry";
import Icon from "@/plugins/icons";
import i18n from "@/plugins/i18n";
import RoutesHelper from "@/helpers/RoutesHelper";

export default class PathHelper {

	public static prependHomeShop(entries: PathEntry[]): PathEntry[] {
		const homeRoute = RoutesHelper.homeShopRoute();
		entries.unshift(new PathEntry(Icon.home, i18n.t('pageTitles.shop.home').toString(), homeRoute));
		return entries;
	}

}
