import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Vue from "vue";
import AxiosService from "@/services/AxiosService";
import ICreateResponse from "@/models/ICreateResponse";
import MailTemplateCreate from "@/models/mail-template/MailTemplateCreate";
import {IMailTemplateAdmin, IMailTemplateAdminListEntry} from "@/models/mail-template/MailTemplateAdminModels";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import MailTemplateListFilter from "@/models/mail-template/MailTemplateListFilter";
import IMailTemplateServiceAdmin from "@/services/definition/IMailTemplateServiceAdmin";
import MailTemplateUpdate from "@/models/mail-template/MailTemplateUpdate";

export default class AxiosMailTemplateServiceAdmin extends AxiosService implements IMailTemplateServiceAdmin {

	public static readonly BASE_URL = '/admin/mail-templates';
	public static readonly BASE_URL_COMMON = '/mail-templates';

	protected defineBaseUrl(): string {
		return AxiosMailTemplateServiceAdmin.BASE_URL;
	}


	public create(model: MailTemplateCreate): Promise<ICreateResponse> {
		return Vue.$axios.post(this.url(null, AxiosMailTemplateServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public update(id: string, model: MailTemplateUpdate): Promise<null> {
		return Vue.$axios.put(this.url(id, AxiosMailTemplateServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id, AxiosMailTemplateServiceAdmin.BASE_URL_COMMON)).then(res => res.data);
	}

	public mailTemplate(id: string): Promise<IMailTemplateAdmin> {
		return Vue.$axios.get<IMailTemplateAdmin>(this.url(id)).then(res => res.data);
	}

	public mailTemplates(filter: MailTemplateListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IMailTemplateAdminListEntry>> {
		if (filter === null) {
			filter = new MailTemplateListFilter();
		}

		return Vue.$axios.get<IPaginatedResponse<IMailTemplateAdminListEntry>>(this.url(), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

}
