import IFormModel from "@/models/IFormModel";
import {MailType} from "@/helpers/constants";
import {ValidationRule} from "@/declarations/ValidationRule";
import MailTemplateRules from "@/models/mail-template/MailTemplateRules";

export default class MailTemplateCreate implements IFormModel {

	public mailType: MailType | null = null;
	public locale: string | null = null;
	public htmlTemplate: string | null = null;
	public subject: string | null = null;

	reset(): void {
		this.mailType = null;
		this.locale = null;
		this.htmlTemplate = null;
		this.subject = null;
	}

	rules(property: string): ValidationRule[] {
		switch (property) {
			case 'htmlTemplate':
				return MailTemplateRules.htmlTemplateRules;
			default:
				return [];
		}
	}

}
