import IFormModel from "@/models/IFormModel";
import TranslatedValueCreate from "@/models/translated-value/TranslatedValueCreate";
import ShipmentTypeRules from "@/models/shipment-type/ShipmentTypeRules";
import {ValidationRule} from "@/declarations/ValidationRule";
import {DeliveryMethod} from "@/helpers/constants";

export default class ShipmentTypeCreate implements IFormModel {

	public name: TranslatedValueCreate = new TranslatedValueCreate();
	public postage: number | null = null;
	public externalId: string | null = null;
	public isBulkyGoodsCharged: boolean = false;
	public deliveryMethod: DeliveryMethod | null = null;

	public reset(): void {
		this.name = new TranslatedValueCreate();
		this.postage = null;
		this.externalId = null;
		this.isBulkyGoodsCharged = false;
		this.deliveryMethod = null;
	}

	public rules(property: string): ValidationRule[] {
		switch(property) {
			case 'postage':
				return ShipmentTypeRules.postageRules;
			case 'deliveryMethod':
				return ShipmentTypeRules.deliveryMethod;
			default:
				return [];
		}
	}

}
