import {Module} from "vuex";
import {State} from "@/store";

export interface Shortcut {
	callbacks: (() => void)[];
}

const shortcut: Module<Shortcut, State> = {
	namespaced: true,
	state: {
		callbacks: [],
	},
	mutations: {
		addCallback(state: Shortcut, callback: () => void): void {
			if (state.callbacks.some(c => c === callback)) return;
			state.callbacks.push(callback);
		},
		removeCallback(state: Shortcut, callback: () => void): void {
			state.callbacks = state.callbacks.filter(c => c !== callback);
		},
	},
};

export default shortcut;
