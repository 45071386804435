import Vue from 'vue';
import {Vue as _Vue} from "vue/types/vue";
import {VTabs} from "vuetify/lib";

const tabs = VTabs as unknown as typeof _Vue;

export default Vue.extend({
	name: 'AsiTabs',
	extends: tabs,
	props: {
		iconsAndText: {
			type: Boolean,
			default: true,
		},
		color: {
			type: String,
			default: 'secondary',
		},
		backgroundColor: {
			type: String,
			default: 'grey lighten-3',
		},
		sliderColor: {
			type: String,
			default: 'primary',
		},
		sliderSize: {
			type: Number,
			default: 4,
		},
		showArrows: {
			type: Boolean,
			default: true,
		}
	},
});
