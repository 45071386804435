import IAzureAdConfigService from "@/services/definition/IAzureAdConfigService";
import AzureAdConfigCreate from "@/models/azure-ad-config/AzureAdConfigCreate";
import AzureAdConfigListFilter from "@/models/azure-ad-config/AzureAdConfigListFilter";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "../../models/IPaginatedResponse";
import Vue from "vue";
import {IAzureAdConfigListEntry} from "@/models/azure-ad-config/AzureAdConfigModels";
import ICreateResponse from "@/models/ICreateResponse";
import AxiosService from "@/services/AxiosService";

export default class AxiosAzureAdConfigService extends AxiosService implements IAzureAdConfigService {

	public static readonly BASE_URL = '/azure-ad-config';

	protected defineBaseUrl(): string {
		return AxiosAzureAdConfigService.BASE_URL;
	}

	public azureAdConfig(id: string): Promise<IAzureAdConfigListEntry> {
		return Vue.$axios.get<IAzureAdConfigListEntry>(this.url(id)).then(res => res.data);
	}

	public azureAdConfigs(filter: AzureAdConfigListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IAzureAdConfigListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['domain'];
			options.sortDesc = [false];
		}

		return Vue.$axios.get<IPaginatedResponse<IAzureAdConfigListEntry>>(this.url(), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public create(model: AzureAdConfigCreate): Promise<ICreateResponse> {
		return Vue.$axios.post<ICreateResponse>(this.url(), model).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id)).then(res => res.data);
	}

}
