import AxiosService from "@/services/AxiosService";
import IContactFormService from "@/services/definition/IContactFormService";
import Vue from "vue";
import ContactFormCreate from "@/models/contact/ContactFormCreate";

export default class AxiosContactFormService extends AxiosService implements IContactFormService {

	public static readonly BASE_URL = '/contact-form'

	protected defineBaseUrl(): string {
		return AxiosContactFormService.BASE_URL;
	}

	sendContactForm(requestData: ContactFormCreate): Promise<null> {
		return Vue.$axios.post(this.url(null, AxiosContactFormService.BASE_URL), requestData).then(res => res.data);
	}

}
