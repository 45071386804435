import Vue from "vue";
import AxiosService from "@/services/AxiosService";
import IPaymentTypeServiceShop from "@/services/definition/IPaymentTypeServiceShop";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import {IPaymentTypeShopListEntry} from "@/models/payment-type/PaymentTypeShopModels";
import PaymentTypeListFilter from "@/models/payment-type/PaymentTypeListFilter";

export default class AxiosPaymentTypeServiceShop extends AxiosService implements IPaymentTypeServiceShop {

	public static readonly BASE_URL = '/shop/payment-types';

	protected defineBaseUrl(): string {
		return AxiosPaymentTypeServiceShop.BASE_URL;
	}

	public paymentTypes(filter: PaymentTypeListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IPaymentTypeShopListEntry>> {
		const opt = new AsiListTableOptions();
		opt.sortBy = options !== null ? options.sortBy : ['name'];
		opt.sortDesc = options !== null ? options.sortDesc : [false];
		opt.page = options !== null ? options.page : 1;
		opt.itemsPerPage = options !== null ? options.itemsPerPage : 0;

		return Vue.$axios.get<IPaginatedResponse<IPaymentTypeShopListEntry>>(this.url(), {
			params: {
				...filter,
				...opt,
			}
		}).then(res => res.data);
	}

}
