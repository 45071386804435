import IListFilter from "@/models/IListFilter";
import StringHelper from "@/helpers/StringHelper";

export default class PaymentTypeListFilter implements IListFilter {

	public fulltext: string | null = null;
	public name: string | null = null;
	public description: string | null = null;
	public code: string | null = null;
	public paymentProviderCode: string | null = null;
	public externalId: string | null = null;

	public clear(): void {
		this.fulltext = null;
		this.name = null;
		this.description = null;
		this.code = null;
		this.paymentProviderCode = null;
		this.externalId = null;
	}

	public hasFilter(): boolean {
		return !StringHelper.allEmpty([
			this.fulltext, this.name, this.description, this.code, this.paymentProviderCode, this.externalId,
		]);
	}

}
