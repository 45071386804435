import IItemServiceShop from "@/services/definition/IItemServiceShop";
import {IItemShop} from "@/models/item/ItemShopModels";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import AxiosService from "@/services/AxiosService";
import Vue from "vue";
import ItemListFilterShop from "@/models/item/ItemListFilterShop";
import {IItemShopPaginatedResponse} from "@/models/item/IItemShopPaginatedResponse";
import LocaleHelper from "@/helpers/LocaleHelper";

export default class AxiosItemServiceShop extends AxiosService implements IItemServiceShop {

	public static readonly BASE_URL = '/shop/items';

	protected defineBaseUrl(): string {
		return AxiosItemServiceShop.BASE_URL;
	}

	public item(itemNumber: string): Promise<IItemShop> {
		return Vue.$axios.get<IItemShop>(this.url(itemNumber)).then(res => res.data);
	}

	public items(filter: ItemListFilterShop | null, options: AsiListTableOptions | null): Promise<IItemShopPaginatedResponse> {
		const opt = new AsiListTableOptions();
		opt.sortBy = options !== null ? options.sortBy : ['name'];
		opt.sortDesc = options !== null ? options.sortDesc : [false];
		opt.page = options !== null ? options.page : 1;
		opt.itemsPerPage = options !== null ? options.itemsPerPage : 0;

		const lang = LocaleHelper.currentLanguage();
		opt.sortBy = opt.sortBy.map(field => {
			return field.startsWith('name') ? `name.${lang}` : field;
		});

		return Vue.$axios.get<IItemShopPaginatedResponse>(this.url(), {
			params: {
				...filter,
				...opt
			}
		}).then(res => res.data);
	}
}
