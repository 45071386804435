import IListFilter from "@/models/IListFilter";
import StringHelper from "@/helpers/StringHelper";

export default class MailTemplateListFilter implements IListFilter {

	public fulltext: string | null = null;
	public locale: string | null = null;

	public clear(): void {
		this.fulltext = null;
		this.locale = null;
	}

	public hasFilter(): boolean {
		return !StringHelper.isEmpty(this.fulltext)
			|| !StringHelper.isEmpty(this.locale);
	}

}
