import ICurrencyService from "@/services/definition/ICurrencyService";
import {ICurrencyListEntry} from "@/models/currency/CurrencyModels";
import AxiosService from "@/services/AxiosService";
import Vue from "vue";

export default class AxiosCurrencyService extends AxiosService implements ICurrencyService {

	public static readonly BASE_URL = '/currencies';

	protected defineBaseUrl(): string {
		return AxiosCurrencyService.BASE_URL;
	}

	public currencies(): Promise<ICurrencyListEntry[]> {
		return Vue.$axios.get<ICurrencyListEntry[]>(this.url()).then(res => res.data);
	}

}
