import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";

export default class ShoppingListPositionCreate implements IFormModel {

	public itemId: string | null = null;
	public quantity: number | null = null;

	reset(): void {
		this.itemId = null;
		this.quantity = null;
	}

	rules(): ValidationRule[] {
		return [];
	}
}
