import Vue, {PluginObject} from "vue";
import ItemAttributeFilterSelection from "@/components/item/shop/attribute-filters/ItemAttributeFilterSelection.vue";
import ItemAttributeFilterBoolean from "@/components/item/shop/attribute-filters/ItemAttributeFilterBoolean.vue";
import ItemAttributeFilterNumeric from "@/components/item/shop/attribute-filters/ItemAttributeFilterNumeric.vue";
import ItemAttributeFilterNumericSelection
	from "@/components/item/shop/attribute-filters/ItemAttributeFilterNumericSelection.vue";
import ItemAttributeFilterStockStatus
	from "@/components/item/shop/attribute-filters/ItemAttributeFilterStockStatus.vue";
import ItemAttributeFilter from "@/components/item/shop/attribute-filters/ItemAttributeFilter.vue";


const Plugin = {
	install: function (vueInstance: typeof Vue): void {
		vueInstance.component(ItemAttributeFilter.name, ItemAttributeFilter);
		vueInstance.component(ItemAttributeFilterBoolean.name, ItemAttributeFilterBoolean);
		vueInstance.component(ItemAttributeFilterNumeric.name, ItemAttributeFilterNumeric);
		vueInstance.component(ItemAttributeFilterNumericSelection.name, ItemAttributeFilterNumericSelection);
		vueInstance.component(ItemAttributeFilterSelection.name, ItemAttributeFilterSelection);
		vueInstance.component(ItemAttributeFilterStockStatus.name, ItemAttributeFilterStockStatus);
	}
} as PluginObject<any>;

Vue.use(Plugin.install);

export default Plugin;
