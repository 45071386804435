import RequiredValidator from "@/validators/RequiredValidator";
import {RangeValidator} from "@/validators/RangeValidator";
import EnumHelper from "@/helpers/EnumHelper";
import {ItemState} from "@/helpers/constants";

export default class ItemRules {

	public static readonly stateRules = [
		(new RequiredValidator('item.state')).validationRule(),
		(new RangeValidator('item.state', true, EnumHelper.toArray(ItemState).map(c => c.value))).validationRule(),
	];

	public static readonly baseUnitOfMeasureRules = [
		(new RequiredValidator('item.baseUnitOfMeasure')).validationRule(),
	];

	public static readonly linkItemRemoteUrlRules = [
		(new RequiredValidator('item.remoteUrl')).validationRule(),
	];

	public static readonly shopItemRemoteUrlRules = [];

	public static readonly salesUnitOfMeasureRules = [
		(new RequiredValidator('item.salesUnitOfMeasure')).validationRule(),
	];

	public static readonly salesUnitQuantityRules = [
		(new RequiredValidator('item.salesUnitQuantity')).validationRule(),
	];

	public static readonly isTopSellerRules = [
		(new RequiredValidator('item.isTopSeller')).validationRule(),
	];

	public static readonly canOrderSampleRules = [
		(new RequiredValidator('item.canOrderSample')).validationRule(),
	];

	public static readonly isSalesUnitFixedRules = [
		(new RequiredValidator('item.isSalesUnitFixed')).validationRule(),
	];

	public static readonly stockQuantityRules = [
		(new RequiredValidator('item.stockQuantity')).validationRule(),
	];
}
