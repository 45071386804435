import AxiosService from "@/services/AxiosService";
import ICreateResponse from "@/models/ICreateResponse";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Vue from "vue";
import ShipmentTypeListFilter from "@/models/shipment-type/ShipmentTypeListFilter";
import LocaleHelper from "@/helpers/LocaleHelper";
import ShipmentTypeCreate from "@/models/shipment-type/ShipmentTypeCreate";
import ShipmentTypeUpdate from "@/models/shipment-type/ShipmentTypeUpdate";
import IShipmentTypeServiceAdmin from "@/services/definition/IShipmentTypeServiceAdmin";
import {IShipmentTypeAdmin, IShipmentTypeAdminListEntry} from "@/models/shipment-type/ShipmentTypeAdminModels";

export default class AxiosShipmentTypeServiceAdmin extends AxiosService implements IShipmentTypeServiceAdmin {

	public static readonly BASE_URL = '/admin/shipment-types';
	public static readonly BASE_URL_COMMON = '/shipment-types';

	protected defineBaseUrl(): string {
		return AxiosShipmentTypeServiceAdmin.BASE_URL;
	}

	public shipmentType(id: string): Promise<IShipmentTypeAdmin> {
		return Vue.$axios.get<IShipmentTypeAdmin>(this.url(id)).then(res => res.data);
	}

	public shipmentTypes(filter: ShipmentTypeListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IShipmentTypeAdminListEntry>> {
		const opt = new AsiListTableOptions();
		opt.sortBy = options !== null ? options.sortBy : ['name'];
		opt.sortDesc = options !== null ? options.sortDesc : [false];
		opt.page = options !== null ? options.page : 1;
		opt.itemsPerPage = options !== null ? options.itemsPerPage : 0;

		const lang = LocaleHelper.currentLanguage();
		opt.sortBy = opt.sortBy.map(field => {
			return field.startsWith('name') ? `name.${lang}` : field;
		});

		return Vue.$axios.get<IPaginatedResponse<IShipmentTypeAdminListEntry>>(this.url(), {
			params: {
				...filter,
				...opt
			}
		})
			.then(res => res.data);
	}

	public create(model: ShipmentTypeCreate): Promise<ICreateResponse> {
		return Vue.$axios.post(this.url(null, AxiosShipmentTypeServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public update(id: string, model: ShipmentTypeUpdate): Promise<null> {
		return Vue.$axios.put(this.url(id, AxiosShipmentTypeServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id, AxiosShipmentTypeServiceAdmin.BASE_URL_COMMON)).then(res => res.data);
	}

}
