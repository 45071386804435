export default class LoginRequestAd {

	public readonly username: string;
	public readonly token: string;

	public constructor(username: string, token: string) {
		this.username = username;
		this.token = token;
	}

}
