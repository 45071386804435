import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
import {Module} from "vuex";
import {State} from "@/store";
import Vue from "vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import CategoryHelper from "@/models/category/CategoryHelper";

export interface Category {
	loading: boolean;
	categories: ICategoryShopListEntry[];
	activeRootCategory: string | null;
}

const category: Module<Category, State> = {
	namespaced: true,
	state: {
		loading: false,
		categories: [],
		activeRootCategory: null,
	},
	getters: {
		rootCategories: (state): ICategoryShopListEntry[] => {
			return state.categories
				.filter(c => c.path.length === 0)
				.sort((a, b) => a.sortIndex - b.sortIndex);
		},
		categoryById: (state) => (id: string): ICategoryShopListEntry | null => {
			return state.categories.find(c => c.id === id) ?? null;
		},
		categoryByCanonical: (state) => (canonical: string): ICategoryShopListEntry | null => {
			return state.categories.find(c => CategoryHelper.currentCanonical(c.canonical) === canonical) ?? null;
		},
		categoryParent: (state, getters) => (childId: string): ICategoryShopListEntry | null => {
			const category = getters.categoryById(childId);
			return category.path.length === 0
				? null
				: getters.categoryById(category.path.concat().sort((a: ICategoryShopListEntry, b: ICategoryShopListEntry) => a.sortIndex - b.sortIndex)[category.path.length - 1].id);
		},
		categoryChildren: ((state) => (parentId: string): ICategoryShopListEntry[] | [] => {
			return state.categories
				.filter(c => {
					return c.path.length > 0 && c.path.concat().sort((a, b) => a.sortIndex - b.sortIndex)[c.path.length - 1].id === parentId;
				})
				.sort((a, b) => a.sortIndex - b.sortIndex);
		}),
	},
	mutations: {
		setActiveRootCategory(state: Category, categoryId: string | null): void {
			state.activeRootCategory = categoryId;
		},
		setLoading(state: Category, isLoading: boolean): void {
			state.loading = isLoading;
		},
		setCategories(state: Category, categories: ICategoryShopListEntry[]): void {
			state.categories = categories
				.sort((a: ICategoryShopListEntry, b: ICategoryShopListEntry): number => {
					return a.path.length - b.path.length;
				})
				.map(c => c as ICategoryShopListEntry);
		},
	},
	actions: {
		async loadCategories(context) {
			try {
				const options = new AsiListTableOptions();
				options.page = 1;
				options.itemsPerPage = 0;

				context.commit('setLoading', true);
				const categories = await Vue.$categoryServiceShop.categories(null, options);
				context.commit('setCategories', categories.data);
			} catch (e) {
				console.error('error while loading categorie', e);
			} finally {
				context.commit('setLoading', false);
			}
		},
	},
};


export default category;
