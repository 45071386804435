import {IItemShopListEntry, IItemShopSimple} from "@/models/item/ItemShopModels";
import Vue from "vue";
import VueGtm from "@gtm-support/vue2-gtm";

interface IGtm {
	selectItem: (payload: SelectItemGtmEvent) => void;
	viewItem: (payload: ViewItemGtmEvent) => void;
	addToCart: (payload: AddToCartGtmEvent) => void;
	viewCart: (payload: ViewCartGtmEvent) => void;
	removeFromCart: (payload: RemoveFromCartGtmEvent) => void;
	beginCheckout: (payload: BeginCheckoutGtmEvent) => void;
	purchase: (payload: PurchaseGtmEvent) => void;
}

interface GtmItem {
	item_id: string;
	item_name: string;
	item_category: string;
	item_brand: string;
	price: number;
	quantity: number;
}

interface CommerceGtmEvent {
	items: GtmItem[]
}

interface SelectItemGtmEvent extends CommerceGtmEvent {
}

interface ViewItemGtmEvent extends CommerceGtmEvent {
	currency: string,
	value: number,
}

interface CartGtmEvent extends CommerceGtmEvent {
	currency: string,
	value: number,
}

interface AddToCartGtmEvent extends CartGtmEvent {
}

interface ViewCartGtmEvent extends CartGtmEvent {
}

interface RemoveFromCartGtmEvent extends CartGtmEvent {
}

interface BeginCheckoutGtmEvent extends CartGtmEvent {
}

interface PurchaseGtmEvent extends CartGtmEvent {
	transaction_id: string,
	shipping: number,
	tax: number,
}

class Gtm implements IGtm {
	private push(payload: any) {
		window.dataLayer?.push({ecommerce: null});
		window.dataLayer?.push(payload);
	}

	selectItem(payload: SelectItemGtmEvent) {
		this.push({
			event: 'select_item',
			ecommerce: payload,
		});
	}

	viewItem(payload: ViewItemGtmEvent) {
		this.push({
			event: 'view_item',
			ecommerce: payload,
		});
	}

	addToCart(payload: AddToCartGtmEvent) {
		this.push({
			event: 'add_to_cart',
			ecommerce: payload,
		});
	}

	viewCart(payload: ViewCartGtmEvent) {
		this.push({
			event: 'view_cart',
			ecommerce: payload,
		});
	}

	removeFromCart(payload: RemoveFromCartGtmEvent) {
		this.push({
			event: 'remove_from_cart',
			ecommerce: payload,
		});
	}

	beginCheckout(payload: BeginCheckoutGtmEvent) {
		this.push({
			event: 'begin_checkout',
			ecommerce: payload,
		});
	}

	purchase(payload: PurchaseGtmEvent) {
		this.push({
			event: 'purchase',
			ecommerce: payload,
		});
	}
}

export default new Gtm;

const itemShopListEntryToGtmItem = (item: IItemShopListEntry, quantity: number = 1, price: number | null = null): GtmItem => ({
	item_id: item.itemNumber,
	item_name: item.name.de ?? '',
	item_category: item.category.name.de ?? '',
	item_brand: Vue.$isHerzogShop ? 'herzog dichtungen' : 'mk dichtungen',
	quantity: quantity,
	price: price ?? item.price?.price.amount ?? 0
});

const itemShopSimpleToGtmItem = (item: IItemShopSimple, price: number, quantity: number = 1): GtmItem => ({
	item_id: item.itemNumber,
	item_name: item.name.de ?? '',
	item_category: '',
	item_brand: Vue.$isHerzogShop ? 'herzog dichtungen' : 'mk dichtungen',
	quantity: quantity,
	price: price,
});

Vue.use(VueGtm, {
	id: process.env.VUE_APP_GTMID,
	debug: true,
	enabled: process.env.NODE_ENV === 'production', // -> enabled on production and staging
});

export {
	GtmItem,
	SelectItemGtmEvent,
	ViewItemGtmEvent,
	AddToCartGtmEvent,
	ViewCartGtmEvent,
	RemoveFromCartGtmEvent,
	PurchaseGtmEvent,
	BeginCheckoutGtmEvent,
	itemShopListEntryToGtmItem,
	itemShopSimpleToGtmItem
};
