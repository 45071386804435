import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";
import {ItemState, ItemType, UnitOfMeasure} from "@/helpers/constants";
import {IAttachment} from "@/models/attachment/AttachmentModels";
import ItemRules from "@/models/item/ItemRules";
import TranslatedValueCreate from "@/models/translated-value/TranslatedValueCreate";

export default class ItemCreate implements IFormModel {

	public state: ItemState | null = null;
	public name: TranslatedValueCreate = new TranslatedValueCreate();
	public itemNumber: string = '';
	public alternativeItemNumber: string | null = null;
	public itemNumberShort: string | null = null;
	public itemType: ItemType = ItemType.linkItem;
	public remoteUrl: string | null = null;
	public avatarAttachmentId: string | null = null;
	public galleryAttachments: IAttachment[] = [];
	public baseUnitOfMeasure: UnitOfMeasure | null = null;
	public salesUnitOfMeasure: UnitOfMeasure | null = null;
	public salesUnitQuantity: number | null = null;

	public reset(): void {
		this.state = null;
		this.name = new TranslatedValueCreate();
		this.itemNumber = '';
		this.alternativeItemNumber = null;
		this.itemNumberShort = null;
		this.itemType = ItemType.linkItem;
		this.remoteUrl = null;
		this.avatarAttachmentId = null;
		this.galleryAttachments = [];
		this.baseUnitOfMeasure = null;
		this.salesUnitOfMeasure = null;
		this.salesUnitQuantity = null;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'state':
				return ItemRules.stateRules;
			case 'baseUnitOfMeasure':
				return ItemRules.baseUnitOfMeasureRules;
			case 'salesUnitOfMeasure':
				return ItemRules.salesUnitOfMeasureRules;
			case 'salesUnitQuantity':
				return ItemRules.salesUnitQuantityRules;
			case 'remoteUrl':
				return this.itemType === ItemType.shopItem
					? ItemRules.shopItemRemoteUrlRules
					: ItemRules.linkItemRemoteUrlRules;
			default:
				return [];
		}
	}
}
