import Vue from "vue";
import CartPositionCreate from "@/models/cart-position/CartPositionCreate";
import ICreateResponse from "@/models/ICreateResponse";
import AxiosService from "@/services/AxiosService";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import CartListFilter from "@/models/cart/CartListFilter";
import CartCreate from "@/models/cart/CartCreate";
import {IAddressFields} from "@/models/address/AddressModels";
import ICartServiceAdmin from "@/services/definition/ICartServiceAdmin";
import {ICartAdmin, ICartAdminListEntry} from "@/models/cart/CartAdminModels";
import {IFrameAssembly} from "@/models/frame-assembly/FrameAssemblyModels";

export default class AxiosCartServiceAdmin extends AxiosService implements ICartServiceAdmin {

	public static readonly BASE_URL = '/admin/carts';
	public static readonly BASE_URL_COMMON = '/carts';

	protected defineBaseUrl(): string {
		return AxiosCartServiceAdmin.BASE_URL;
	}

	public cart(id: string): Promise<ICartAdmin> {
		return Vue.$axios.get<ICartAdmin>(this.url(id)).then(res => res.data);
	}

	public carts(filter: CartListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<ICartAdminListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['name'];
			options.sortDesc = [false];
		}

		return Vue.$axios.get<IPaginatedResponse<ICartAdminListEntry>>(this.url(), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public create(model: CartCreate): Promise<ICreateResponse> {
		return Vue.$axios.post(this.url(null, AxiosCartServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateName(cartId: string, name: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/name`, AxiosCartServiceAdmin.BASE_URL_COMMON), {
			name: name,
		}).then(res => res.data);
	}

	public updateOneTimeEmail(cartId: string, email: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/one-time-email`, AxiosCartServiceAdmin.BASE_URL_COMMON), {
			email: email,
		}).then(res => res.data);
	}

	public updateOneTimePhone(cartId: string, phone: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/one-time-phone`, AxiosCartServiceAdmin.BASE_URL_COMMON), {
			phone: phone,
		}).then(res => res.data);
	}

	public updateRemark(id: string, remark: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/remark`, AxiosCartServiceAdmin.BASE_URL_COMMON), {
			remark: remark,
		}).then(res => res.data);
	}

	public updateShipmentType(id: string, shipmentTypeId: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/shipment-type`, AxiosCartServiceAdmin.BASE_URL_COMMON), {
			shipmentTypeId: shipmentTypeId,
		}).then(res => res.data);
	}

	public updatePaymentType(id: string, paymentTypeId: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/payment-type`, AxiosCartServiceAdmin.BASE_URL_COMMON), {
			paymentTypeId: paymentTypeId,
		}).then(res => res.data);
	}

	public updateCustomerReference(id: string, customerReference: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/customer-reference`, AxiosCartServiceAdmin.BASE_URL_COMMON), {
			customerReference: customerReference,
		}).then(res => res.data);
	}

	public updateShippingAddress(cartId: string, addressId: string | null, addressFields: IAddressFields = {} as IAddressFields): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/shipping-address`, AxiosCartServiceAdmin.BASE_URL_COMMON), {
			addressId: addressId,
			...addressFields,
		}).then(res => res.data);
	}

	public updateBillingAddress(cartId: string, addressId: string | null, addressFields: IAddressFields = {} as IAddressFields): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/billing-address`, AxiosCartServiceAdmin.BASE_URL_COMMON), {
			addressId: addressId,
			...addressFields,
		}).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id, AxiosCartServiceAdmin.BASE_URL_COMMON)).then(res => res.data);
	}

	public addPosition(id: string, model: CartPositionCreate): Promise<ICreateResponse> {
		return Vue.$axios.post(this.url(`${id}/positions`, AxiosCartServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateQuantity(cartId: string, positionId: string, quantity: number): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/positions/${positionId}/quantity`, AxiosCartServiceAdmin.BASE_URL_COMMON), {
			quantity: quantity,
		}).then(res => res.data);
	}

	public updateFrameAssembly(cartId: string, positionId: string, frameAssembly: IFrameAssembly | null): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/positions/${positionId}/frame-assembly`, AxiosCartServiceAdmin.BASE_URL_COMMON), {
			frameAssembly: frameAssembly,
		}).then(res => res.data);
	}


	public updatePositionCustomerReference(cartId: string, positionId: string, customerReference: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/positions/${positionId}/customer-reference`, AxiosCartServiceAdmin.BASE_URL_COMMON), {
			customerReference: customerReference,
		}).then(res => res.data);
	}

	public removePosition(cartId: string, positionId: string): Promise<null> {
		return Vue.$axios.delete(this.url(`${cartId}/positions/${positionId}`, AxiosCartServiceAdmin.BASE_URL_COMMON)).then(res => res.data);
	}

	public clearPositions(cartId: string): Promise<null> {
		return Vue.$axios.delete(this.url(`${cartId}/positions`, AxiosCartServiceAdmin.BASE_URL_COMMON)).then(res => res.data);
	}

}
