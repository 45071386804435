import {Salutation} from "@/helpers/constants";
import IModel from "@/models/IModel";
import IBlamable from "@/models/IBlamable";
import ContactFormCreate from "@/models/contact/ContactFormCreate";

export interface IContactFormSimple extends IModel {
	company: string | null;
	salutation: Salutation
	firstname: string | null;
	lastname: string | null;
	street: string | null;
	houseNumber: string | null;
	zip: string | null;
	city: string | null;
	phone: string | null;
	email: string | null;
	message: string | null;
}

export interface IContactFormListEntry extends IContactFormSimple {
}

export interface IContactForm extends IContactFormListEntry, IBlamable {
	locale: string | null;
}

export default class ContactFormSummary {
	company: string | null;
	salutation: Salutation;
	firstname: string | null;
	lastname: string | null;
	street: string | null;
	houseNumber: string | null;
	city: string | null;
	zip: string | null;
	email: string | null;
	phone: string | null;
	message: string | null;

	public constructor(model: ContactFormCreate) {
		this.company = model.company;
		this.salutation = model.salutation;
		this.firstname = model.firstname;
		this.lastname = model.lastname;
		this.street = model.street;
		this.houseNumber = model.houseNumber;
		this.city = model.city;
		this.zip = model.zip;
		this.email = model.email;
		this.phone = model.phone;
		this.message = model.message;
	}
}
