import Vue from "vue";
import AxiosService from "@/services/AxiosService";
import IPaymentTypeServiceAdmin from "@/services/definition/IPaymentTypeServiceAdmin";
import {IPaymentTypeAdmin, IPaymentTypeAdminListEntry} from "@/models/payment-type/PaymentTypeAdminModels";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import PaymentTypeCreate from "@/models/payment-type/PaymentTypeCreate";
import ICreateResponse from "@/models/ICreateResponse";
import PaymentTypeUpdate from "@/models/payment-type/PaymentTypeUpdate";
import PaymentTypeListFilter from "@/models/payment-type/PaymentTypeListFilter";

export default class AxiosPaymentTypeServiceAdmin extends AxiosService implements IPaymentTypeServiceAdmin {

	public static readonly BASE_URL = '/admin/payment-types';
	public static readonly BASE_URL_COMMON = '/payment-types';

	protected defineBaseUrl(): string {
		return AxiosPaymentTypeServiceAdmin.BASE_URL;
	}

	public paymentType(id: string): Promise<IPaymentTypeAdmin> {
		return Vue.$axios.get<IPaymentTypeAdmin>(this.url(id)).then(res => res.data);
	}

	public paymentTypes(filter: PaymentTypeListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IPaymentTypeAdminListEntry>> {
		const opt = new AsiListTableOptions();
		opt.sortBy = options !== null ? options.sortBy : ['name'];
		opt.sortDesc = options !== null ? options.sortDesc : [false];
		opt.page = options !== null ? options.page : 1;
		opt.itemsPerPage = options !== null ? options.itemsPerPage : 0;

		return Vue.$axios.get<IPaginatedResponse<IPaymentTypeAdminListEntry>>(this.url(), {
			params: {
				...filter,
				...opt,
			}
		}).then(res => res.data);
	}

	public create(model: PaymentTypeCreate): Promise<ICreateResponse> {
		return Vue.$axios.post(this.url(null, AxiosPaymentTypeServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public update(id: string, model: PaymentTypeUpdate): Promise<null> {
		return Vue.$axios.put(this.url(id, AxiosPaymentTypeServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id, AxiosPaymentTypeServiceAdmin.BASE_URL_COMMON)).then(res => res.data);
	}

}
