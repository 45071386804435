import AttachmentCreate from "@/models/attachment/AttachmentCreate";

export default class AttachmentUpload {

	private static CURRENT_IDENTIFIER: number = 0;

	public readonly identifier: number;
	public readonly model: AttachmentCreate;
	public progress: number = 0;

	constructor(model: AttachmentCreate) {
		this.identifier = AttachmentUpload.CURRENT_IDENTIFIER;
		this.model = model;

		AttachmentUpload.CURRENT_IDENTIFIER++;
	}

}
