import AuthTypeResult from "@/models/auth/AuthTypeResult";
import {Module} from "vuex";
import {State} from "@/store";

export interface Login {
	username: string | null;
	authTypeResult: AuthTypeResult | null;
}

const login: Module<Login, State> = {
	namespaced: true,
	state: {
		username: null,
		authTypeResult: null as null | AuthTypeResult,
	},
	getters: {},
	mutations: {
		setLoginInfo(state: Login, loginInfo: { username: string; authTypeResult: AuthTypeResult } | null): void {
			state.username = loginInfo?.username ?? null;
			state.authTypeResult = loginInfo?.authTypeResult ?? null;
		},
	},
};

export default login;
