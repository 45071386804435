import ModelUpdate from "@/models/ModelUpdate";
import {ValidationRule} from "@/declarations/ValidationRule";
import ShipmentTypeRules from "@/models/shipment-type/ShipmentTypeRules";
import TranslatedValueUpdate from "@/models/translated-value/TranslatedValueUpdate";
import {IShipmentTypeAdmin} from "@/models/shipment-type/ShipmentTypeAdminModels";
import {DeliveryMethod} from "@/helpers/constants";

export default class ShipmentTypeUpdate extends ModelUpdate<IShipmentTypeAdmin> {

	public name!: TranslatedValueUpdate;
	public postage!: number;
	public externalId!: string | null;
	public isBulkyGoodsCharged: boolean = false;
	public deliveryMethod!: DeliveryMethod

	public constructor(model: IShipmentTypeAdmin) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IShipmentTypeAdmin): void {
		this.name = new TranslatedValueUpdate(detailModel.name);
		this.postage = detailModel.postage.amount;
		this.externalId = detailModel.externalId;
		this.deliveryMethod = detailModel.deliveryMethod;
		this.isBulkyGoodsCharged = detailModel.isBulkyGoodsCharged;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'postage':
				return ShipmentTypeRules.postageRules;
			case 'deliveryMethod':
				return ShipmentTypeRules.deliveryMethod;
			default:
				return [];
		}
	}

}
