import Vue from 'vue';
import IAttachmentService from "@/services/definition/IAttachmentService";
import AttachmentUpdate from "@/models/attachment/AttachmentUpdate";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "../../models/IPaginatedResponse";
import AttachmentListFilter from "@/models/attachment/AttachmentListFilter";
import {IAttachmentListEntry} from "@/models/attachment/AttachmentModels";
import AxiosService from "@/services/AxiosService";

export default class AxiosAttachmentService extends AxiosService implements IAttachmentService {

	public static readonly BASE_URL = '/attachment';

	protected defineBaseUrl(): string {
		return AxiosAttachmentService.BASE_URL;
	}

	public attachments(filter: AttachmentListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IAttachmentListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['name', 'filename'];
			options.sortDesc = [false, false];
		}

		return Vue.$axios.get<IPaginatedResponse<IAttachmentListEntry>>(this.url(), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id)).then(() => null);
	}

	public update(id: string, model: AttachmentUpdate): Promise<null> {
		return Vue.$axios.patch(this.url(id), model).then(res => res.data);
	}

	public name(id: string, name: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/name`), {
			name: name,
		}).then(() => null);
	}

}
