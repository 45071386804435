import {RawLocation} from "vue-router";
import {AttachmentMediaSize, Routes, UserState} from "@/helpers/constants";
import Icon from "@/plugins/icons";
import PathEntry from "@/models/PathEntry";
import StringHelper from "@/helpers/StringHelper";
import CustomerHelper from "@/models/customer/CustomerHelper";
import {IUserAdmin, IUserAdminBlamable, IUserAdminSimple} from "@/models/user/UserAdminModels";
import {IUserShopBlamable, IUserShopSimple} from "@/models/user/UserShopModels";
import AttachmentHelper from "@/models/attachment/AttachmentHelper";

export default class UserHelper {

	public static detailRoute(id: string): RawLocation {
		return {
			name: Routes.user,
			params: {
				id: id,
			},
		};
	}

	public static detailRouteAdmin(id: string): RawLocation {
		return {
			name: Routes.adminUser,
			params: {
				id: id,
			},
		};
	}

	public static avatarUrl(model: IUserShopSimple | IUserAdminSimple, size: AttachmentMediaSize): string | null {
		return model.avatar === null ? null : AttachmentHelper.getImageUrl(model.avatar, size);
	}

	public static pathIcon(): string {
		return Icon.user;
	}

	public static pathLabel(model: IUserAdminBlamable | IUserShopBlamable): string {
		return this.fullName(model) ?? '?';
	}

	public static pathEntry(model: IUserAdminBlamable | IUserShopBlamable, adminRoute: boolean = false): PathEntry {
		return new PathEntry(this.pathIcon(), this.pathLabel(model), adminRoute ? this.detailRouteAdmin(model.id) : this.detailRoute(model.id));
	}

	public static path(model: IUserAdminSimple | IUserShopSimple, adminRoutes: boolean = false): PathEntry[] {
		return [CustomerHelper.pathEntry(model.customer, adminRoutes)];
	}

	public static isImported(model: IUserAdmin): boolean {
		return model.externalId !== null;
	}

	public static fullName(model: IUserAdminBlamable | IUserShopBlamable | null): string | null {
		if (model === null) return null;
		const str = [model.firstname, model.lastname].filter(s => !StringHelper.isEmpty(s)).join(' ');
		return StringHelper.isEmpty(str) ? null : str;
	}

	public static assertedAcronym(model: IUserAdminBlamable | IUserShopBlamable | null): string | null {
		if (model === null) return null;
		return !StringHelper.isEmpty(model.acronym)
			? model.acronym
			: StringHelper.firstCharsOfWords(this.fullName(model));
	}

	public static stateColor(model: IUserAdminBlamable | IUserShopBlamable): string {
		switch (model.state) {
			case UserState.created:
				return 'info';
			case UserState.active:
				return 'success';
			case UserState.archived:
			default:
				return 'error';
		}
	}

	public static stateIcon(model: IUserAdminBlamable | IUserShopBlamable): string {
		switch (model.state) {
			case UserState.created:
				return Icon.created;
			case UserState.active:
				return Icon.unarchive;
			case UserState.archived:
			default:
				return Icon.archive;
		}
	}


}
