import {RawLocation} from "vue-router";
import {Routes} from "@/helpers/constants";
import Vue from "vue";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import i18n from "@/plugins/i18n";

export default class RoutesHelper {

	public static homeShopRoute(): RawLocation {
		return {
			name: Routes.home,
		};
	}

	public static loginShopRoute(): RawLocation {
		return {
			name: Routes.login,
		};
	}

	public static homeAdminRoute(): RawLocation {
		return {
			name: Routes.adminHome,
		};
	}

	public static notFoundRoute(): RawLocation {
		return {
			name: Routes.notFound,
		};
	}

	public static notFoundUri(): string {
		return '/:language/not-found';
	}

	public static catchAllRoute(): RawLocation {
		return {
			name: Routes.catchAll,
		};
	}

	public static async documentTitle(id: string, route: string): Promise<string | null> {
		switch (route) {
			case Routes.item:
				return Vue.$itemServiceShop.item(id)
					.then(res => {
						return TranslatedValueHelper.get(res.name, i18n.locale);
					})
					.catch(() => {
						return null;
					});
			case Routes.category:
				return Vue.$categoryServiceShop.category(id)
					.then(res => {
						return TranslatedValueHelper.get(res.name, i18n.locale);
					})
					.catch(() => {
						return null;
					});
			default:
				return Promise.resolve(null);
		}
	}
}
