import AxiosService from "@/services/AxiosService";
import IPromotionServiceShop from "@/services/definition/IPromotionServiceShop";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import {IPromotionShopListEntry} from "@/models/promotion/PromotionShopModels";
import Vue from "vue";

export default class AxiosPromotionServiceShop extends AxiosService implements IPromotionServiceShop {

	public static readonly BASE_URL = '/shop/promotions';
	public static readonly BASE_URL_COMMON = '/promotions';

	protected defineBaseUrl(): string {
		return AxiosPromotionServiceShop.BASE_URL;
	}

	public promotions(options: AsiListTableOptions | null): Promise<IPaginatedResponse<IPromotionShopListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['validFrom', 'validTo'];
			options.sortDesc = [false, false];
		}

		return Vue.$axios.get<IPaginatedResponse<IPromotionShopListEntry>>(this.url(), {
			params: {
				...options
			}
		})
			.then(res => res.data);
	}

}
