import RequiredValidator from "@/validators/RequiredValidator";
import {PhoneValidator} from "@/validators/PhoneValidator";
import EmailValidator from "@/validators/EmailValidator";

export default class ContactFormRules {
	public static readonly salutationRules = [
		(new RequiredValidator('contactForm.salutation')).validationRule(),
	];
	public static readonly firstnameRules = [
		(new RequiredValidator('contactForm.firstname')).validationRule(),
	];
	public static readonly lastnameRules = [
		(new RequiredValidator('contactForm.lastname')).validationRule(),
	];
	public static readonly streetRules = [
		(new RequiredValidator('contactForm.street')).validationRule(),
	];
	public static readonly houseNumberRules = [
		(new RequiredValidator('contactForm.houseNumber')).validationRule(),
	];
	public static readonly zipRules = [
		(new RequiredValidator('contactForm.zip')).validationRule(),
	];
	public static readonly cityRules = [
		(new RequiredValidator('contactForm.city')).validationRule(),
	];
	public static readonly phoneRules = [
		(new PhoneValidator('contactForm.phone')).validationRule(),
		(new RequiredValidator('contactForm.phone')).validationRule(),
	];
	public static readonly emailRules = [
		(new EmailValidator('contactForm.email')).validationRule(),
		(new RequiredValidator('contactForm.email')).validationRule(),
	];
	public static readonly messageRules = [
		(new RequiredValidator('contactForm.message')).validationRule(),
	];
}
