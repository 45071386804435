import IAuthService from "@/services/definition/IAuthService";
import TokenResult from "@/models/auth/TokenResult";
import Vue from 'vue';
import AuthTypeResult from "@/models/auth/AuthTypeResult";
import AuthTypeRequest from "@/models/auth/AuthTypeRequest";
import LoginRequestPw from "@/models/auth/LoginRequestPw";
import LoginRequestAd from "@/models/auth/LoginRequestAd";
import ResetPwRequest from "@/models/auth/ResetPwRequest";
import AccessTokenCreate from "@/models/access-token/AccessTokenCreate";
import IPaginatedResponse from "../../models/IPaginatedResponse";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import AccessTokenListFilter from "@/models/access-token/AccessTokenListFilter";
import {IAccessTokenListEntry} from "@/models/access-token/AccessTokenModels";
import AxiosService from "@/services/AxiosService";

export default class AxiosAuthService extends AxiosService implements IAuthService {

	public static readonly BASE_URL = '/auth';

	protected defineBaseUrl(): string {
		return AxiosAuthService.BASE_URL;
	}

	public authType(authTypeRequest: AuthTypeRequest): Promise<AuthTypeResult> {
		return Vue.$axios.post<AuthTypeResult>(this.url('auth-type'), authTypeRequest).then(res => res.data);
	}

	public loginPw(loginRequest: LoginRequestPw): Promise<TokenResult> {
		return Vue.$axios.post<TokenResult>(this.url('login'), loginRequest).then(res => res.data);
	}

	public loginAd(loginRequest: LoginRequestAd): Promise<TokenResult> {
		return Vue.$axios.post<TokenResult>(this.url('login-azad'), loginRequest).then(res => res.data);
	}

	public logout(): Promise<null> {
		return Vue.$axios.post<null>(this.url('logout')).then(res => res.data);
	}

	public refreshToken(tokenResult: TokenResult): Promise<TokenResult> {
		return Vue.$axios.post<TokenResult>(this.url('refresh'), {
			"refreshToken": tokenResult.refreshToken,
			"jwtToken": tokenResult.token,
		}).then(res => res.data);
	}

	public resetPassword(resetPwRequest: ResetPwRequest): Promise<null> {
		return Vue.$axios.post<null>(this.url('reset-password'), resetPwRequest).then(res => res.data);
	}

	public resendRegistrationConfirmation(username: string): Promise<null> {
		return Vue.$axios.post<null>(this.url('resend-confirmation'), {
			username: username,
		}).then(res => res.data);
	}

	public createAccessToken(userId: string, createRequest: AccessTokenCreate): Promise<string> {
		return Vue.$axios.post<string>(this.url(`${userId}/access-token`), createRequest)
			.then(res => res.data);
	}

	public accessTokens(userId: string, filter: AccessTokenListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IAccessTokenListEntry>> {
		return Vue.$axios.get<IPaginatedResponse<IAccessTokenListEntry>>(this.url(`${userId}/access-token`), {
			params: {
				...filter,
				...options
			}
		}).then(res => res.data);
	}

}
