import i18n from "@/plugins/i18n";
import FileHelper from "@/helpers/FileHelper";
import Icon from "@/plugins/icons";
import {IAttachment, IAttachmentFile} from "@/models/attachment/AttachmentModels";
import {AttachmentMediaSize} from "@/helpers/constants";

export default class AttachmentHelper {

	public static getImageUrl(attachment: IAttachment, size: AttachmentMediaSize): string | null {
		if (!this.isImageAttachment(attachment)) {
			throw new Error(`attachment is not an image: ${attachment.id}`);
		}

		const scaledSizes = attachment.files.filter(a => a.mediaSize !== AttachmentMediaSize.orig);
		const origSize = attachment.files.find(a => a.mediaSize === AttachmentMediaSize.orig) as IAttachmentFile;
		let url: string | null = null;
		if (scaledSizes.length === 0) {
			url = origSize.url;
		} else if (size === AttachmentMediaSize.s) {
			url = scaledSizes.find(a => a.mediaSize === AttachmentMediaSize.s)?.url ?? origSize.url;
		} else if (size === AttachmentMediaSize.m) {
			url = scaledSizes.find(a => a.mediaSize === AttachmentMediaSize.m)?.url
				?? scaledSizes.find(a => a.mediaSize === AttachmentMediaSize.s)?.url
				?? origSize.url;
		} else if (size === AttachmentMediaSize.l) {
			url = scaledSizes.find(a => a.mediaSize === AttachmentMediaSize.l)?.url
				?? scaledSizes.find(a => a.mediaSize === AttachmentMediaSize.m)?.url
				?? scaledSizes.find(a => a.mediaSize === AttachmentMediaSize.s)?.url
				?? origSize.url;
		} else {
			url = origSize.url;
		}

		return url === null ? null : encodeURI(url);
	}

	public static isImageAttachment(attachment: IAttachment): boolean {
		return attachment.mimeType.indexOf('image/') === 0;
	}

	public static fileSizeFormatted(sizeBytes: number): string {
		if (sizeBytes < 1024) {
			return i18n.t('ui.terms.fileSize', {size: sizeBytes, unit: 'B'}).toString();
		} else if (sizeBytes < 1024 * 1024) {
			return i18n.t('ui.terms.fileSize', {size: Math.round(sizeBytes / 1024), unit: 'KB'}).toString();
		} else if (sizeBytes < 1024 * 1024 * 1024) {
			return i18n.t('ui.terms.fileSize', {
				size: Math.round(sizeBytes / (1024 * 1024)).toFixed(2),
				unit: 'MB'
			}).toString();
		} else {
			return i18n.t('ui.terms.fileSize', {
				size: Math.round(sizeBytes / (1024 * 1024 * 1024)).toFixed(2),
				unit: 'GB'
			}).toString();
		}
	}

	public static extension(attachment: IAttachment | null, upperCase: boolean = false): string | null {
		if (attachment === null) return null;
		const ext = FileHelper.extensionFromFilename(attachment.filename);
		if (ext === null) return null;
		return upperCase ? ext.toLocaleUpperCase() : ext;
	}

	public static icon(attachment: IAttachment): string {
		return this.iconByMimeType(attachment.mimeType, attachment.filename);
	}

	public static iconByMimeType(mime: string | null, filename: string | null = null): string {
		if (mime === null) return Icon.file;
		mime = mime.toLocaleLowerCase();

		if (mime.indexOf('image/') === 0) {
			return Icon.fileImage;
		} else if (mime === 'application/pdf') {
			return Icon.filePdf;
		} else if (mime === 'text/plain') {
			return Icon.fileText;
		} else if (mime.indexOf('excel') !== -1 || mime.indexOf('spreadsheet') !== -1) {
			return Icon.fileExcel;
		} else if (mime.indexOf('word') !== -1) {
			return Icon.fileWord;
		} else if (mime === 'application/vnd.ms-outlook') {
			return Icon.mail;
		} else if (mime === 'application/octet-stream') {
			if (filename === null) return Icon.file;
			switch (FileHelper.extensionFromFilename(filename)) {
				case 'eml':
				case 'msg':
					return Icon.mail;
				default:
					return Icon.file;
			}
		} else {
			return Icon.file;
		}
	}

	public static download(attachment: IAttachment) {
		const file = attachment.files.find(f => f.mediaSize === AttachmentMediaSize.orig);
		if (file === undefined) return;

		fetch(file.url,
			{
				method: 'GET',
				mode: 'cors',
				cache: 'default',
			})
			.then(response => response.blob())
			.then(blob => {
				const blobUrl = URL.createObjectURL(blob);

				const link = document.createElement('a');
				link.href = blobUrl;
				link.download = attachment.filename;

				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);
				URL.revokeObjectURL(blobUrl);
			})
			.catch(error => console.error('Error downloading file:', error));
	}

}
