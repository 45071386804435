import RequiredValidator from "@/validators/RequiredValidator";

export default class RegistrationRules {

	public static readonly typeRules = [
		(new RequiredValidator('customer.type')).validationRule(),
	];

	public static readonly currencyRules = [
		(new RequiredValidator('currency.singular')).validationRule(),
	];

}
