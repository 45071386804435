import {Module} from "vuex";
import {State} from "@/store";
import i18n from "@/plugins/i18n";
import ISnackbarMessage from "@/models/ISnackbarMessage";
import {PerPageOptions} from "@/helpers/constants";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IListFilter from "@/models/IListFilter";

export interface UI {
	locale: string;
	snackbarMessage: null | ISnackbarMessage;
	mainNavPinned: boolean;
	itemsPerPage: number;
	numFullscreenDialogsOpen: number;
	listMemoryOptions: object;
	listMemoryFilters: object;
	activeTabs: {
		shop: Record<string, number>,
		admin: Record<string, number>,
	};
	editor: {
		expanded: boolean;
		editType: 'wysiwyg' | 'markdown';
		previewStyle: 'tab' | 'vertical';
	};
	amountsInclVat: boolean;
	videoWatched: boolean;
	welcomeMessageSeen: boolean;
}

const ui: Module<UI, State> = {
	namespaced: true,
	state: {
		locale: 'de-CH',
		snackbarMessage: null as null | ISnackbarMessage,
		mainNavPinned: false,
		itemsPerPage: PerPageOptions[0],
		numFullscreenDialogsOpen: 0,
		listMemoryOptions: {},
		listMemoryFilters: {},
		activeTabs: {
			shop: {},
			admin: {},
		},
		editor: {
			expanded: false,
			editType: 'wysiwyg',
			previewStyle: 'tab',
		},
		amountsInclVat: false,
		videoWatched: false,
		welcomeMessageSeen: false,
	},
	getters: {
		getListMemoryOptions: (state) => (key: string): AsiListTableOptions | null => {
			//@ts-ignore
			return state.listMemoryOptions[key] ?? null;
		},
		getListMemoryFilter: (state) => (key: string): IListFilter | null => {
			//@ts-ignore
			return state.listMemoryFilters[key] ?? null;
		},
		activeTabShop: (state) => (key: string): number => {
			return state.activeTabs.shop[key] ?? 0;
		},
		activeTabAdmin: (state) => (key: string): number => {
			return state.activeTabs.admin[key] ?? 0;
		},
	},
	mutations: {
		setMainNavPinned(state: UI, isPinned: boolean): void {
			state.mainNavPinned = isPinned;
		},
		clearListMemory(state: UI) {
			state.listMemoryOptions = {};
			state.listMemoryFilters = {};
		},
		setListMemoryOptions(state: UI, payload: { key: string; options: AsiListTableOptions }) {
			//@ts-ignore
			state.listMemoryOptions[payload.key] = payload.options;
		},
		setListMemoryFilter(state: UI, payload: { key: string; filter: IListFilter }) {
			//@ts-ignore
			state.listMemoryFilters[payload.key] = payload.filter;
		},
		setLocale(state: UI, locale: string): void {
			i18n.locale = locale;
			state.locale = locale;
		},
		showSnackbar(state: UI, msg: ISnackbarMessage) {
			state.snackbarMessage = msg;
		},
		setItemsPerPage(state: UI, itemsPerPage: number) {
			state.itemsPerPage = itemsPerPage;
		},
		setFullscreenDialogOpenState(state: UI, isOpen: boolean) {
			state.numFullscreenDialogsOpen += isOpen ? 1 : -1;

			const html = document.querySelector('html');
			if (html !== null) {
				if (state.numFullscreenDialogsOpen > 0) {
					html.classList.add('no-scroll');
				} else {
					html.classList.remove('no-scroll');
				}
			}
		},
		resetFullScreenDialogOpenState(state: UI) {
			state.numFullscreenDialogsOpen = 0;
			const html = document.querySelector('html');
			if (html !== null) {
				html.classList.remove('no-scroll');
			}
		},
		activeTabShop(state: UI, payload: { key: string; activeTab: number }) {
			state.activeTabs.shop[payload.key] = payload.activeTab;
		},
		activeTabAdmin(state: UI, payload: { key: string; activeTab: number }) {
			state.activeTabs.admin[payload.key] = payload.activeTab;
		},
		setAmountsInclVat(state: UI, value: boolean) {
			state.amountsInclVat = value;
		},
		setVideoWatched(state: UI, value: boolean) {
			state.videoWatched = value;
		},
		setWelcomeMessageSeen(state: UI, value: boolean) {
			state.welcomeMessageSeen = value;
		},
		setEditorEditType(state, payload: 'wysiwyg' | 'markdown') {
			state.editor.editType = payload;
		},
		setEditorPreviewStyle(state, payload: 'tab' | 'vertical') {
			state.editor.previewStyle = payload;
		},
		setEditorExpanded(state, payload: boolean) {
			state.editor.expanded = payload;
		}
	},
};

export default ui;
