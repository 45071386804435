import IAccessTokenService from "@/services/definition/IAccessTokenService";
import Vue from "vue";
import {IAccessToken} from "@/models/access-token/AccessTokenModels";
import AxiosService from "@/services/AxiosService";

export default class AxiosAccessTokenService extends AxiosService implements IAccessTokenService {

	public static readonly BASE_URL = '/access-token';

	protected defineBaseUrl(): string {
		return AxiosAccessTokenService.BASE_URL;
	}

	public accessToken(id: string): Promise<IAccessToken> {
		return Vue.$axios.get<IAccessToken>(this.url(id)).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id)).then(res => res.data);
	}

}
