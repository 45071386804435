import RequiredValidator from "@/validators/RequiredValidator";
import {RangeValidator} from "@/validators/RangeValidator";
import {CustomerState, CustomerType} from "@/helpers/constants";
import EnumHelper from "@/helpers/EnumHelper";
import {PhoneValidator} from "@/validators/PhoneValidator";

export default class CustomerRules {

	public static readonly nameRules = [
		(new RequiredValidator('customer.name')).validationRule(),
	];

	public static readonly stateRules = [
		(new RequiredValidator('customer.state')).validationRule(),
		(new RangeValidator('customer.state', true, EnumHelper.toArray(CustomerState).map(c => c.value))).validationRule(),
	];

	public static readonly typeRules = [
		(new RequiredValidator('customer.type')).validationRule(),
		(new RangeValidator('customer.type', true, EnumHelper.toArray(CustomerType).map(c => c.value))).validationRule(),
	];

	public static phoneRules(countryCode: string | null = null): any[] {
		return [
			(new RequiredValidator('customer.phone')).validationRule(),
			(new PhoneValidator('customer.phone', true, countryCode)).validationRule(),
		];
	}

}
