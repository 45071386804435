import AxiosService from "@/services/AxiosService";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import Vue from "vue";
import {IPromotionAdmin, IPromotionAdminListEntry} from "@/models/promotion/PromotionAdminModels";
import PromotionListFilterAdmin from "@/models/promotion/PromotionListFilterAdmin";
import IPromotionServiceAdmin from "@/services/definition/IPromotionServiceAdmin";
import PromotionCreate from "@/models/promotion/PromotionCreate";
import ICreateResponse from "@/models/ICreateResponse";
import PromotionUpdate from "@/models/promotion/PromotionUpdate";

export default class AxiosPromotionServiceAdmin extends AxiosService implements IPromotionServiceAdmin {

	public static readonly BASE_URL = '/admin/promotions';
	public static readonly BASE_URL_COMMON = '/promotions';

	protected defineBaseUrl(): string {
		return AxiosPromotionServiceAdmin.BASE_URL;
	}

	public promotions(filter: PromotionListFilterAdmin | null = null, options: AsiListTableOptions | null = null): Promise<IPaginatedResponse<IPromotionAdminListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['validFrom', 'validTo'];
			options.sortDesc = [false, false];
		}

		return Vue.$axios.get<IPaginatedResponse<IPromotionAdminListEntry>>(this.url(), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public promotion(id: string): Promise<IPromotionAdmin> {
		return Vue.$axios.get<IPromotionAdmin>(this.url(id)).then(res => res.data);
	}

	public create(model: PromotionCreate): Promise<ICreateResponse> {
		return Vue.$axios.post(this.url(null, AxiosPromotionServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public update(id: string, model: PromotionUpdate): Promise<null> {
		return Vue.$axios.put(this.url(id, AxiosPromotionServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id, AxiosPromotionServiceAdmin.BASE_URL_COMMON)).then(res => res.data);
	}

}
