import i18n from "@/plugins/i18n";
import {getName} from "i18n-iso-countries";
import CountryEntryTranslated from "@/models/country/CountryEntryTranslated";

export default class CountryHelper {

	public static readonly PRIORITY_COUNTRIES: (string[])[] = [
		['ch'],
		[],
		['de', 'fr', 'li', 'at', 'it'],
	];

	public static countryName(isoAbbr: string | null, lang: string | null = null): string | null {
		if (isoAbbr === null) return null;

		if (lang === null) {
			const locale = i18n.locale;
			lang = locale.substring(0, 2).toUpperCase();
		} else {
			lang = lang.toUpperCase();
		}

		return getName(isoAbbr, lang) ?? null;
	}

	public static sort(a: CountryEntryTranslated, b: CountryEntryTranslated): number {
		const codeA = a.code.toLowerCase();
		const codeB = b.code.toLowerCase();

		const flattenedPriorityCountries = CountryHelper.PRIORITY_COUNTRIES.flat();

		const aHasPriority = flattenedPriorityCountries.includes(codeA);
		const bHasPriority = flattenedPriorityCountries.includes(codeB);

		if (aHasPriority && !bHasPriority) {
			return -1;
		}
		if (!aHasPriority && bHasPriority) {
			return 1;
		}
		if (aHasPriority && bHasPriority) {
			return flattenedPriorityCountries.indexOf(codeA) - flattenedPriorityCountries.indexOf(codeB);
		}

		if (a.labelTranslated !== null && b.labelTranslated === null) return -1;
		if (a.labelTranslated === null && b.labelTranslated !== null) return 1;
		return (a.labelTranslated ?? '').toString().localeCompare((b.labelTranslated ?? '').toString());
	}

}