<template>
	<div @click="contentClicked">
		<Viewer ref="viewer"
		        :initial-value="finalValue"
		        :options="defaultOptions"
		        class="markdown-viewer"/>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import '@toast-ui/editor/dist/toastui-editor-viewer.css';
	import {Viewer} from '@toast-ui/vue-editor';
	import DOMPurify, {Config} from 'dompurify';
	import {LinkAttributes, ViewerOptions} from '@toast-ui/editor';

	@Component({
		components: {Viewer}
	})
	export default class AsiMarkDownViewer extends Vue {

		@Prop({type: String, default: null})
		public value!: string | null;

		// noinspection JSMethodCanBeStatic
		private get defaultOptions(): ViewerOptions {
			return {
				linkAttributes: {
					target: '_blank',
					rel: 'noopener noreferrer'
				} as LinkAttributes,
				customHTMLSanitizer: this.purify,
			} as ViewerOptions;
		}

		private get finalValue(): string {
			return this.value ?? '';
		}

		@Watch('value')
		private onValueChanged(value: string | null): void {
			const viewer = this.$refs.viewer as Viewer | undefined;
			//@ts-ignore
			if (viewer !== undefined) viewer.invoke('setMarkdown', value);
		}

		@Emit('linkClicked')
		public linkClicked(event: Event): Event {
			return event;
		}

		private purify(html: string): string {
			const purifyConfig = {
				FORBID_ATTR: ['style'],
				FORBID_TAGS: ['script', 'style'],
				ALLOWED_ATTR: ['class', 'target', 'href', 'rel'],
				KEEP_CONTENT: false,
				IN_PLACE: true,
			} as Config;

			//wiki link hook
			DOMPurify.addHook('uponSanitizeElement', (currentNode: Element): void => {
				if (currentNode.tagName?.toLowerCase() !== 'a') return;
				const link = currentNode as HTMLLinkElement;
				let url = link.getAttribute('href');
				if (url === null || !url.match(/^\/?wiki/)) return;
				if (url.startsWith('/')) url = url.substring(1);
				link.setAttribute('href', `/${url}`);
				link.removeAttribute('target');
			});

			//responsive table conversion
			return DOMPurify
				.sanitize(html, purifyConfig)
				.toString()
				.replaceAll('<table', '<div class="wysiwyg-responsive-table"><table')
				.replaceAll('</table>', '</table></div>');
		}

		private contentClicked(event: Event): void {
			const target = event.target as HTMLElement;
			if (target.tagName.toLowerCase() !== 'a') return;
			this.linkClicked(event);
		}

	}
</script>

<style lang="scss" scoped>
	.markdown-viewer {
		.toastui-editor-contents {
			font-family: "Roboto", sans-serif !important;
		}

		.wysiwyg-responsive-table {
			overflow-x: auto;
		}
	}
</style>
