import AxiosService from "@/services/AxiosService";
import IShoppingListServiceShop from "@/services/definition/IShoppingListServiceShop";
import ShoppingListPositionCreate from "@/models/shopping-list-position/ShoppingListPositionCreate";
import {IShoppingListShopListEntry} from "@/models/shopping-list/ShoppingListShopModels";
import ICreateResponse from "@/models/ICreateResponse";
import ShoppingListCreate from "@/models/shopping-list/ShoppingListCreate";
import Vue from "vue";
import ShoppingListListFilter from "@/models/shopping-list/ShoppingListListFilter";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "@/models/IPaginatedResponse";

export default class AxiosShoppingListServiceShop extends AxiosService implements IShoppingListServiceShop {

	public static readonly BASE_URL = '/shopping-lists';
	public static readonly BASE_URL_USER = '/users';
	public static readonly BASE_URL_SHOPUSER = '/shop/users';

	protected defineBaseUrl(): string {
		return AxiosShoppingListServiceShop.BASE_URL;
	}

	public shoppingLists(userId: string, filter: ShoppingListListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IShoppingListShopListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['name'];
			options.sortDesc = [false];
		}

		return Vue.$axios.get<IPaginatedResponse<IShoppingListShopListEntry>>(this.url(`${userId}/shopping-lists`, AxiosShoppingListServiceShop.BASE_URL_SHOPUSER), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public create(userId: string, model: ShoppingListCreate | null): Promise<ICreateResponse> {
		const modelInternal = model !== null ? model : {};
		return Vue.$axios.post(this.url(`${userId}/shopping-lists`, AxiosShoppingListServiceShop.BASE_URL_USER), modelInternal).then(res => res.data);
	}

	public updateName(id: string, name: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/name`, AxiosShoppingListServiceShop.BASE_URL), {
			name: name,
		}).then(res => res.data);
	}

	public addPosition(id: string, model: ShoppingListPositionCreate): Promise<ICreateResponse> {
		return Vue.$axios.post(this.url(`${id}/positions`, AxiosShoppingListServiceShop.BASE_URL), model).then(res => res.data);
	}

	public updatePosition(shoppingListId: string, positionId: string, quantity: number): Promise<null> {
		return Vue.$axios.put(this.url(`${shoppingListId}/positions/${positionId}/quantity`, AxiosShoppingListServiceShop.BASE_URL), {
			quantity: quantity,
		}).then(res => res.data);
	}

	public deletePosition(shoppingListId: string, positionId: string): Promise<null> {
		return Vue.$axios.delete(this.url(`${shoppingListId}/positions/${positionId}`, AxiosShoppingListServiceShop.BASE_URL)).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id, AxiosShoppingListServiceShop.BASE_URL)).then(res => res.data);
	}
}
