import RequiredValidator from "@/validators/RequiredValidator";
import EmailValidator from "@/validators/EmailValidator";
import {PhoneValidator} from "@/validators/PhoneValidator";

export default class CartRules {

	public static readonly oneTimeEmailRules = [
		(new RequiredValidator('cart.oneTimeEmail')).validationRule(),
		(new EmailValidator('cart.oneTimeEmail')).validationRule(),
	];

	public static readonly oneTimePhoneRules = [
		(new PhoneValidator('cart.oneTimePhone', true)).validationRule()
	];
}
