import RequiredValidator from "@/validators/RequiredValidator";

export default class ShipmentTypeRules {

	public static readonly postageRules = [
		(new RequiredValidator('shipmentType.postage')).validationRule(),
	];

	public static readonly deliveryMethod = [
		(new RequiredValidator('shipmentType.deliveryMethod')).validationRule(),
	];
}
