import ModelUpdate from "@/models/ModelUpdate";
import {IPaymentTypeAdmin} from "@/models/payment-type/PaymentTypeAdminModels";
import {ValidationRule} from "@/declarations/ValidationRule";
import TranslatedValueUpdate from "@/models/translated-value/TranslatedValueUpdate";
import PaymentTypeRules from "@/models/payment-type/PaymentTypeRules";
import {CustomerType} from "@/helpers/constants";

export default class PaymentTypeUpdate extends ModelUpdate<IPaymentTypeAdmin> {

	public name!: TranslatedValueUpdate;
	public description!: TranslatedValueUpdate;
	public code!: string;
	public paymentProviderCode!: string | null;
	public externalId!: string | null;
	public allowedCustomerTypes!: CustomerType[] | null;

	public constructor(model: IPaymentTypeAdmin) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IPaymentTypeAdmin): void {
		this.name = new TranslatedValueUpdate(detailModel.name);
		this.description = new TranslatedValueUpdate(detailModel.description);
		this.code = detailModel.code;
		this.paymentProviderCode = detailModel.paymentProviderCode;
		this.externalId = detailModel.externalId;
		this.allowedCustomerTypes = detailModel.allowedCustomerTypes;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'code':
				return PaymentTypeRules.codeRules;
			default:
				return [];
		}
	}

}
