import i18n from "@/plugins/i18n";
import VueI18n from "vue-i18n";
import store from "@/store";
import StringHelper from "@/helpers/StringHelper";
import TranslateResult = VueI18n.TranslateResult;

export default class LocaleHelper {

	public static supportedLocales(): string[] {
		return i18n.availableLocales;
	}

	public static supportedLocaleOptions(): { text: string | TranslateResult, value: string }[] {
		return this.supportedLocales().map(locale => {
			return {
				value: locale,
				text: i18n.t(`locale.${locale}`),
			};
		});
	}

	public static supportedLanguages(): string[] {
		return this.supportedLocales()
			.map(this.extractLanguage)
			.reduce((acc: string[], cur: string) => {
				if (!acc.includes(cur)) acc.push(cur);
				return acc;
			}, []);
	}

	public static currentLanguage(): string {
		return this.extractLanguage(i18n.locale);
	}

	public static extractLanguage(locale: string): string {
		return locale.substring(0, 2).toLocaleLowerCase();
	}

	public static localeFromLanguage(language: string): string | null {
		const lang = language.toLowerCase();

		return this.supportedLocales()
			.map(l => l)
			.find(l => l.toLowerCase().startsWith(lang)) ?? null;
	}

	public static fallbackLocale(): string {
		if (!StringHelper.isEmpty(i18n.locale)) {
			return i18n.locale;
		}

		const fallbackLocale = i18n.fallbackLocale;
		if (typeof fallbackLocale === 'string') {
			return fallbackLocale;
		} else if (Array.isArray(fallbackLocale) && fallbackLocale.length > 0) {
			return fallbackLocale[0];
		} else if (typeof fallbackLocale === 'object' && null !== fallbackLocale) {
			const locales = Object.values(fallbackLocale).flat();
			if (locales.length > 0) {
				return locales[0];
			}
		}

		return '';
	}

	public static determinePreferredLocale(): string {
		//if user is logged in, try to extract his locale
		if (store.getters['user/isLoggedIn']) {
			const user = store.getters['user/model'];
			const locale = user.locale;
			if (!StringHelper.isEmpty(locale)) return locale;
		}
		//try to extract a locale from the browser information
		const preferredLocale = window.navigator.language;
		const preferredLang = this.extractLanguage(preferredLocale);
		if (this.supportedLanguages().includes(preferredLang)) {
			return this.localeFromLanguage(preferredLang) ?? this.fallbackLocale();
		}
		//apply fallback locale procedure
		return this.fallbackLocale();
	}

	public static removeHrefLangTags(): void {
		document
			.querySelectorAll('link[rel="alternate"][hreflang]')
			.forEach(node => node.remove());
	}

	public static createHreflangTags(activeLanguage: string) {
		const currentUrl = window.location.href;

		this.supportedLanguages().forEach(lang => {
			//create new url by replacing language in currently active url
			const url = this.replaceLanguageInUrl(currentUrl, activeLanguage, lang);
			//add tag for language
			this.createHrefLangTag(lang, url);
			//if current language is german, also add default tag
			if (lang === 'de') {
				this.createHrefLangTag('x-default', url);
			}
		});
	}

	private static createHrefLangTag(language: string, url: string): void {
		const link = document.createElement('link');
		link.setAttribute('rel', 'alternate');
		link.setAttribute('hreflang', language);
		link.setAttribute('href', url);
		document.head.appendChild(link);
	}

	public static replaceLanguageInUrl(url: string, oldLanguage: string, newLanguage: string): string {
		return url.replace(new RegExp(`(?<=\\/)${oldLanguage}(?=\\/|$)`), newLanguage);
	}

}
