import AxiosService from "@/services/AxiosService";
import ISampleOrderService from "@/services/definition/ISampleOrderService";
import Vue from "vue";
import SampleOrderRequestData from "@/models/sample-order/SampleOrderRequestData";

export default class SampleOrderService extends AxiosService implements ISampleOrderService {

	public static readonly BASE_URL = '/sample-orders';

	protected defineBaseUrl(): string {
		return SampleOrderService.BASE_URL;
	}

	sendSampleOrder(requestData: SampleOrderRequestData): Promise<null> {
		return Vue.$axios.post(this.url(null, SampleOrderService.BASE_URL), requestData).then(res => res.data);
	}

}
