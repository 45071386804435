import {Module} from "vuex";
import {State} from "@/store";
import {IItemShopSimple} from "@/models/item/ItemShopModels";

export interface SampleOrderCart {
	items: IItemShopSimple[]
}

const sampleOrderCart: Module<SampleOrderCart, State> = {
	namespaced: true,
	state: {
		items: [] as IItemShopSimple[]
	},
	getters: {
		items: (state): IItemShopSimple[] | null => {
			if (JSON.stringify(state.items) === JSON.stringify([])) {
				return null;
			} else {
				return state.items;
			}
		},
		getItemById: (state) => (id: string) => {
			return state.items.find(item => item.id === id) ?? null;
		}
	},
	mutations: {
		addSampleOrderItem(state, item: IItemShopSimple): void {
			if (state.items.map(i => i.id).includes(item.id)) {
				return;
			}
			state.items.push(item);
		},
		removeSampleOrderItem(state, item: IItemShopSimple): void {
			state.items = state.items.filter(i => i.id != item.id);
		},
		removeAllSampleOrdersItem(state) {
			state.items = [];
		},

	},
	actions: {
		async add(context, item: IItemShopSimple) {
			try {
				context.commit('addSampleOrderItem', item);
			} catch (e) {
				console.error('error while adding item to sample orders', e);
			}
		},
		async remove(context, item: IItemShopSimple) {
			try {
				context.commit('removeSampleOrderItem', item);
			} catch (e) {
				console.error('error while removing item from sample orders', e);
			}
		},
		async removeAll(context) {
			try {
				context.commit('removeAllSampleOrdersItem');
			} catch (e) {
				console.error('error while removing all items from sample orders', e);
			}
		},
	},
};
export default sampleOrderCart;
