import {ICartAdminListEntry} from "@/models/cart/CartAdminModels";
import {IOrderAdmin} from "@/models/order/OrderAdminModels";
import {IPrice} from "@/models/price/PriceModels";
import {ICartPositionShopSimple} from "@/models/cart-position/CartPositionShopModels";
import {IOrderShop} from "@/models/order/OrderShopModels";
import {ICartShopListEntry} from "@/models/cart/CartShopModels";
import {ICurrency} from "@/models/currency/CurrencyModels";

export class CurrencyAmount {
	currency!: ICurrency;
	amount!: number;

	public constructor(currency: ICurrency, amount: number = 0) {
		this.currency = currency;
		this.amount = amount;
	}
}

export default class TotalHelper {

	private static total(subject: ICartAdminListEntry | ICartShopListEntry | IOrderAdmin | IOrderShop, includeShipment: boolean): IPrice[] {
		const ret = subject.positions
			.reduce((acc: IPrice[], cur: ICartPositionShopSimple) => {
				if (cur.price === null) {
					return acc;
				}
				// @ts-ignore
				let price = acc.find(p => p.vat === cur.price.vat && p.currency === cur.price.currency);
				if (price === undefined) {
					price = {vat: cur.price.vat, currency: cur.price.currency, amount: 0};
					acc.push(price);
				}

				price.amount += cur.price.amount * cur.quantity + (cur.trimmingCost?.amount ?? 0);
				return acc;
			}, [] as IPrice[]);

		if (subject.minimumOrderSurcharge !== null && subject.positions.length > 0) {
			const surchargePrice = subject.minimumOrderSurcharge.price;
			let price = ret.find(p => p.vat === surchargePrice.vat && p.currency === surchargePrice.currency);
			if (price === undefined) {
				price = {vat: surchargePrice.vat, currency: surchargePrice.currency, amount: 0};
				ret.push(price);
			}
			price.amount += surchargePrice.amount;
		}

		if (includeShipment && subject.shipmentType !== null) {
			const shipmentPrice = subject.shipmentType.postage;
			let price = ret.find(p => p.vat === shipmentPrice.vat && p.currency === shipmentPrice.currency);
			if (price === undefined) {
				price = {vat: shipmentPrice.vat, currency: shipmentPrice.currency, amount: 0};
				ret.push(price);
			}
			price.amount += shipmentPrice.amount;

			if (subject.bulkyGoodsSurcharge !== null) {
				const bulkySurchargePrice = subject.bulkyGoodsSurcharge.price;
				let price = ret.find(p => p.vat === bulkySurchargePrice.vat && p.currency === bulkySurchargePrice.currency);
				if (price === undefined) {
					price = {vat: bulkySurchargePrice.vat, currency: bulkySurchargePrice.currency, amount: 0};
					ret.push(price);
				}
				price.amount += bulkySurchargePrice.amount;
			}
		}

		return ret;
	}

	public static totalExclVat(subject: ICartAdminListEntry | ICartShopListEntry | IOrderAdmin | IOrderShop, includeShipment: boolean): CurrencyAmount[] {
		return this.total(subject, includeShipment).reduce((acc: CurrencyAmount[], cur: IPrice) => {
			let entry = acc.find(e => e.currency.currencyCode === cur.currency.currencyCode);
			if (entry === undefined) {
				entry = new CurrencyAmount(cur.currency);
				acc.push(entry);
			}

			entry.amount += cur.amount;
			return acc;
		}, []);
	}

	public static totalInclVat(subject: ICartAdminListEntry | ICartShopListEntry | IOrderAdmin | IOrderShop, includeShipment: boolean): CurrencyAmount[] {
		return this.total(subject, includeShipment).reduce((acc: CurrencyAmount[], cur: IPrice) => {
			let entry = acc.find(e => e.currency.currencyCode === cur.currency.currencyCode);
			if (entry === undefined) {
				entry = new CurrencyAmount(cur.currency);
				acc.push(entry);
			}

			entry.amount += cur.amount + this.vatAmount(cur);
			return acc;
		}, []);
	}

	public static vat(subject: ICartAdminListEntry | ICartShopListEntry | IOrderAdmin | IOrderShop, includeShipment: boolean): CurrencyAmount[] {
		return this.total(subject, includeShipment).reduce((acc: CurrencyAmount[], cur: IPrice) => {
			let entry = acc.find(e => e.currency.currencyCode === cur.currency.currencyCode);
			if (entry === undefined) {
				entry = new CurrencyAmount(cur.currency);
				acc.push(entry);
			}

			entry.amount += this.vatAmount(cur);
			return acc;
		}, []);
	}

	public static vatAmount(price: IPrice): number {
		return price.amount * (price.vat / 100);
	}

}
