import Vue from 'vue';
import {PluginObject} from "vue/types/umd";
import AxiosAzureAdConfigService from "@/services/implementation/AxiosAzureAdConfigService";
import AxiosAuthService from "@/services/implementation/AxiosAuthService";
import AxiosAttachmentService from "@/services/implementation/AxiosAttachmentService";
import AxiosAccessTokenService from "@/services/implementation/AxiosAccessTokenService";
import AxiosCustomerServiceAdmin from "@/services/implementation/AxiosCustomerServiceAdmin";
import AxiosCustomerServiceShop from "@/services/implementation/AxiosCustomerServiceShop";
import AxiosCategoryServiceShop from "@/services/implementation/AxiosCategoryServiceShop";
import AxiosUserServiceAdmin from "@/services/implementation/AxiosUserServiceAdmin";
import AxiosItemServiceShop from "@/services/implementation/AxiosItemServiceShop";
import AxiosUserServiceShop from "@/services/implementation/AxiosUserServiceShop";
import AxiosItemServiceAdmin from "@/services/implementation/AxiosItemServiceAdmin";
import AxiosCurrencyService from "@/services/implementation/AxiosCurrencyService";
import AxiosCountryService from "@/services/implementation/AxiosCountryService";
import AxiosCategoryServiceAdmin from "@/services/implementation/AxiosCategoryServiceAdmin";
import AxiosCartServiceShop from "@/services/implementation/AxiosCartServiceShop";
import AxiosCartServiceAdmin from "@/services/implementation/AxiosCartServiceAdmin";
import AxiosShipmentTypeServiceAdmin from "@/services/implementation/AxiosShipmentTypeServiceAdmin";
import AxiosShipmentTypeServiceShop from "@/services/implementation/AxiosShipmentTypeServiceShop";
import AxiosPaymentTypeServiceAdmin from "@/services/implementation/AxiosPaymentTypeServiceAdmin";
import AxiosPaymentTypeServiceShop from "@/services/implementation/AxiosPaymentTypeServiceShop";
import AxiosOrderServiceShop from "@/services/implementation/AxiosOrderServiceShop";
import AxiosOrderServiceAdmin from "@/services/implementation/AxiosOrderServiceAdmin";
import AxiosPromotionServiceAdmin from "@/services/implementation/AxiosPromotionServiceAdmin";
import AxiosPromotionServiceShop from "@/services/implementation/AxiosPromotionServiceShop";
import AxiosGlobalConfigServiceAdmin from "@/services/implementation/AxiosGlobalConfigServiceAdmin";
import AxiosMailTemplateServiceAdmin from "@/services/implementation/AxiosMailTemplateServiceAdmin";
import AxiosShoppingListServiceAdmin from "@/services/implementation/AxiosShoppingListServiceAdmin";
import AxiosShoppingListServiceShop from "@/services/implementation/AxiosShoppingListServiceShop";
import SampleOrderService from "@/services/implementation/SampleOrderService";
import AxiosContactFormService from "@/services/implementation/AxiosContactFormService";

const authService = new AxiosAuthService();
const accessTokenService = new AxiosAccessTokenService();
const attachmentService = new AxiosAttachmentService();
const azureAdConfigService = new AxiosAzureAdConfigService();
const globalConfigServiceAdmin = new AxiosGlobalConfigServiceAdmin();

const customerServiceAdmin = new AxiosCustomerServiceAdmin();
const customerServiceShop = new AxiosCustomerServiceShop();
const userServiceAdmin = new AxiosUserServiceAdmin();
const userServiceShop = new AxiosUserServiceShop();
const categoryServiceAdmin = new AxiosCategoryServiceAdmin();
const categoryServiceShop = new AxiosCategoryServiceShop();
const itemServiceAdmin = new AxiosItemServiceAdmin();
const itemServiceShop = new AxiosItemServiceShop();
const mailTemplateServiceAdmin = new AxiosMailTemplateServiceAdmin();
const cartServiceAdmin = new AxiosCartServiceAdmin();
const cartServiceShop = new AxiosCartServiceShop();
const orderServiceAdmin = new AxiosOrderServiceAdmin();
const orderServiceShop = new AxiosOrderServiceShop();
const shipmentTypeServiceAdmin = new AxiosShipmentTypeServiceAdmin();
const shipmentTypeServiceShop = new AxiosShipmentTypeServiceShop();
const paymentTypeServiceAdmin = new AxiosPaymentTypeServiceAdmin();
const paymentTypeServiceShop = new AxiosPaymentTypeServiceShop();
const promotionServiceAdmin = new AxiosPromotionServiceAdmin();
const promotionServiceShop = new AxiosPromotionServiceShop();
const sampleOrderService = new SampleOrderService();
const currencyService = new AxiosCurrencyService();
const countryService = new AxiosCountryService();
const contactFormService = new AxiosContactFormService();
const shoppingListServiceAdmin = new AxiosShoppingListServiceAdmin();
const shoppingListServiceShop = new AxiosShoppingListServiceShop();

const Plugin = {
	install: function (vueInstance: typeof Vue): void {
		vueInstance.$authService = authService;
		vueInstance.$accessTokenService = accessTokenService;
		vueInstance.$attachmentService = attachmentService;
		vueInstance.$azureAdConfigService = azureAdConfigService;
		vueInstance.$globalConfigServiceAdmin = globalConfigServiceAdmin;

		vueInstance.$customerServiceAdmin = customerServiceAdmin;
		vueInstance.$customerServiceShop = customerServiceShop;
		vueInstance.$userServiceAdmin = userServiceAdmin;
		vueInstance.$userServiceShop = userServiceShop;
		vueInstance.$categoryServiceAdmin = categoryServiceAdmin;
		vueInstance.$categoryServiceShop = categoryServiceShop;
		vueInstance.$itemServiceAdmin = itemServiceAdmin;
		vueInstance.$itemServiceShop = itemServiceShop;
		vueInstance.$mailTemplateServiceAdmin = mailTemplateServiceAdmin;
		vueInstance.$cartServiceAdmin = cartServiceAdmin;
		vueInstance.$cartServiceShop = cartServiceShop;
		vueInstance.$orderServiceAdmin = orderServiceAdmin;
		vueInstance.$orderServiceShop = orderServiceShop;
		vueInstance.$shipmentTypeServiceAdmin = shipmentTypeServiceAdmin;
		vueInstance.$shipmentTypeServiceShop = shipmentTypeServiceShop;
		vueInstance.$sampleOrderService = sampleOrderService;
		vueInstance.$currencyService = currencyService;
		vueInstance.$countryService = countryService;
		vueInstance.$contactFormService = contactFormService;
		vueInstance.$shoppingListServiceAdmin = shoppingListServiceAdmin;
		vueInstance.$shoppingListServiceShop = shoppingListServiceShop;

		Object.defineProperties(vueInstance.prototype, {
			$authService: {
				get() {
					return authService;
				}
			},
			$accessTokenService: {
				get() {
					return accessTokenService;
				}
			},
			$attachmentService: {
				get() {
					return attachmentService;
				}
			},
			$azureAdConfigService: {
				get() {
					return azureAdConfigService;
				}
			},
			$globalConfigServiceAdmin: {
				get() {
					return globalConfigServiceAdmin;
				}
			},

			$customerServiceAdmin: {
				get() {
					return customerServiceAdmin;
				}
			},
			$customerServiceShop: {
				get() {
					return customerServiceShop;
				}
			},
			$userServiceAdmin: {
				get() {
					return userServiceAdmin;
				}
			},
			$userServiceShop: {
				get() {
					return userServiceShop;
				}
			},
			$categoryServiceAdmin: {
				get() {
					return categoryServiceAdmin;
				}
			},
			$categoryServiceShop: {
				get() {
					return categoryServiceShop;
				}
			},
			$itemServiceAdmin: {
				get() {
					return itemServiceAdmin;
				}
			},
			$itemServiceShop: {
				get() {
					return itemServiceShop;
				}
			},
			$mailTemplateServiceAdmin: {
				get() {
					return mailTemplateServiceAdmin;
				}
			},
			$cartServiceAdmin: {
				get() {
					return cartServiceAdmin;
				}
			},
			$cartServiceShop: {
				get() {
					return cartServiceShop;
				}
			},
			$orderServiceAdmin: {
				get() {
					return orderServiceAdmin;
				}
			},
			$orderServiceShop: {
				get() {
					return orderServiceShop;
				}
			},
			$shipmentTypeServiceAdmin: {
				get() {
					return shipmentTypeServiceAdmin;
				}
			},
			$shipmentTypeServiceShop: {
				get() {
					return shipmentTypeServiceShop;
				}
			},
			$paymentTypeServiceAdmin: {
				get() {
					return paymentTypeServiceAdmin;
				}
			},
			$paymentTypeServiceShop: {
				get() {
					return paymentTypeServiceShop;
				}
			},
			$promotionServiceAdmin: {
				get() {
					return promotionServiceAdmin;
				}
			},
			$promotionServiceShop: {
				get() {
					return promotionServiceShop;
				}
			},
			$sampleOrderService: {
				get() {
					return sampleOrderService;
				}
			},
			$currencyService: {
				get() {
					return currencyService;
				}
			},
			$countryService: {
				get() {
					return countryService;
				}
			},
			$contactFormService: {
				get() {
					return contactFormService;
				}
			},
			$shoppingListServiceAdmin: {
				get() {
					return shoppingListServiceAdmin;
				}
			},
			$shoppingListServiceShop: {
				get() {
					return shoppingListServiceShop;
				}
			},
		});
	}
} as PluginObject<any>;


Vue.use(Plugin.install);
export default Plugin;
