import {ICustomerAdmin, ICustomerAdminSimple} from "@/models/customer/CustomerAdminModels";
import {RawLocation} from "vue-router";
import {AttachmentMediaSize, CustomerState, Routes} from "@/helpers/constants";
import Icon from "@/plugins/icons";
import PathEntry from "@/models/PathEntry";
import {ICustomerShopSimple} from "@/models/customer/CustomerShopModels";
import AttachmentHelper from "@/models/attachment/AttachmentHelper";

export default class CustomerHelper {

	public static detailRoute(id: string): RawLocation {
		return {
			name: Routes.customer,
			params: {
				id: id,
			},
		};
	}

	public static detailRouteAdmin(id: string): RawLocation {
		return {
			name: Routes.adminCustomer,
			params: {
				id: id,
			},
		};
	}

	public static avatarUrl(model: ICustomerShopSimple | ICustomerAdminSimple, size: AttachmentMediaSize): string | null {
		return model.avatar === null ? null : AttachmentHelper.getImageUrl(model.avatar, size);
	}

	public static pathIcon(): string {
		return Icon.customer;
	}

	public static pathLabel(model: ICustomerAdminSimple | ICustomerShopSimple): string {
		return model.name;
	}

	public static pathEntry(model: ICustomerAdminSimple | ICustomerShopSimple, adminRoute: boolean = false): PathEntry {
		return new PathEntry(this.pathIcon(), this.pathLabel(model), adminRoute ? this.detailRouteAdmin(model.id) : this.detailRoute(model.id));
	}

	public static path(): PathEntry[] {
		return [];
	}

	public static isImported(model: ICustomerAdmin): boolean {
		return model.externalId !== null;
	}

	public static stateColor(model: ICustomerAdminSimple | ICustomerShopSimple): string {
		switch (model.state) {
			case CustomerState.active:
				return 'success';
			case CustomerState.archived:
			default:
				return 'error';
		}
	}

	public static stateIcon(model: ICustomerAdminSimple | ICustomerShopSimple): string {
		switch (model.state) {
			case CustomerState.active:
				return Icon.unarchive;
			case CustomerState.archived:
			default:
				return Icon.archive;
		}
	}

}
