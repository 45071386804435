import IListFilter from "@/models/IListFilter";
import {CustomerType, UserState} from "@/helpers/constants";
import StringHelper from "@/helpers/StringHelper";

export default class UserListFilter implements IListFilter {

	public static readonly defaultStateFilter = [UserState.active];
	public readonly defaultStateFilterInternal: UserState[] = [];

	public fulltext: string | null = null;

	public states: UserState[] = [];
	public username: string | null = null;
	public firstname: string | null = null;
	public lastname: string | null = null;
	public acronym: string | null = null;
	public notes: string | null = null;
	public customerName: string | null = null;
	public customerTypes: CustomerType[] = [];

	public constructor(defaultStateFilter: UserState[] | null = null) {
		this.defaultStateFilterInternal = defaultStateFilter === null ? UserListFilter.defaultStateFilter : defaultStateFilter;
		this.states = this.defaultStateFilterInternal;
	}

	public clear(): void {
		this.fulltext = null;
		this.states = this.defaultStateFilterInternal;
		this.username = null;
		this.firstname = null;
		this.lastname = null;
		this.acronym = null;
		this.notes = null;

		this.customerName = null;
		this.customerTypes = [];
	}

	public hasFilter(): boolean {
		if (this.states.length !== this.defaultStateFilterInternal.length
			|| this.states.some(s => !this.defaultStateFilterInternal.includes(s))) {
			return true;
		}

		return !StringHelper.allEmpty([this.fulltext, this.username, this.firstname, this.lastname, this.acronym, this.notes, this.customerName,])
			|| this.customerTypes.length > 0;
	}

}
