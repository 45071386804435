import {FrameAssemblyInstallation, FrameAssemblyMeasuringPoint, FrameAssemblyType} from "@/helpers/constants";
import {ValidationRule} from "@/declarations/ValidationRule";
import ModelUpdate from "@/models/ModelUpdate";
import {IFrameAssembly, IFrameAssemblySimple} from "@/models/frame-assembly/FrameAssemblyModels";

export default class FrameAssemblyUpdate extends ModelUpdate<IFrameAssemblySimple> {

	public frameType!: FrameAssemblyType;
	public width!: number;
	public height!: number;
	public measuringPoint!: FrameAssemblyMeasuringPoint;
	public installation!: FrameAssemblyInstallation;
	public description!: string | null;

	public constructor(model: IFrameAssembly) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IFrameAssemblySimple): void {
		this.frameType = detailModel.frameType;
		this.width = detailModel.width;
		this.height = detailModel.height;
		this.measuringPoint = detailModel.measuringPoint;
		this.installation = detailModel.installation;
		this.description = detailModel.description;
	}

	public rules(): ValidationRule[] {
		return [];
	}
}
