<template>
	<div>
		<div @click="editorContainerClicked">
			<Editor ref="editor"
			        :initialValue="toEditorValue(value)"
			        :options="defaultOptions"
			        :initialEditType="editType"
			        :previewStyle.sync="previewStyle"
			        @change="input"/>
		</div>

		<div class="d-flex flex-row">
			<AsiBtn v-if="height === null && !hideExpander" depressed small tile @click="expanded = !expanded"
			        class="flex-grow-1 flex-shrink-1">
				<v-icon :class="{'flipped': expanded}" class="mr-2">{{ icons.dropdown }}</v-icon>
				<span>{{ $t(expanded ? 'ui.collapse' : 'ui.expand') }}</span>
			</AsiBtn>
			<v-fade-transition>
				<AsiBtn v-if="$store.state.ui.editor.editType === 'markdown'" :icon="previewStyle === 'vertical' ? icons.yes : icons.no"
				        depressed small tile @click="togglePreviewStyle" class="flex-grow-1 flex-shrink-1">
					{{ $t('ui.preview') }}
				</AsiBtn>
			</v-fade-transition>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import '@toast-ui/editor/dist/toastui-editor.css';
	import {Editor} from '@toast-ui/vue-editor';
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import {EditorOptions, HookMap, LinkAttributes} from '@toast-ui/editor/types';

	@Component({
		components: {AsiBtn, Editor}
	})
	export default class AsiMarkDownEditor extends Vue {

		private static readonly HEIGHT_DEFAULT = '300px';
		private static readonly HEIGHT_EXPANDED = '700px';

		public static readonly MODE_MARKDOWN = 10;
		public static readonly MODE_HTML = 20;

		@Prop({type: Number, default: 10})
		public mode!: number;

		@Prop({type: String, default: null})
		public value!: string | null;

		@Prop({type: String, default: null})
		public label!: string | null;

		@Prop({type: String, default: null})
		public height!: string | null;

		@Prop({type: Boolean, default: false})
		public hideExpander!: boolean;

		@Prop({type: Boolean, default: false})
		public keepChangedValues!: boolean;

		private icons = Icon;

		public mounted(): void {
		    this.setHeight();
		}

		private get defaultOptions(): EditorOptions {
			return {
				height: this.initialHeight,
				minHeight: this.initialHeight,
				language: this.$i18n.locale.substring(0, 2),
				useCommandShortcut: true,
				usageStatistics: false,
				hideModeSwitch: false,
				placeholder: this.placeholder,
				toolbarItems: [
					['heading'],
					['bold', 'italic', 'strike'],
					['hr', 'quote'],
					['ul', 'ol', 'task'],
					['table', 'link'],
					['code', 'codeblock'],
					['scrollSync'],
				] as any,
				linkAttributes: {
					target: '_blank',
					rel: 'noopener noreferrer'
				} as LinkAttributes,
				hooks: {
					addImageBlobHook: this.pasted,
				} as HookMap,
			} as EditorOptions;
		}

		private get editorInstance(): Editor | null {
			return this.$refs.editor as Editor | undefined ?? null;
		}

		private get editType(): 'wysiwyg' | 'markdown' {
			return this.$store.state.ui.editor.editType;
		}

		private set editType(editType: 'wysiwyg' | 'markdown') {
			this.$store.commit('ui/setEditorEditType', editType);
		}

		private get expanded(): boolean {
			return this.hideExpander ? false : this.$store.state.ui.editor.expanded;
		}

		private set expanded(expanded: boolean) {
			this.$store.commit('ui/setEditorExpanded', expanded);
		}

		private get placeholder(): string {
			return this.label ?? this.$t('ui.terms.description').toString();
		}

		private get initialHeight(): string {
			return this.height ?? (this.$store.state.ui.editor.expanded ? AsiMarkDownEditor.HEIGHT_EXPANDED : AsiMarkDownEditor.HEIGHT_DEFAULT);
		}

		private get previewStyle(): 'tab' | 'vertical' {
			return this.$store.state.ui.editor.previewStyle;
		}

		private set previewStyle(previewStyle: 'tab' | 'vertical') {
			this.$store.commit('ui/setEditorPreviewStyle', previewStyle);
		}

		@Watch('height')
		private onHeightChanged(): void {
			this.setHeight();
		}

		@Watch('expanded')
		private onExpandedChanged(): void {
			if (this.height !== null) return;
			this.setHeight();
		}

		@Emit('input')
		public input(): string | null {
			return this.getEditorValue();
		}

		@Emit('pasted')
		public pasted(blob: Blob): Blob {
			return blob;
		}

		@Watch('value')
		public onValueChanged(val: string) {
			const oldVal = this.getEditorValue();
			const newVal = this.toEditorValue(val);

			if (oldVal !== newVal) {
				this.setEditorValue(val);
			}
		}

		private setEditorValue(val: string | null): void {
			const instance = this.editorInstance;
			if (instance === null) return;
			const method = this.mode === AsiMarkDownEditor.MODE_HTML ? 'setHtml' : 'setMarkdown';

			//@ts-ignore
			instance.invoke(method, this.toEditorValue(val));
			this.setHeight();
		}

		private getEditorValue(): string | null {
			const instance = this.editorInstance;
			if (instance === null) return null;
			const method = this.mode === AsiMarkDownEditor.MODE_HTML ? 'getHtml' : 'getMarkdown';

			//@ts-ignore
			return this.fromEditorValue(instance.invoke(method));
		}

		private setHeight(): void {
			const instance = this.editorInstance;
			if (instance === null) return;
			const height = this.height ?? (this.expanded ? AsiMarkDownEditor.HEIGHT_EXPANDED : AsiMarkDownEditor.HEIGHT_DEFAULT);
			//@ts-ignore
			instance.invoke('setHeight', height);
		}

		// noinspection JSMethodCanBeStatic
		private toEditorValue(val: string | null): string {
			return val ?? '';
		}

		// noinspection JSMethodCanBeStatic
		private fromEditorValue(val: string): string | null {
			val = val.trim();
			return val.length === 0 ? null : val;
		}

		private togglePreviewStyle(): void {
			this.previewStyle = this.previewStyle === 'tab' ? 'vertical' : 'tab';
		}

		private editorContainerClicked(event: PointerEvent): void {
			const target = event.target as HTMLElement | null;
			if (target === null) return;
			const parentElement = target.parentElement;
			if (parentElement === null) return;

			if (parentElement.classList.contains('toastui-editor-mode-switch')) {
				const currentEditType = this.getEditType();
				if (currentEditType !== null && this.editType !== currentEditType) {
					this.editType = currentEditType;
				}
			}
		}

		private getEditType(): 'wysiwyg' | 'markdown' | null {
			const instance = this.editorInstance;
			if (instance === null) return null;

			//@ts-ignore
			return instance.invoke('isMarkdownMode') ? 'markdown' : 'wysiwyg';
		}

	}
</script>

<style lang="scss" scoped>
	.flipped {
		transform: rotate(180deg);
	}
</style>
