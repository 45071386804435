import {Module} from "vuex";
import {State} from "@/store";
import {ICartShopListEntry} from "@/models/cart/CartShopModels";
import Vue from "vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import CartPositionCreate from "@/models/cart-position/CartPositionCreate";
import {IUserShop} from "@/models/user/UserShopModels";
import CartCreate from "@/models/cart/CartCreate";
import {IAddressFields} from "@/models/address/AddressModels";
import CartHelper from "@/helpers/CartHelper";
import {IItemShopListEntry} from "@/models/item/ItemShopModels";
import Gtm, {itemShopListEntryToGtmItem, itemShopSimpleToGtmItem} from "@/plugins/gtm";
import TotalHelper from "@/helpers/TotalHelper";
import FrameAssemblyCreate from "@/models/frame-assembly/FrameAssemblyCreate";

export interface Cart {
	loading: boolean;
	expanded: boolean;
	activeCartId: string | null,
	carts: ICartShopListEntry[],
}

const cart: Module<Cart, State> = {
	namespaced: true,
	state: {
		loading: false,
		expanded: false,
		activeCartId: null as string | null,
		carts: [] as ICartShopListEntry[],
	},
	getters: {
		activeCart: (state): ICartShopListEntry | null => {
			if (state.activeCartId === null) return null;
			return state.carts.find(c => c.id === state.activeCartId) ?? null;
		},
		cartById: (state) => (id: string): ICartShopListEntry | null => {
			return state.carts.find(c => c.id === id) ?? null;
		},
		cartByPositionId: (state) => (id: string): ICartShopListEntry | null => {
			return state.carts.find(c => c.positions.some(p => p.id === id)) ?? null;
		},
		activeCartIndex: (state): number | null => {
			if (state.activeCartId === null) return null;
			const index = state.carts.findIndex(c => c.id === state.activeCartId);
			return index < 0 ? null : index;
		},
		prevCart: (state, getters): ICartShopListEntry | null => {
			const curIndex = getters.activeCartIndex;
			return curIndex === null
				? null
				: (curIndex === 0 ? null : state.carts[curIndex - 1]);
		},
		nextCart: (state, getters): ICartShopListEntry | null => {
			const curIndex = getters.activeCartIndex;
			return curIndex === null
				? null
				: (curIndex === state.carts.length - 1 ? null : state.carts[curIndex + 1]);
		},
		hasPrev: (state, getters): boolean => {
			return getters.prevCart !== null;
		},
		hasNext: (state, getters): boolean => {
			return getters.nextCart !== null;
		},
	},
	mutations: {
		setLoading(state: Cart, isLoading: boolean): void {
			state.loading = isLoading;
		},
		setCarts(state: Cart, carts: ICartShopListEntry[]): void {
			state.carts = carts.sort(CartHelper.sort);
		},
		setActiveCartId(state: Cart, id: string | null): void {
			state.activeCartId = id;
		},
		setExpanded(state: Cart, expanded: boolean): void {
			const oldExpanded = state.expanded;
			state.expanded = expanded;

			const activeCart = state.carts.find(c => c.id === state.activeCartId) ?? null;
			if (activeCart === null) {
				return;
			}

			if (!oldExpanded && state.expanded) {
				TotalHelper.totalInclVat(activeCart, true).forEach(total => {
					Gtm.viewCart({
						value: total.amount,
						currency: total.currency.currencyCode,
						items: activeCart.positions.map(p => itemShopSimpleToGtmItem(p.item, 1, 1))
					});
				});
			}

		},
	},
	actions: {
		async loadCarts(context) {
			try {
				const isLoggedIn = context.rootGetters['user/isLoggedIn'];
				const activeCartId = context.state.activeCartId;

				if (isLoggedIn) {
					const options = new AsiListTableOptions();
					options.itemsPerPage = 0;

					context.commit('setLoading', true);
					const data = await Vue.$cartServiceShop.carts(null, options);
					context.commit('setCarts', data.data);
				} else if (activeCartId !== null) {
					context.commit('setLoading', true);
					const cart = await Vue.$cartServiceShop.cart(activeCartId);
					context.commit('setCarts', [cart]);
				} else {
					context.commit('setCarts', []);
				}

				if (context.state.activeCartId !== null && !context.state.carts.some(c => c.id === context.state.activeCartId)) {
					context.commit('setActiveCartId', null);
				}
			} catch (e) {
				console.error('loading carts failed', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async loadCart(context, cartId: string) {
			try {
				context.commit('setLoading', true);
				const cart = await Vue.$cartServiceShop.cart(cartId);
				context.commit('setCarts', context.state.carts.filter(c => c.id !== cart.id).concat([cart]));
			} catch (e) {
				console.error('loading cart failed', cartId, e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async createCart(context, payload: { name: string | null, positions: CartPositionCreate[] } | null = null) {
			try {
				const userModel: IUserShop | null = context.rootGetters['user/model'];

				const model = new CartCreate();
				model.customerId = userModel?.customer.id ?? null;
				model.name = payload?.name ?? null;
				model.positions = payload?.positions ?? [];

				context.commit('setLoading', true);
				const result = await Vue.$cartServiceShop.create(model);
				await context.dispatch('loadCart', result.id);
				context.commit('setActiveCartId', result.id);
			} catch (e) {
				console.error('creating cart failed', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async deleteCart(context, cartId: string) {
			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.delete(cartId);
				if (context.state.activeCartId === cartId) {
					const newCart = context.state.carts.filter(c => c.id !== cartId)[0] ?? null;
					context.commit('setActiveCartId', newCart);
				}
				await context.dispatch('loadCarts');
			} catch (e) {
				console.error('deleting cart failed', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async clearCart(context, cartId: string) {
			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.clearPositions(cartId);
				await context.dispatch('loadCart', cartId);
			} catch (e) {
				console.error('clearing cart failed', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async updateCustomer(context, payload: { cartId: string, customerId: string }) {
			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.updateCustomer(payload.cartId, payload.customerId);
				await context.dispatch('loadCart', payload.cartId);
			} catch (e) {
				console.error('error while setting name', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async updateName(context, payload: { cartId: string, name: string | null }) {
			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.updateName(payload.cartId, payload.name);
				await context.dispatch('loadCart', payload.cartId);
			} catch (e) {
				console.error('error while setting name', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async updateOneTimeEmail(context, payload: { cartId: string, email: string | null }) {
			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.updateOneTimeEmail(payload.cartId, payload.email);
				await context.dispatch('loadCart', payload.cartId);
			} catch (e) {
				console.error('error while setting one time email', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async updateOneTimePhone(context, payload: { cartId: string, phone: string | null }) {
			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.updateOneTimePhone(payload.cartId, payload.phone);
				await context.dispatch('loadCart', payload.cartId);
			} catch (e) {
				console.error('error while setting one time phone', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async updateRemark(context, payload: { cartId: string, remark: string | null }) {
			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.updateRemark(payload.cartId, payload.remark);
				await context.dispatch('loadCart', payload.cartId);
			} catch (e) {
				console.error('error while setting remark', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async updateCustomerReference(context, payload: { cartId: string, customerReference: string | null }) {
			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.updateCustomerReference(payload.cartId, payload.customerReference);
				await context.dispatch('loadCart', payload.cartId);
			} catch (e) {
				console.error('error while setting customer reference', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async updateShipmentType(context, payload: { cartId: string, shipmentTypeId: string | null }) {
			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.updateShipmentType(payload.cartId, payload.shipmentTypeId);
				await context.dispatch('loadCart', payload.cartId);
			} catch (e) {
				console.error('error while setting shipment type', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async updatePaymentType(context, payload: { cartId: string, paymentTypeId: string | null }) {
			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.updatePaymentType(payload.cartId, payload.paymentTypeId);
				await context.dispatch('loadCart', payload.cartId);
			} catch (e) {
				console.error('error while setting payment type', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async updateShippingAddress(context, payload: {
			cartId: string,
			addressId: string | null,
			addressFields: IAddressFields | null
		}) {
			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.updateShippingAddress(payload.cartId, payload.addressId, payload.addressFields ?? {} as IAddressFields);
				await context.dispatch('loadCart', payload.cartId);
			} catch (e) {
				console.error('error while setting shipping address', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async updateBillingAddress(context, payload: {
			cartId: string,
			addressId: string | null,
			addressFields: IAddressFields | null
		}) {
			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.updateBillingAddress(payload.cartId, payload.addressId, payload.addressFields ?? {} as IAddressFields);
				await context.dispatch('loadCart', payload.cartId);
			} catch (e) {
				console.error('error while setting billing address', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async addPosition(context, payload: { item: IItemShopListEntry, frameAssembly: FrameAssemblyCreate | null, quantity: number, cartId: string | null }) {
			try {
				const finalCartId = payload.cartId ?? context.state.activeCartId;

				const model = new CartPositionCreate();
				model.itemId = payload.item?.id;
				model.frameAssembly = payload.frameAssembly;
				model.quantity = payload.quantity;

				if (finalCartId === null) {
					await context.dispatch('createCart', {
						name: null,
						positions: [model],
					});
				} else {
					context.commit('setLoading', true);
					await Vue.$cartServiceShop.addPosition(finalCartId, model);
					context.commit('setActiveCartId', finalCartId);
					await context.dispatch('loadCart', finalCartId);
				}

				const unitPrice = payload.item.prices
					.sort((p1, p2) => p2.fromQuantity - p1.fromQuantity)
					.find(p => p.fromQuantity <= payload.quantity)?.price ?? null;

				if (unitPrice !== null) {
					Gtm.addToCart({
						items: [
							itemShopListEntryToGtmItem(payload.item, payload.quantity, unitPrice.amount)
						],
						currency: unitPrice.currency.currencyCode,
						value: (TotalHelper.vatAmount(unitPrice) + unitPrice.amount) * payload.quantity,
					});
				}

			} catch (e) {
				console.error('creating cart position failed', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async removePosition(context, positionId: string) {
			const cart: ICartShopListEntry | null = context.getters['cartByPositionId'](positionId);
			if (cart === null) {
				console.error('cart for position to remove not found');
				return;
			}

			const position = cart.positions.find(p => p.id === positionId) ?? null;
			if (position === null) {
				// this should never happen since the cart was found by the position id.
				console.error('position to remove not found');
				return;
			}

			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.removePosition(cart.id, positionId);

				if (position.price !== null) {
					Gtm.removeFromCart({
						currency: cart.id,
						value: (TotalHelper.vatAmount(position.price) + position.price.amount) * position.quantity,
						items: [
							itemShopSimpleToGtmItem(position.item, position.price.amount, position.quantity),
						],
					});
				}
			} catch (e) {
				console.error('removing position failed', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}

			await context.dispatch('loadCart', cart.id);
		},
		async updatePositionQuantity(context, payload: { positionId: string, quantity: number }) {
			const cart = context.getters['cartByPositionId'](payload.positionId);
			if (cart === null) {
				console.error('cart for position to change quantity for not found');
			}

			try {
				if (payload.quantity <= 0) {
					await context.dispatch('removePosition', payload.positionId);
				} else {
					context.commit('setLoading', true);
					await Vue.$cartServiceShop.updateQuantity(cart.id, payload.positionId, payload.quantity);
					await context.dispatch('loadCart', cart.id);
				}
			} catch (e) {
				console.error('changing quantity failed', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
		async updatePositionCustomerReference(context, payload: {
			positionId: string,
			customerReference: string | null
		}) {
			const cart = context.getters['cartByPositionId'](payload.positionId);
			if (cart === null) {
				console.error('cart for position to change reference for not found');
			}

			try {
				context.commit('setLoading', true);
				await Vue.$cartServiceShop.updatePositionCustomerReference(cart.id, payload.positionId, payload.customerReference);
				await context.dispatch('loadCart', cart.id);
			} catch (e) {
				console.error('error while updating customer reference of cart position', e);
				throw e;
			} finally {
				context.commit('setLoading', false);
			}
		},
	},
};

export default cart;
