import store from "@/store";

export default class AsiListTableOptions {

	public sortBy: string[];
	public sortDesc: boolean[];
	public page: number;
	public itemsPerPage: number;

	constructor(sortBy: string[] = [], sortDesc: boolean[] = [], page: number = 1, itemsPerPage: number | null = null) {
		this.sortBy = sortBy;
		this.sortDesc = sortDesc;
		this.page = page;
		this.itemsPerPage = itemsPerPage ?? store.state.ui.itemsPerPage;
	}

}
