import ICategoryServiceShop from "@/services/definition/ICategoryServiceShop";
import CategoryListFilter from "@/models/category/CategoryListFilter";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import {ICategoryShop, ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
import Vue from "vue";
import AxiosService from "@/services/AxiosService";
import ItemListFilterShop from "@/models/item/ItemListFilterShop";
import {IItemShopPaginatedResponse} from "@/models/item/IItemShopPaginatedResponse";
import LocaleHelper from "@/helpers/LocaleHelper";

export default class AxiosCategoryServiceShop extends AxiosService implements ICategoryServiceShop {

	public static readonly BASE_URL = '/shop/categories';

	protected defineBaseUrl(): string {
		return AxiosCategoryServiceShop.BASE_URL;
	}

	public category(id: string): Promise<ICategoryShop> {
		return Vue.$axios.get<ICategoryShop>(this.url(id)).then(res => res.data);
	}

	public categories(filter: CategoryListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<ICategoryShopListEntry>> {
		const opt = new AsiListTableOptions();
		opt.sortBy = options !== null ? options.sortBy : ['name'];
		opt.sortDesc = options !== null ? options.sortDesc : [false];
		opt.page = options !== null ? options.page : 1;
		opt.itemsPerPage = options !== null ? options.itemsPerPage : 0;

		const lang = LocaleHelper.currentLanguage();
		opt.sortBy = opt.sortBy.map(field => {
			return field.startsWith('name') ? `name.${lang}` : field;
		});

		return Vue.$axios.get<IPaginatedResponse<ICategoryShopListEntry>>(this.url(), {
			params: {
				...filter,
				...opt
			}
		}).then(res => res.data);
	}

	public subCategories(id: string, filter: CategoryListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<ICategoryShopListEntry>> {
		return Vue.$axios.get<IPaginatedResponse<ICategoryShopListEntry>>(this.url(`${id}/categories`), {
			params: {
				...filter,
				...options
			}
		}).then(res => res.data);

	}

	public items(id: string, filter: ItemListFilterShop | null, options: AsiListTableOptions | null): Promise<IItemShopPaginatedResponse> {
		return Vue.$axios.get<IItemShopPaginatedResponse>(this.url(`${id}/items`), {
			params: {
				...filter,
				...options
			}
		}).then(res => res.data);
	}

}
