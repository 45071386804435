import Snackbar from "@/helpers/Snackbar";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;
import i18n from "@/plugins/i18n";

export default class ClipboardHelper {

	public static copyText(text: string, successMessage: string | TranslateResult | null = null, errorMessage: string | TranslateResult | null = null, messageTimeout: number = 2000): void {
		navigator.clipboard.writeText(text)
			.then(() => {
				if (successMessage === null) successMessage = i18n.t('snackbar.copyClipboardSuccess');
				Snackbar.show(successMessage.toString(), 'success', messageTimeout);
			})
			.catch(() => {
				if (errorMessage === null) errorMessage = i18n.t('snackbar.copyClipboardError');
				Snackbar.show(errorMessage.toString(), 'success', messageTimeout);
			});
	}

}
