import {IShoppingListShopListEntry} from "@/models/shopping-list/ShoppingListShopModels";
import {Module} from "vuex";
import Vue from "vue";
import {State} from "@/store";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import ShoppingListPositionCreate from "@/models/shopping-list-position/ShoppingListPositionCreate";
import ShoppingListCreate from "@/models/shopping-list/ShoppingListCreate";

export interface ShoppingList {
	shoppingLists: IShoppingListShopListEntry[],
}

const shoppingList: Module<ShoppingList, State> = {
	namespaced: true,
	state: {
		shoppingLists: [] as IShoppingListShopListEntry[],
	},
	getters: {
		shoppingListByPositionId: (state) => (id: string): IShoppingListShopListEntry | null => {
			return state.shoppingLists.find(s => s.positions.some(p => p.id === id)) ?? null;
		}
	},
	mutations: {
		setShoppingLists(state: ShoppingList, shoppingLists: IShoppingListShopListEntry[]): void {
			state.shoppingLists = shoppingLists;
		}
	},
	actions: {
		async loadShoppingLists(context) {
			try {
				const isLoggedIn = context.rootGetters['user/isLoggedIn'];

				if (isLoggedIn) {
					const userId = context.rootGetters['user/userId'];
					const options = new AsiListTableOptions();
					options.itemsPerPage = 0;

					const data = await Vue.$shoppingListServiceShop.shoppingLists(userId, null, options);
					context.commit('setShoppingLists', data.data);
				}
			} catch (e) {
				console.error('loading shopping-lists failed', e);
				throw e;
			}
		},
		async createShoppingList(context, payload: { name: string | null, positions: ShoppingListPositionCreate[] } | null = null) {
			try {
				const isLoggedIn = context.rootGetters['user/isLoggedIn'];

				if (isLoggedIn) {
					const userId = context.rootGetters['user/userId'];

					let model: ShoppingListCreate | null = null;
					if (payload != null) {
						model = new ShoppingListCreate();
						model.name = payload.name ?? null;
						model.positions = payload.positions;
					}
					await Vue.$shoppingListServiceShop.create(userId, model);
				}
			} catch (e) {
				console.error('create shopping-list failed', e);
				throw e;
			} finally {
				await context.dispatch('loadShoppingLists');
			}
		},
		async deleteShoppingList(context, id: string) {
			try {
				await Vue.$shoppingListServiceShop.delete(id);
			} catch (e) {
				console.error('delete shopping-list failed', e);
			} finally {
				await context.dispatch('loadShoppingLists');
			}
		},
		async updateName(context, payload: { shoppingListId: string, name: string }) {
			try {
				await Vue.$shoppingListServiceShop.updateName(payload.shoppingListId, payload.name);
			} catch (e) {
				console.error('update shopping-list name failed', e);
				throw e;
			} finally {
				await context.dispatch('loadShoppingLists');
			}
		},
		async addPosition(context, payload: { itemId: string, quantity: number, shoppingListId: string | null }) {
			try {

				if (context.state.shoppingLists.length === 0) {
					await context.dispatch('createShoppingList');
				}
				const finalShoppingListId = payload.shoppingListId ?? context.state.shoppingLists[0].id;

				const model = new ShoppingListPositionCreate();
				model.itemId = payload.itemId;
				model.quantity = payload.quantity;

				await Vue.$shoppingListServiceShop.addPosition(finalShoppingListId, model);

			} catch (e) {
				console.error('creating shopping-list position failed', e);
				throw e;
			} finally {
				await context.dispatch('loadShoppingLists');
			}
		},
		async updateQuantity(context, payload: { positionId: string, quantity: number }) {
			try {
				const shoppingListId = context.getters['shoppingListByPositionId'](payload.positionId).id;
				await Vue.$shoppingListServiceShop.updatePosition(shoppingListId, payload.positionId, payload.quantity);
			} catch (e) {
				console.error('update position failed', e);
			}
		},
		async deletePosition(context, positionId: string) {
			try {
				const shoppingListId = context.getters['shoppingListByPositionId'](positionId).id;
				await Vue.$shoppingListServiceShop.deletePosition(shoppingListId, positionId);
			} catch (e) {
				console.error('delete position failed', e);
			} finally {
				await context.dispatch('loadShoppingLists');
			}
		}
	}
};

export default shoppingList;
