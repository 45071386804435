import RequiredValidator from "@/validators/RequiredValidator";
import {RangeValidator} from "@/validators/RangeValidator";
import EnumHelper from "@/helpers/EnumHelper";
import {CategoryState} from "@/helpers/constants";

export default class CategoryRules {
	public static readonly stateRules = [
		(new RequiredValidator('category.state')).validationRule(),
		(new RangeValidator('category.state', true, EnumHelper.toArray(CategoryState).map(c => c.value))).validationRule(),
	];
}
