import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";
import AvatarRules from "@/models/attachment/AvatarRules";
import IModel from "@/models/IModel";

export default class AvatarCreate implements IFormModel {

	public model: IModel | null = null;
	public file: File | null = null;

	public reset(): void {
		this.model = null;
		this.file = null;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'file':
				return AvatarRules.fileRules;
			default:
				return [];
		}
	}

}
