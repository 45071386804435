import ModelUpdate from "@/models/ModelUpdate";
import {IItemAdminListEntry} from "@/models/item/ItemAdminModels";
import {ValidationRule} from "@/declarations/ValidationRule";
import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";

export default class ItemUpdateAttributes extends ModelUpdate<IItemAdminListEntry> {

	public grossWeight!: number | null;
	public length!: number | null;
	public width!: number | null;
	public height!: number | null;

	public material!: ITranslatedValue | null;
	public color!: ITranslatedValue | null;
	public type!: ITranslatedValue | null;
	public rebate!: number | null;
	public rebateSpace!: number | null;
	public grooveWidth!: number | null;
	public grooveDepth!: number | null;
	public hardnessType!: ITranslatedValue | null;
	public hardness!: number | null;
	public properties!: ITranslatedValue | null;
	public density!: number | null;
	public connectionType!: ITranslatedValue | null;
	public surfaceTreatment!: ITranslatedValue | null;
	public tolerance!: ITranslatedValue | null;
	public fireProtectionStandard!: ITranslatedValue | null;
	public foodStandard!: string | null;
	public otherStandard!: string | null;
	public fixture!: string | null;
	public wrenchSize!: number | null;
	public thread!: string | null;
	public threadLength!: number | null;
	public diameterMm!: number | null;
	public applicationRange!: string | null;
	public resistance!: string | null;
	public system!: string | null;
	public glassThickness!: number | null;
	public innerDiameter!: number | null;
	public outerDiameter!: number | null;
	public ringDiameter!: number | null;
	public crossSection!: number | null;
	public assemblyGroup!: string | null;

	public constructor(model: IItemAdminListEntry) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IItemAdminListEntry): void {
		this.grossWeight = detailModel.grossWeight;
		this.length = detailModel.length;
		this.width = detailModel.width;
		this.height = detailModel.height;

		this.material = detailModel.material;
		this.color = detailModel.color;
		this.type = detailModel.type;
		this.rebate = detailModel.rebate;
		this.rebateSpace = detailModel.rebateSpace;
		this.grooveWidth = detailModel.grooveWidth;
		this.grooveDepth = detailModel.grooveDepth;
		this.hardnessType = detailModel.hardnessType;
		this.hardness = detailModel.hardness;
		this.properties = detailModel.properties;
		this.density = detailModel.density;
		this.connectionType = detailModel.connectionType;
		this.surfaceTreatment = detailModel.surfaceTreatment;
		this.tolerance = detailModel.tolerance;
		this.fireProtectionStandard = detailModel.fireProtectionStandard;
		this.foodStandard = detailModel.foodStandard;
		this.otherStandard = detailModel.otherStandard;
		this.fixture = detailModel.fixture;
		this.wrenchSize = detailModel.wrenchSize;
		this.thread = detailModel.thread;
		this.threadLength = detailModel.threadLength;
		this.diameterMm = detailModel.diameterMm;
		this.applicationRange = detailModel.applicationRange;
		this.resistance = detailModel.resistance;
		this.system = detailModel.system;
		this.glassThickness = detailModel.glassThickness;
		this.innerDiameter = detailModel.innerDiameter;
		this.outerDiameter = detailModel.outerDiameter;
		this.ringDiameter = detailModel.ringDiameter;
		this.crossSection = detailModel.crossSection;
		this.assemblyGroup = detailModel.assemblyGroup;
	}

	public rules(): ValidationRule[] {
		return [];
	}
}
