import {Module} from "vuex";
import {State} from "@/store";
import Vue from "vue";
import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";

export interface GlobalConfig {
	modelAdmin: IGlobalConfigAdmin | null;
}

const globalConfig: Module<GlobalConfig, State> = {
	namespaced: true,
	state: {
		modelAdmin: null as IGlobalConfigAdmin | null,
	},
	mutations: {
		setModelAdmin(state: GlobalConfig, model: IGlobalConfigAdmin) {
			state.modelAdmin = model;
		},
	},
	actions: {
		async reload(context) {
			try {
				const modelAdmin = await Vue.$globalConfigServiceAdmin.globalConfig();
				if (modelAdmin !== null) context.commit('setModelAdmin', modelAdmin);
			} catch (e) {
				console.error('loading global config failed', e);
			}
		}
	},
};

export default globalConfig;
