import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";
import AddressRules from "@/models/address/AddressRules";
import {Salutation} from "@/helpers/constants";

export default class AddressCreate implements IFormModel {

	public isDefaultShippingAddress: boolean = false;
	public isDefaultBillingAddress: boolean = false;

	public label: string | null = null;

	public salutation: Salutation = Salutation.mr;
	public title: string | null = null;
	public firstname: string | null = null;
	public middlename: string | null = null;
	public lastname: string | null = null;
	public company: string | null = null;
	public companySector: string | null = null;
	public role: string | null = null;
	public address1: string | null = null;
	public address2: string | null = null;
	public street: string | null = null;
	public houseNumber: string | null = null;
	public poBox: string | null = null;
	public zip: string | null = null;
	public city: string | null = null;
	public county: string | null = null;
	public state: string | null = null;
	public countryCode: string | null = null;

	public reset(): void {
		this.isDefaultShippingAddress = false;
		this.isDefaultBillingAddress = false;

		this.label = null;

		this.salutation = Salutation.mr;
		this.title = null;
		this.firstname = null;
		this.middlename = null;
		this.lastname = null;
		this.company = null;
		this.companySector = null;
		this.role = null;
		this.address1 = null;
		this.address2 = null;
		this.street = null;
		this.houseNumber = null;
		this.poBox = null;
		this.zip = null;
		this.city = null;
		this.county = null;
		this.state = null;
		this.countryCode = null;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'salutation':
				return AddressRules.salutationRules;
			case 'firstname':
				return AddressRules.firstnameRules;
			case 'lastname':
				return AddressRules.lastnameRules;
			case 'company':
				return AddressRules.companyRules;
			case 'street':
				return AddressRules.streetRules;
			case 'houseNumber':
				return AddressRules.houseNumberRules;
			case 'zip':
				return AddressRules.zipRules;
			case 'city':
				return AddressRules.cityRules;
			case 'countryCode':
				return AddressRules.countryCodeRules;
			default:
				return [];
		}
	}

}
