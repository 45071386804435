import IListFilter from "@/models/IListFilter";
import StringHelper from "@/helpers/StringHelper";

export default abstract class ItemListFilter implements IListFilter {

	public fulltext: string | null = null;

	public itemNumber: string | null = null;
	public name: string | null = null;
	public description: string | null = null;
	public priceFrom: number | null = null;
	public priceTo: number | null = null;
	public isTopSeller: boolean | null = null;
	public canOrderSample: boolean | null = null;
	public grossWeightFrom: number | null = null;
	public grossWeightTo: number | null = null;
	public lengthFrom: number | null = null;
	public lengthTo: number | null = null;
	public widthFrom: number | null = null;
	public widthTo: number | null = null;
	public heightFrom: number | null = null;
	public heightTo: number | null = null;
	public materials: string[] = [];
	public colors: string[] = [];
	public types: string[] = [];
	public rebateFrom: number | null = null;
	public rebateTo: number | null = null;
	public grooveWidthFrom: number | null = null;
	public grooveWidthTo: number | null = null;
	public grooveDepthFrom: number | null = null;
	public grooveDepthTo: number | null = null;
	public rebateSpaceFrom: number | null = null;
	public rebateSpaceTo: number | null = null;
	public hardnessTypes: string[] = [];
	public hardnessFrom: number | null = null;
	public hardnessTo: number | null = null;
	public properties: string[] = [];
	public densityFrom: number | null = null;
	public densityTo: number | null = null;
	public connectionTypes: string[] = [];
	public surfaceTreatments: string[] = [];
	public tolerances: string[] = [];
	public fireProtectionStandards: string[] = [];
	public foodStandards: string[] = [];
	public otherStandards: string[] = [];
	public fixtures: string[] = [];
	public wrenchSizeFrom: number | null = null;
	public wrenchSizeTo: number | null = null;
	public threadLengthFrom: number | null = null;
	public threadLengthTo: number | null = null;
	public threads: string[] = [];
	public diameterMmFrom: number | null = null;
	public diameterMmTo: number | null = null;
	public applicationRanges: string[] = [];
	public resistances: string[] = [];
	public systems: string[] = [];
	public glassThicknessFrom: number | null = null;
	public glassThicknessTo: number | null = null;
	public innerDiameterFrom: number | null = null;
	public innerDiameterTo: number | null = null;
	public outerDiameterFrom: number | null = null;
	public outerDiameterTo: number | null = null;
	public ringDiameterFrom: number | null = null;
	public ringDiameterTo: number | null = null;
	public crossSectionFrom: number | null = null;
	public crossSectionTo: number | null = null;
	public assemblyGroups: string[] = [];

	public clear() {
		this.fulltext = null;
		this.itemNumber = null;
		this.name = null;
		this.description = null;
		this.priceFrom = null;
		this.priceTo = null;
		this.isTopSeller = null;
		this.canOrderSample = null;
		this.grossWeightFrom = null;
		this.grossWeightTo = null;
		this.lengthFrom = null;
		this.lengthTo = null;
		this.widthFrom = null;
		this.widthTo = null;
		this.heightFrom = null;
		this.heightTo = null;
		this.materials = [];
		this.colors = [];
		this.types = [];
		this.rebateFrom = null;
		this.rebateTo = null;
		this.grooveWidthFrom = null;
		this.grooveWidthTo = null;
		this.grooveDepthFrom = null;
		this.grooveDepthTo = null;
		this.rebateSpaceFrom = null;
		this.rebateSpaceTo = null;
		this.hardnessTypes = [];
		this.hardnessFrom = null;
		this.hardnessTo = null;
		this.properties = [];
		this.densityFrom = null;
		this.densityTo = null;
		this.connectionTypes = [];
		this.surfaceTreatments = [];
		this.tolerances = [];
		this.fireProtectionStandards = [];
		this.foodStandards = [];
		this.otherStandards = [];
		this.fixtures = [];
		this.wrenchSizeFrom = null;
		this.wrenchSizeTo = null;
		this.threadLengthFrom = null;
		this.threadLengthTo = null;
		this.threads = [];
		this.diameterMmFrom = null;
		this.diameterMmTo = null;
		this.applicationRanges = [];
		this.resistances = [];
		this.systems = [];
		this.glassThicknessFrom = null;
		this.glassThicknessTo = null;
		this.innerDiameterFrom = null;
		this.innerDiameterTo = null;
		this.outerDiameterFrom = null;
		this.outerDiameterTo = null;
		this.ringDiameterFrom = null;
		this.ringDiameterTo = null;
		this.crossSectionFrom = null;
		this.crossSectionTo = null;
		this.assemblyGroups = [];
	}

	public hasFilter(): boolean {
		return this.materials.length > 0
			|| this.colors.length > 0
			|| this.types.length > 0
			|| this.hardnessTypes.length > 0
			|| this.properties.length > 0
			|| this.connectionTypes.length > 0
			|| this.surfaceTreatments.length > 0
			|| this.tolerances.length > 0
			|| this.fireProtectionStandards.length > 0
			|| this.foodStandards.length > 0
			|| this.otherStandards.length > 0
			|| this.fixtures.length > 0
			|| this.threads.length > 0
			|| this.applicationRanges.length > 0
			|| this.resistances.length > 0
			|| this.systems.length > 0
			|| this.assemblyGroups.length > 0
			|| [
				this.priceFrom,
				this.priceTo,
				this.isTopSeller,
				this.canOrderSample,
				this.grossWeightFrom,
				this.grossWeightTo,
				this.lengthFrom,
				this.lengthTo,
				this.widthFrom,
				this.widthTo,
				this.heightFrom,
				this.heightTo,
				this.rebateFrom,
				this.rebateTo,
				this.grooveWidthFrom,
				this.grooveWidthTo,
				this.grooveDepthFrom,
				this.grooveDepthTo,
				this.rebateSpaceFrom,
				this.rebateSpaceTo,
				this.hardnessFrom,
				this.hardnessTo,
				this.densityFrom,
				this.densityTo,
				this.wrenchSizeFrom,
				this.wrenchSizeTo,
				this.threadLengthFrom,
				this.threadLengthTo,
				this.diameterMmFrom,
				this.diameterMmTo,
				this.glassThicknessFrom,
				this.glassThicknessTo,
				this.innerDiameterFrom,
				this.innerDiameterTo,
				this.outerDiameterFrom,
				this.outerDiameterTo,
				this.ringDiameterFrom,
				this.ringDiameterTo,
				this.crossSectionFrom,
				this.crossSectionTo,
			].some(v => v !== null)
			|| !StringHelper.allEmpty([
				this.fulltext,
				this.itemNumber,
				this.name,
				this.description,
			]);
	}

}
