import ModelUpdate from "@/models/ModelUpdate";
import {IPromotionAdminListEntry} from "@/models/promotion/PromotionAdminModels";
import PromotionRules from "@/models/promotion/PromotionRules";
import {ValidationRule} from "@/declarations/ValidationRule";

export default class PromotionUpdate extends ModelUpdate<IPromotionAdminListEntry> {

	public itemId!: string;
	public minimumOrderPrice!: number;
	public validFrom!: string | null;
	public validTo!: string | null;

	public constructor(model: IPromotionAdminListEntry) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IPromotionAdminListEntry): void {
		this.itemId = detailModel.item.id;
		this.minimumOrderPrice = detailModel.minimumOrderPrice.amount;
		this.validFrom = detailModel.validFrom;
		this.validTo = detailModel.validTo;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'itemId':
				return PromotionRules.itemIdRules;
			case 'minimumOrderPrice':
				return PromotionRules.minimumOrderPriceRules;
			case 'validFrom':
				return PromotionRules.validFromRules;
			case 'validTo':
				return PromotionRules.validToRules;
			default:
				return [];
		}
	}

}
