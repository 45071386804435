import ModelUpdate from "@/models/ModelUpdate";
import {IItemAdminListEntry} from "@/models/item/ItemAdminModels";
import {UnitOfMeasure} from "@/helpers/constants";
import {ValidationRule} from "@/declarations/ValidationRule";
import ItemRules from "@/models/item/ItemRules";

export default class ItemUpdateSalesData extends ModelUpdate<IItemAdminListEntry> {

	public baseUnitOfMeasure!: UnitOfMeasure | null;
	public salesUnitOfMeasure!: UnitOfMeasure | null;
	public salesUnitQuantity!: number;
	public stockQuantity!: number | null;
	public isTopSeller!: boolean;
	public canOrderSample!: boolean;
	public deliveryTime!: number | null;
	public isSalesUnitFixed!: boolean;

	public constructor(model: IItemAdminListEntry) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IItemAdminListEntry): void {
		this.baseUnitOfMeasure = detailModel.baseUnitOfMeasure;
		this.salesUnitOfMeasure = detailModel.salesUnitOfMeasure;
		this.salesUnitQuantity = detailModel.salesUnitQuantity;
		this.stockQuantity = detailModel.stockQuantity;
		this.isTopSeller = detailModel.isTopSeller;
		this.canOrderSample = detailModel.canOrderSample;
		this.deliveryTime = detailModel.deliveryTime;
		this.isSalesUnitFixed = detailModel.isSalesUnitFixed;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'baseUnitOfMeasure':
				return ItemRules.baseUnitOfMeasureRules;
			case 'salesUnitOfMeasure':
				return ItemRules.salesUnitOfMeasureRules;
			case 'salesUnitQuantity':
				return ItemRules.salesUnitQuantityRules;
			case 'isTopSeller':
				return ItemRules.isTopSellerRules;
			case 'canOrderSample':
				return ItemRules.canOrderSampleRules;
			case 'isSalesUnitFixed':
				return ItemRules.isSalesUnitFixedRules;
			case 'stockQuantity':
				return ItemRules.stockQuantityRules;
			default:
				return [];
		}
	}

}
