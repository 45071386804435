import RequiredValidator from "@/validators/RequiredValidator";

export default class ShoppingListPositionRules {

	public static readonly itemIdRules = [
		(new RequiredValidator('item.singular')).validationRule(),
	]

	public static readonly quantityRules = [
		(new RequiredValidator('shoppingListPosition.quantity')).validationRule(),
	]
}
