import {ValidationRule} from "@/declarations/ValidationRule";
import ModelUpdate from "@/models/ModelUpdate";
import {IUserShop} from "@/models/user/UserShopModels";
import {IUserAdmin} from "@/models/user/UserAdminModels";
import UserRules from "@/models/user/UserRules";
import {Salutation} from "@/helpers/constants";

export default class UserUpdateName extends ModelUpdate<IUserShop | IUserAdmin> {

	public salutation!: Salutation;
	public firstname!: string;
	public lastname!: string;
	public acronym!: string | null;

	public constructor(model: IUserShop | IUserAdmin) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IUserShop | IUserAdmin): void {
		this.salutation = detailModel.salutation;
		this.firstname = detailModel.firstname;
		this.lastname = detailModel.lastname;
		this.acronym = detailModel.acronym;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'salutation':
				return UserRules.salutationRules;
			case 'firstname':
				return UserRules.firstnameRules;
			case 'lastname':
				return UserRules.lastnameRules;
			case 'acronym':
				return UserRules.acronymRules;
			default:
				return [];
		}
	}

}
