import AxiosService from "@/services/AxiosService";
import IUserServiceShop from "@/services/definition/IUserServiceShop";
import {IUserShop, IUserShopListEntry} from "@/models/user/UserShopModels";
import AvatarCreate from "@/models/attachment/AvatarCreate";
import UserListFilter from "@/models/user/UserListFilter";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import Vue from "vue";
import ICreateResponse from "@/models/ICreateResponse";
import Registration from "@/models/registration/Registration";
import UserUpdateName from "@/models/user/UserUpdateName";
import UserUpdatePersonalData from "@/models/user/UserUpdatePersonalData";
import AxiosAuthService from "@/services/implementation/AxiosAuthService";
import {Permissions, Roles} from "@/helpers/constants";

export default class AxiosUserServiceShop extends AxiosService implements IUserServiceShop {

	public static readonly BASE_URL = '/shop/users';

	protected defineBaseUrl(): string {
		return AxiosUserServiceShop.BASE_URL;
	}

	public user(id: string): Promise<IUserShop> {
		return Vue.$axios.get<IUserShop>(this.url(id)).then(res => res.data);
	}

	public users(filter: UserListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IUserShopListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['firstname', 'lastname'];
			options.sortDesc = [false, false];
		}

		return Vue.$axios.get<IPaginatedResponse<IUserShopListEntry>>(this.url(), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public updateUsername(id: string, username: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/username`, '/users'), {
			username: username,
		}).then(res => res.data);
	}

	public updatePassword(id: string, password: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/password`, '/users'), {
			password: password,
		}).then(res => res.data);
	}

	public resetPassword(username: string): Promise<null> {
		return Vue.$axios.post(this.url('reset-password', AxiosAuthService.BASE_URL), {
			username: username,
		}).then(res => res.data);
	}

	public updateName(id: string, model: UserUpdateName): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/name`, '/users'), model).then(res => res.data);
	}

	public updatePersonalData(id: string, model: UserUpdatePersonalData): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/personal-data`, '/users'), model).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id, '/users')).then(res => res.data);
	}

	public uploadAvatar(model: AvatarCreate, onProgress: (event: ProgressEvent) => void): Promise<null> {
		const formData = new FormData();
		formData.append('avatar', model.file as Blob, model.file?.name);

		return Vue.$axios.put<null>(this.url(`${model.model?.id}/avatar`, '/users'), formData, {
			onUploadProgress: onProgress,
		}).then(res => res.data);
	}

	public deleteAvatar(model: IUserShop): Promise<null> {
		return Vue.$axios.delete(this.url(`${model.id}/avatar`, '/users')).then(res => res.data);
	}

	public registration(model: Registration): Promise<ICreateResponse> {
		return Vue.$axios.post(this.url(null, '/register'), model).then(res => res.data);
	}

	public confirmRegistration(id: string, token: string, password: string): Promise<null> {
		return Vue.$axios.post(this.url(`${id}/confirm`, '/users'), {
			token: token,
			password: password,
		}).then(res => res.data);
	}

	public roles(id: string): Promise<Roles[]> {
		return Vue.$axios.get(this.url(`${id}/roles`, AxiosAuthService.BASE_URL)).then(res => res.data);
	}

	public assignRole(id: string, role: Roles): Promise<null> {
		return Vue.$axios.post(this.url(`${id}/roles`, AxiosAuthService.BASE_URL), {
			roleName: role,
		}).then(res => res.data);
	}

	public revokeRole(id: string, role: Roles): Promise<null> {
		return Vue.$axios.delete(this.url(`${id}/roles/${role}`, AxiosAuthService.BASE_URL)).then(res => res.data);
	}

	public permissions(id: string): Promise<Permissions[]> {
		return Vue.$axios.get<Permissions[]>(this.url(`${id}/permissions`, AxiosAuthService.BASE_URL)).then(res => res.data);
	}

	public assignPermission(id: string, permission: Permissions): Promise<null> {
		return Vue.$axios.post(this.url(`${id}/permissions`, AxiosAuthService.BASE_URL), {
			permissionName: permission,
		}).then(res => res.data);
	}

	public revokePermission(id: string, permission: Permissions): Promise<null> {
		return Vue.$axios.delete(this.url(`${id}/permissions/${permission}`, AxiosAuthService.BASE_URL)).then(res => res.data);
	}

}
