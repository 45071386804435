import SampleOrderAddress, {ISampleOrderAddressData} from "@/models/sample-order/SampleOrderAddress";
import {Module} from "vuex";
import {State} from "@/store";
import {IUserShop} from "@/models/user/UserShopModels";
import Snackbar from "@/helpers/Snackbar";
import ICustomerServiceShop from "@/services/definition/ICustomerServiceShop";

export interface SampleOrderFormData {
	data: ISampleOrderAddressData | null;
}

const sampleOrderFormData: Module<SampleOrderFormData, State> = {
	namespaced: true,
	state: {
		data: null as ISampleOrderAddressData | null
	},
	getters: {
		data: (state): ISampleOrderAddressData | null => {
			if ((JSON.stringify(state.data) === JSON.stringify(new SampleOrderAddress())) || state.data === null) {
				return null;
			} else {
				return state.data;
			}
		}
	},
	mutations: {
		updateModel(state, model: ISampleOrderAddressData): void {
			state.data = model;
		},
		resetModel(state): void {
			state.data = new SampleOrderAddress();
		}
	},
	actions: {
		async rehydrate(context, customerServiceShop: ICustomerServiceShop) {
			const sampleOrderAddress = new SampleOrderAddress();
			const user: IUserShop | null = context.rootGetters["user/model"];
			if (user !== null) {
				sampleOrderAddress.phone = user.phone;
				sampleOrderAddress.email = user.username;
				sampleOrderAddress.address.salutation = user.salutation;
				sampleOrderAddress.address.firstname = user.firstname;
				sampleOrderAddress.address.lastname = user.lastname;
				try {
					const customer = await customerServiceShop.customer(context.rootGetters["user/modelCustomer"]?.id);

					if (customer.defaultShippingAddress !== null) {
						sampleOrderAddress.address.company = customer.defaultShippingAddress?.company;
						sampleOrderAddress.address.street = customer.defaultShippingAddress?.street;
						sampleOrderAddress.address.houseNumber = customer.defaultShippingAddress?.houseNumber;
						sampleOrderAddress.address.poBox = customer.defaultShippingAddress?.poBox;
						sampleOrderAddress.address.zip = customer.defaultShippingAddress?.zip;
						sampleOrderAddress.address.city = customer.defaultShippingAddress?.city;
						sampleOrderAddress.address.countryCode = customer.defaultShippingAddress?.countryCode;
					}
					context.commit('updateModel', sampleOrderAddress);
				} catch {
					Snackbar.loadingError();
				}
			}
		}
	},
};
export default sampleOrderFormData;
