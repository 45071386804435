import ModelUpdate from "@/models/ModelUpdate";
import {ValidationRule} from "@/declarations/ValidationRule";
import MailTemplateRules from "@/models/mail-template/MailTemplateRules";
import {IMailTemplateAdmin} from "@/models/mail-template/MailTemplateAdminModels";

export default class MailTemplateUpdate extends ModelUpdate<IMailTemplateAdmin> {

	public subject!: string | null;
	public htmlTemplate!: string;

	public constructor(detailModel: IMailTemplateAdmin) {
		super();
		this.setDetailModel(detailModel);
	}

	protected initializeWithDetailModelData(detailModel: IMailTemplateAdmin): void {
		this.subject = detailModel.subject;
		this.htmlTemplate = detailModel.htmlTemplate;
	}

	rules(property: string): ValidationRule[] {
		switch (property) {
			case 'htmlTemplate':
				return MailTemplateRules.htmlTemplateRules;
			default:
				return [];
		}
	}

}
