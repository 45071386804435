import {RawLocation} from "vue-router";

export default class PathEntry {

	public icon!: string;
	public label!: string;
	public route!: RawLocation;

	public constructor(icon: string, label: string, route: RawLocation) {
		this.icon = icon;
		this.label = label;
		this.route = route;
	}

}
