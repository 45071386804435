import AxiosService from "@/services/AxiosService";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Vue from "vue";
import ShipmentTypeListFilter from "@/models/shipment-type/ShipmentTypeListFilter";
import LocaleHelper from "@/helpers/LocaleHelper";
import IShipmentTypeServiceShop from "@/services/definition/IShipmentTypeServiceShop";
import {IShipmentTypeShopListEntry} from "@/models/shipment-type/ShipmentTypeShopModels";

export default class AxiosShipmentTypeServiceShop extends AxiosService implements IShipmentTypeServiceShop {

	public static readonly BASE_URL = '/shop/shipment-types';
	public static readonly BASE_URL_COMMON = '/shipment-types';

	protected defineBaseUrl(): string {
		return AxiosShipmentTypeServiceShop.BASE_URL;
	}

	public shipmentTypes(filter: ShipmentTypeListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IShipmentTypeShopListEntry>> {
		const opt = new AsiListTableOptions();
		opt.sortBy = options !== null ? options.sortBy : ['name'];
		opt.sortDesc = options !== null ? options.sortDesc : [false];
		opt.page = options !== null ? options.page : 1;
		opt.itemsPerPage = options !== null ? options.itemsPerPage : 0;

		const lang = LocaleHelper.currentLanguage();
		opt.sortBy = opt.sortBy.map(field => {
			return field.startsWith('name') ? `name.${lang}` : field;
		});

		return Vue.$axios.get<IPaginatedResponse<IShipmentTypeShopListEntry>>(this.url(), {
			params: {
				...filter,
				...opt
			}
		})
			.then(res => res.data);
	}

}
