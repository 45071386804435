import ModelUpdate from "@/models/ModelUpdate";
import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
import {ValidationRule} from "@/declarations/ValidationRule";
import TranslatedValueUpdate from "@/models/translated-value/TranslatedValueUpdate";
import {CategoryView} from "@/helpers/constants";

export default class CategoryUpdateBasicData extends ModelUpdate<ICategoryAdmin> {

	public name!: TranslatedValueUpdate;
	public isTopCategory!: boolean;
	public sortIndex!: number;
	public color!: string | null;
	public view!: CategoryView;
	public defaultSortBy!: string|null;
	public defaultSortDesc!: boolean;
	public hasFrameAssembly!: boolean;

	public constructor(model: ICategoryAdmin) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: ICategoryAdmin): void {
		this.name = new TranslatedValueUpdate(detailModel.name);
		this.isTopCategory = detailModel.isTopCategory;
		this.sortIndex = detailModel.sortIndex;
		this.color = detailModel.color;
		this.view = detailModel.view;
		this.defaultSortBy = detailModel.defaultSortBy;
		this.defaultSortDesc = detailModel.defaultSortDesc;
		this.hasFrameAssembly = detailModel.hasFrameAssembly;
	}

	public rules(): ValidationRule[] {
		return [];
	}

}
