import RequiredValidator from "@/validators/RequiredValidator";
import EmailValidator from "@/validators/EmailValidator";
import {PasswordValidator} from "@/validators/PasswordValidator";
import {RangeValidator} from "@/validators/RangeValidator";
import EnumHelper from "@/helpers/EnumHelper";
import {UserState} from "@/helpers/constants";
import {PhoneValidator} from "@/validators/PhoneValidator";

export default class UserRules {

	public static readonly customerIdRules = [
		(new RequiredValidator('customer.singular')).validationRule(),
	];

	public static readonly stateRules = [
		(new RequiredValidator('user.state')).validationRule(),
		(new RangeValidator('user.state', true, EnumHelper.toArray(UserState).map(c => c.value))).validationRule(),
	];

	public static readonly salutationRules = [
		(new RequiredValidator('user.salutation')).validationRule(),
	];

	public static readonly firstnameRules = [
		(new RequiredValidator('user.firstname')).validationRule(),
	];

	public static readonly lastnameRules = [
		(new RequiredValidator('user.lastname')).validationRule(),
	];

	public static readonly localeRules = [
		(new RequiredValidator('user.locale')).validationRule(),
	];

	public static readonly timeZoneRules = [
		(new RequiredValidator('user.timeZone')).validationRule(),
	];

	public static readonly usernameRules = [
		(new RequiredValidator('ui.terms.username')).validationRule(),
		(new EmailValidator('ui.terms.username')).validationRule(),
	];

	public static readonly acronymRules = [];

	public static passwordRules = [
		(new RequiredValidator('ui.terms.password')).validationRule(),
		(new PasswordValidator('ui.terms.password')).validationRule(),
	];

	public static phoneRules(countryCode: string | null = null): any[] {
		return [
			(new PhoneValidator('user.phone', true, countryCode)).validationRule(),
		];
	}

}
