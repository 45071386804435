import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";
import TranslatedValueCreate from "@/models/translated-value/TranslatedValueCreate";
import PaymentTypeRules from "@/models/payment-type/PaymentTypeRules";
import {CustomerType} from "@/helpers/constants";

export default class PaymentTypeCreate implements IFormModel {

	public name: TranslatedValueCreate = new TranslatedValueCreate();
	public description: TranslatedValueCreate = new TranslatedValueCreate();
	public code: string | null = null;
	public paymentProviderCode: string | null = null;
	public externalId: string | null = null;
	public allowedCustomerTypes: CustomerType[] = []

	public reset(): void {
		this.name = new TranslatedValueCreate();
		this.description = new TranslatedValueCreate();
		this.code = null;
		this.paymentProviderCode = null;
		this.externalId = null;
		this.allowedCustomerTypes = [];
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'code':
				return PaymentTypeRules.codeRules;
			default:
				return [];
		}
	}

}
