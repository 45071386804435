<template>
	<div class="d-flex flex-column overflow-y-auto">
		<div class="flex-grow-1 flex-shrink-1 overflow-y-auto cart-items">
			<v-list v-if="items.length > 0" class="py-0">
				<template v-for="item in items">
					<v-divider v-if="item !== null" :key="`${item.id}-divider`"/>
					<v-list-item :key="`${item.id}-entry`" class="px-3">
						<v-list-item-avatar class="mr-2">
							<AsiAvatar :icon="icons.item" :size="32"/>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="mb-1 item-pos" @click="showItem(item)">
								{{ itemTitle(item) }}
							</v-list-item-title>
							<div class="d-flex flex-row align-center item-pos-chip-gap mb-2">
								<ItemChipNumber small :item="item" />
								<ItemChipAltNumber v-if="item.alternativeItemNumber !== null" small :item="item" />
							</div>
							<v-list-item-subtitle v-if="canDelete" class="d-flex flex-row justify-space-between align-center">
								<div class="d-flex flex-row align-center">
									<AsiBtn :icon="icons.delete" x-small @click.stop="removePosition(item)"/>
								</div>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-list>
			<div v-else class="flex-grow-1 flex-shrink-1 pa-6 text-center grey--text">
				{{ $t('sampleOrder.noSampleOrders') }}
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import ItemQuantitySelection from "@/components/item/shop/ItemQuantitySelection.vue";
	import CartPositionCustomerReferenceInput from "@/components/cart-position/CartPositionCustomerReferenceInput.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import CartTotalEntries from "@/components/cart/shop/CartTotalEntries.vue";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import Icon from "@/plugins/icons";
	import ItemHelper from "@/models/item/ItemHelper";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import {TranslateResult} from "vue-i18n";
	import {IItemShopSimple} from "@/models/item/ItemShopModels";
	import ItemChipNumber from "@/components/item/ItemChipNumber.vue";
	import ItemChipAltNumber from "@/components/item/ItemChipAltNumber.vue";

	@Component({
		components: {
			ItemChipAltNumber,
			ItemChipNumber, AsiAvatar, CartTotalEntries, AsiBtn, CartPositionCustomerReferenceInput, ItemQuantitySelection}
	})
	export default class SampleOrderCart extends Vue {

		@Prop({type: Array, required: false})
		public itemsModel!: IItemShopSimple[] | undefined

		@Prop({type: Boolean, required: false, default: true})
		public canDelete!: boolean | undefined

		private icons = Icon;

		public get items(): IItemShopSimple[] {
			if (this.itemsModel !== undefined) {
				return this.itemsModel;
			}
			return this.$store.state.sampleOrder.items;
		}

		private showItem(item: IItemShopSimple): void {
			if (this.$router.resolve(ItemHelper.detailRoute(item.itemNumber)).route.path === this.$route.path) return;
			this.$router.push(ItemHelper.detailRoute(item.itemNumber));
		}

		private itemTitle(item: IItemShopSimple): string | TranslateResult | null {
			return TranslatedValueHelper.get(item.name, this.$i18n.locale);
		}

		private removePosition(item: IItemShopSimple): void {
			this.$store.dispatch('sampleOrder/remove', item);
		}

	}
</script>

<style lang="scss" scoped>
	.item-pos:hover {
		color: var(--v-primary-base);
		cursor: pointer;
	}

	.item-pos-chip-gap{
		gap: 0.5rem;
	}


</style>
