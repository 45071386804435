<template>
	<AsiCardDetail :icon="icons.category" :title="$t('ui.terms.description')"
	               :show-action-button="!readonly" :action-button-disabled="disabled" @actionButtonClicked="updateDialog.open()">

		<div class="d-flex justify-center pt-2 pb-4">
			<v-btn-toggle v-model="locale" mandatory>
				<AsiBtn v-for="curLocale in availableLocales" :key="curLocale.value" :value="curLocale.value" small>
					{{ curLocale.text }}
				</AsiBtn>
			</v-btn-toggle>
		</div>
		<v-divider/>
		<v-card-text>
			<AsiMarkDownViewer :value="category.description[currentLocaleExtracted]"/>
		</v-card-text>

		<CategoryUpdateDialogDescription v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen" :category="category"
		                                 @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
	import Icon from "@/plugins/icons";
	import DialogHandler from "@/components/common/DialogHandler";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntryTranslatedValue from "@/components/common/AsiCardDetailEntryTranslatedValue.vue";
	import CategoryUpdateDialogBasicData from "@/components/category/admin/CategoryUpdateDialogBasicData.vue";
	import EnumHelper from "@/helpers/EnumHelper";
	import {CategoryView} from "@/helpers/constants";
	import CategoryUpdateDialogDescription from "@/components/category/admin/CategoryUpdateDialogDescription.vue";
	import AsiMarkDownViewer from "@/components/common/AsiMarkDownViewer.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import {TranslateResult} from "vue-i18n";
	import LocaleHelper from "@/helpers/LocaleHelper";

	@Component({
		components: {
			AsiBtn,
			AsiMarkDownViewer,
			CategoryUpdateDialogDescription,
			CategoryUpdateDialogBasicData,
			AsiCardDetailEntryTranslatedValue, AsiCardDetail, AsiCardDetailEntry
		}
	})
	export default class CategoryCardDescription extends Vue {

		@Prop({type: Object, required: true})
		public category!: ICategoryAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
		private locale: string = this.$i18n.locale;
		private updateDialog: DialogHandler = new DialogHandler();

		private get availableLocales(): { text: string | TranslateResult, value: string }[] {
			return LocaleHelper.supportedLocaleOptions();
		}

		private get currentLocaleExtracted(): string {
			return LocaleHelper.extractLanguage(this.locale);
		}

		private get viewValue(): string | null {
			return EnumHelper.textFromValue(CategoryView, this.category.view, true);
		}

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
