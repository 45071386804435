import {RawLocation} from "vue-router";
import {Routes} from "@/helpers/constants";

export default class SampleOrderHelper {
	public static detailRoute(): RawLocation {
		return {
			name: Routes.sampleOrder,
		};
	}
}
