import ModelUpdate from "@/models/ModelUpdate";
import {ValidationRule} from "@/declarations/ValidationRule";
import TranslatedValueUpdate from "@/models/translated-value/TranslatedValueUpdate";
import {IAttachment} from "@/models/attachment/AttachmentModels";

export default class AttachmentUpdate extends ModelUpdate<IAttachment> {

	public constructor(model: IAttachment) {
		super();
		this.setDetailModel(model);
	}

	public name!: TranslatedValueUpdate | null;

	protected initializeWithDetailModelData(detailModel: IAttachment): void {
		this.name = detailModel.name === null ? null : new TranslatedValueUpdate(detailModel.name);
	}

	public rules(): ValidationRule[] {
		return [];
	}

}
