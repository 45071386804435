import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";
import CartPositionRules from "@/models/cart-position/CartPositionRules";
import FrameAssemblyCreate from "@/models/frame-assembly/FrameAssemblyCreate";

export default class CartPositionCreate implements IFormModel {

	public itemId: string | null = null;
	public frameAssembly: FrameAssemblyCreate | null = null;
	public quantity: number | null = null;

	public reset(): void {
		this.itemId = null;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'itemId':
				return CartPositionRules.itemIdRules;
			case 'quantity':
				return CartPositionRules.quantityRules;
			default:
				return [];
		}
	}

}
