import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;

export default class AsiListTableHeader {

	public text: string | TranslateResult;
	public value: string;
	public sortable: boolean;
	public filterable: boolean;
	public align: string | null = null;
	public width: string | null = null;

	constructor(text: string | TranslateResult, value: string, sortable: boolean = false, filterable: boolean = false, align: string | null = null, width: string | null = null) {
		this.text = text;
		this.value = value;
		this.sortable = sortable;
		this.filterable = filterable;
		this.align = align;
		this.width = width;
	}

}
