import IListFilter from "@/models/IListFilter";
import StringHelper from "@/helpers/StringHelper";

export default class CategoryListFilter implements IListFilter {

	public fulltext: string | null = null;
	public isRoot: boolean | null = null;
	public name: string | null = null;
	public externalId: string | null = null;

	public clear(): void {
		this.fulltext = null;
		this.isRoot = null;
		this.name = null;
		this.externalId = null;
	}

	public hasFilter(): boolean {
		return !StringHelper.allEmpty([this.fulltext, this.name, this.externalId])
			|| this.isRoot !== null;
	}

}
