import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";
import UserRules from "@/models/user/UserRules";
import {Salutation} from "@/helpers/constants";

export default class UserCreate implements IFormModel {

	public static readonly DEFAULT_TIME_ZONE = 'Europe/Zurich';
	public static readonly DEFAULT_LOCALE = 'de-CH';

	public salutation: Salutation = Salutation.mr;
	public firstname: string | null = null;
	public lastname: string | null = null;
	public username: string | null = null;
	public phone: string | null = null;
	public timeZone: string = UserCreate.DEFAULT_TIME_ZONE;
	public locale: string = UserCreate.DEFAULT_LOCALE;

	public reset(): void {
		this.salutation = Salutation.mr;
		this.firstname = null;
		this.lastname = null;
		this.username = null;
		this.phone = null;
		this.timeZone = UserCreate.DEFAULT_TIME_ZONE;
		this.locale = UserCreate.DEFAULT_LOCALE;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'salutation':
				return UserRules.salutationRules;
			case 'firstname':
				return UserRules.firstnameRules;
			case 'lastname':
				return UserRules.lastnameRules;
			case 'username':
				return UserRules.usernameRules;
			case 'phone':
				return UserRules.phoneRules();
			case 'timeZone':
				return UserRules.timeZoneRules;
			case 'locale':
				return UserRules.localeRules;
			default:
				return [];
		}
	}

}
