import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";
import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
import TranslatedValueRules from "@/models/translated-value/TranslatedValueRules";

export default class TranslatedValueCreate implements ITranslatedValue, IFormModel {

	public de: string | null = null;
	public en: string | null = null;
	public fr: string | null = null;

	public reset(): void {
		this.de = null;
		this.en = null;
		this.fr = null;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'de':
				return TranslatedValueRules.deRules;
			default:
				return [];
		}
	}

}
