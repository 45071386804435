import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";
import PromotionRules from "@/models/promotion/PromotionRules";

export default class PromotionCreate implements IFormModel {

	public itemId: string | null = null;
	public minimumOrderPrice: number | null = null;
	public validFrom: string | null = null;
	public validTo: string | null = null;

	public reset(): void {
		this.itemId = null;
		this.minimumOrderPrice = null;
		this.validFrom = null;
		this.validTo = null;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'itemId':
				return PromotionRules.itemIdRules;
			case 'minimumOrderPrice':
				return PromotionRules.minimumOrderPriceRules;
			case 'validFrom':
				return PromotionRules.validFromRules;
			case 'validTo':
				return PromotionRules.validToRules;
			default:
				return [];
		}
	}

}
