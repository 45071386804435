import IListFilter from "@/models/IListFilter";
import StringHelper from "@/helpers/StringHelper";
import {OrderPaymentState, OrderShipmentState} from "@/helpers/constants";

export default abstract class OrderListFilter implements IListFilter {

	public static readonly defaultPaymentStateFilter = [OrderPaymentState.orderPaymentUnpaid, OrderPaymentState.orderPaymentPaid];
	public static readonly defaultShipmentStateFilter = [OrderShipmentState.orderShipmentNotShipped, OrderShipmentState.orderShipmentShipped];
	public readonly defaultPaymentStateFilter: OrderPaymentState[] = [];
	public readonly defaultShipmentStateFilter: OrderShipmentState[] = [];

	public fulltext: string | null = null;

	public paymentStates: OrderPaymentState[] = [];
	public shipmentStates: OrderShipmentState[] = [];
	public createdAtFrom: string | null = null;
	public createdAtTo: string | null = null;

	public constructor(defaultPaymentStateFilter: OrderPaymentState[] | null = null, defaultShipmentStateFilter: OrderShipmentState[] | null = null) {
		this.defaultPaymentStateFilter = defaultPaymentStateFilter === null ? OrderListFilter.defaultPaymentStateFilter : defaultPaymentStateFilter;
		this.defaultShipmentStateFilter = defaultShipmentStateFilter === null ? OrderListFilter.defaultShipmentStateFilter : defaultShipmentStateFilter;
		this.paymentStates = this.defaultPaymentStateFilter;
		this.shipmentStates = this.defaultShipmentStateFilter;
	}

	public clear(): void {
		this.fulltext = null;
		this.paymentStates = this.defaultPaymentStateFilter;
		this.shipmentStates = this.defaultShipmentStateFilter;
		this.createdAtFrom = null;
		this.createdAtTo = null;
	}

	public hasFilter(): boolean {
		if (this.paymentStates.length !== this.defaultPaymentStateFilter.length
			|| this.paymentStates.some(s => !this.defaultPaymentStateFilter.includes(s))) {
			return true;
		}

		if (this.shipmentStates.length !== this.defaultShipmentStateFilter.length
			|| this.shipmentStates.some(s => !this.defaultShipmentStateFilter.includes(s))) {
			return true;
		}

		return !StringHelper.allEmpty([this.fulltext, this.createdAtFrom, this.createdAtTo]);
	}

}
