import ModelUpdate from "@/models/ModelUpdate";
import {ICategoryAdminListEntry} from "@/models/category/CategoryAdminModels";
import {ValidationRule} from "@/declarations/ValidationRule";
import {ItemAttributeFilterVisibility, ItemAttributeVisibility} from "@/helpers/constants";

export default class CategoryUpdateItemAttributes extends ModelUpdate<ICategoryAdminListEntry> {
	public avatarAttachmentIdVisibility!: ItemAttributeVisibility;
	public priceVisibility!: ItemAttributeVisibility;

	public stockStatusVisibility!: ItemAttributeVisibility;
	public isTopSellerVisibility!: ItemAttributeVisibility;
	public canOrderSampleVisibility!: ItemAttributeVisibility;

	public grossWeightVisibility!: ItemAttributeVisibility;
	public lengthVisibility!: ItemAttributeVisibility;
	public widthVisibility!: ItemAttributeVisibility;
	public heightVisibility!: ItemAttributeVisibility;

	public materialVisibility!: ItemAttributeVisibility;
	public colorVisibility!: ItemAttributeVisibility;
	public typeVisibility!: ItemAttributeVisibility;
	public rebateVisibility!: ItemAttributeVisibility;
	public rebateSpaceVisibility!: ItemAttributeVisibility;
	public grooveWidthVisibility!: ItemAttributeVisibility;
	public grooveDepthVisibility!: ItemAttributeVisibility;
	public hardnessTypeVisibility!: ItemAttributeVisibility;
	public hardnessVisibility!: ItemAttributeVisibility;
	public propertiesVisibility!: ItemAttributeVisibility;
	public densityVisibility!: ItemAttributeVisibility;
	public connectionTypeVisibility!: ItemAttributeVisibility;
	public surfaceTreatmentVisibility!: ItemAttributeVisibility;
	public toleranceVisibility!: ItemAttributeVisibility;
	public fireProtectionStandardVisibility!: ItemAttributeVisibility;
	public foodStandardVisibility!: ItemAttributeVisibility;
	public otherStandardVisibility!: ItemAttributeVisibility;
	public fixtureVisibility!: ItemAttributeVisibility;
	public wrenchSizeVisibility!: ItemAttributeVisibility;
	public threadVisibility!: ItemAttributeVisibility;
	public threadLengthVisibility!: ItemAttributeVisibility;
	public diameterMmVisibility!: ItemAttributeVisibility;
	public applicationRangeVisibility!: ItemAttributeVisibility;
	public resistanceVisibility!: ItemAttributeVisibility;
	public systemVisibility!: ItemAttributeVisibility;
	public glassThicknessVisibility!: ItemAttributeVisibility;
	public innerDiameterVisibility!: ItemAttributeVisibility;
	public outerDiameterVisibility!: ItemAttributeVisibility;
	public ringDiameterVisibility!: ItemAttributeVisibility;
	public crossSectionVisibility!: ItemAttributeVisibility;
	public assemblyGroupVisibility!: ItemAttributeVisibility;


	public priceFilterVisibility!: ItemAttributeFilterVisibility;

	public stockStatusFilterVisibility!: ItemAttributeFilterVisibility;
	public isTopSellerFilterVisibility!: ItemAttributeFilterVisibility;
	public canOrderSampleFilterVisibility!: ItemAttributeFilterVisibility;

	public grossWeightFilterVisibility!: ItemAttributeFilterVisibility;
	public lengthFilterVisibility!: ItemAttributeFilterVisibility;
	public widthFilterVisibility!: ItemAttributeFilterVisibility;
	public heightFilterVisibility!: ItemAttributeFilterVisibility;

	public materialFilterVisibility!: ItemAttributeFilterVisibility;
	public colorFilterVisibility!: ItemAttributeFilterVisibility;
	public typeFilterVisibility!: ItemAttributeFilterVisibility;
	public rebateFilterVisibility!: ItemAttributeFilterVisibility;
	public rebateSpaceFilterVisibility!: ItemAttributeFilterVisibility;
	public grooveWidthFilterVisibility!: ItemAttributeFilterVisibility;
	public grooveDepthFilterVisibility!: ItemAttributeFilterVisibility;
	public hardnessTypeFilterVisibility!: ItemAttributeFilterVisibility;
	public hardnessFilterVisibility!: ItemAttributeFilterVisibility;
	public propertiesFilterVisibility!: ItemAttributeFilterVisibility;
	public densityFilterVisibility!: ItemAttributeFilterVisibility;
	public connectionTypeFilterVisibility!: ItemAttributeFilterVisibility;
	public surfaceTreatmentFilterVisibility!: ItemAttributeFilterVisibility;
	public toleranceFilterVisibility!: ItemAttributeFilterVisibility;
	public fireProtectionStandardFilterVisibility!: ItemAttributeFilterVisibility;
	public foodStandardFilterVisibility!: ItemAttributeFilterVisibility;
	public otherStandardFilterVisibility!: ItemAttributeFilterVisibility;
	public fixtureFilterVisibility!: ItemAttributeFilterVisibility;
	public wrenchSizeFilterVisibility!: ItemAttributeFilterVisibility;
	public threadFilterVisibility!: ItemAttributeFilterVisibility;
	public threadLengthFilterVisibility!: ItemAttributeFilterVisibility;
	public diameterMmFilterVisibility!: ItemAttributeFilterVisibility;
	public applicationRangeFilterVisibility!: ItemAttributeFilterVisibility;
	public resistanceFilterVisibility!: ItemAttributeFilterVisibility;
	public systemFilterVisibility!: ItemAttributeFilterVisibility;
	public glassThicknessFilterVisibility!: ItemAttributeFilterVisibility;
	public innerDiameterFilterVisibility!: ItemAttributeFilterVisibility;
	public outerDiameterFilterVisibility!: ItemAttributeFilterVisibility;
	public ringDiameterFilterVisibility!: ItemAttributeFilterVisibility;
	public crossSectionFilterVisibility!: ItemAttributeFilterVisibility;
	public assemblyGroupFilterVisibility!: ItemAttributeFilterVisibility;

	public constructor(model: ICategoryAdminListEntry) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: ICategoryAdminListEntry): void {
		this.avatarAttachmentIdVisibility = detailModel.avatarAttachmentIdVisibility;
		this.priceVisibility = detailModel.priceVisibility;

		this.stockStatusVisibility = detailModel.stockStatusVisibility;
		this.isTopSellerVisibility = detailModel.isTopSellerVisibility;
		this.canOrderSampleVisibility = detailModel.canOrderSampleVisibility;

		this.grossWeightVisibility = detailModel.grossWeightVisibility;
		this.lengthVisibility = detailModel.lengthVisibility;
		this.widthVisibility = detailModel.widthVisibility;
		this.heightVisibility = detailModel.heightVisibility;

		this.materialVisibility = detailModel.materialVisibility;
		this.colorVisibility = detailModel.colorVisibility;
		this.typeVisibility = detailModel.typeVisibility;
		this.rebateVisibility = detailModel.rebateVisibility;
		this.rebateSpaceVisibility = detailModel.rebateSpaceVisibility;
		this.grooveWidthVisibility = detailModel.grooveWidthVisibility;
		this.grooveDepthVisibility = detailModel.grooveDepthVisibility;
		this.hardnessTypeVisibility = detailModel.hardnessTypeVisibility;
		this.hardnessVisibility = detailModel.hardnessVisibility;
		this.propertiesVisibility = detailModel.propertiesVisibility;
		this.densityVisibility = detailModel.densityVisibility;
		this.connectionTypeVisibility = detailModel.connectionTypeVisibility;
		this.surfaceTreatmentVisibility = detailModel.surfaceTreatmentVisibility;
		this.toleranceVisibility = detailModel.toleranceVisibility;
		this.fireProtectionStandardVisibility = detailModel.fireProtectionStandardVisibility;
		this.foodStandardVisibility = detailModel.foodStandardVisibility;
		this.otherStandardVisibility = detailModel.otherStandardVisibility;
		this.fixtureVisibility = detailModel.fixtureVisibility;
		this.wrenchSizeVisibility = detailModel.wrenchSizeVisibility;
		this.threadVisibility = detailModel.threadVisibility;
		this.threadLengthVisibility = detailModel.threadLengthVisibility;
		this.diameterMmVisibility = detailModel.diameterMmVisibility;
		this.applicationRangeVisibility = detailModel.applicationRangeVisibility;
		this.resistanceVisibility = detailModel.resistanceVisibility;
		this.systemVisibility = detailModel.systemVisibility;
		this.glassThicknessVisibility = detailModel.glassThicknessVisibility;
		this.innerDiameterVisibility = detailModel.innerDiameterVisibility;
		this.outerDiameterVisibility = detailModel.outerDiameterVisibility;
		this.ringDiameterVisibility = detailModel.ringDiameterVisibility;
		this.crossSectionVisibility = detailModel.crossSectionVisibility;
		this.assemblyGroupVisibility = detailModel.assemblyGroupVisibility;

		this.priceFilterVisibility = detailModel.priceFilterVisibility;

		this.stockStatusFilterVisibility = detailModel.stockStatusFilterVisibility;
		this.isTopSellerFilterVisibility = detailModel.isTopSellerFilterVisibility;
		this.canOrderSampleFilterVisibility = detailModel.canOrderSampleFilterVisibility;

		this.grossWeightFilterVisibility = detailModel.grossWeightFilterVisibility;
		this.lengthFilterVisibility = detailModel.lengthFilterVisibility;
		this.widthFilterVisibility = detailModel.widthFilterVisibility;
		this.heightFilterVisibility = detailModel.heightFilterVisibility;

		this.materialFilterVisibility = detailModel.materialFilterVisibility;
		this.colorFilterVisibility = detailModel.colorFilterVisibility;
		this.typeFilterVisibility = detailModel.typeFilterVisibility;
		this.rebateFilterVisibility = detailModel.rebateFilterVisibility;
		this.rebateSpaceFilterVisibility = detailModel.rebateSpaceFilterVisibility;
		this.grooveWidthFilterVisibility = detailModel.grooveWidthFilterVisibility;
		this.grooveDepthFilterVisibility = detailModel.grooveDepthFilterVisibility;
		this.hardnessTypeFilterVisibility = detailModel.hardnessTypeFilterVisibility;
		this.hardnessFilterVisibility = detailModel.hardnessFilterVisibility;
		this.propertiesFilterVisibility = detailModel.propertiesFilterVisibility;
		this.densityFilterVisibility = detailModel.densityFilterVisibility;
		this.connectionTypeFilterVisibility = detailModel.connectionTypeFilterVisibility;
		this.surfaceTreatmentFilterVisibility = detailModel.surfaceTreatmentFilterVisibility;
		this.toleranceFilterVisibility = detailModel.toleranceFilterVisibility;
		this.fireProtectionStandardFilterVisibility = detailModel.fireProtectionStandardFilterVisibility;
		this.foodStandardFilterVisibility = detailModel.foodStandardFilterVisibility;
		this.otherStandardFilterVisibility = detailModel.otherStandardFilterVisibility;
		this.fixtureFilterVisibility = detailModel.fixtureFilterVisibility;
		this.wrenchSizeFilterVisibility = detailModel.wrenchSizeFilterVisibility;
		this.threadFilterVisibility = detailModel.threadFilterVisibility;
		this.threadLengthFilterVisibility = detailModel.threadLengthFilterVisibility;
		this.diameterMmFilterVisibility = detailModel.diameterMmFilterVisibility;
		this.applicationRangeFilterVisibility = detailModel.applicationRangeFilterVisibility;
		this.resistanceFilterVisibility = detailModel.resistanceFilterVisibility;
		this.systemFilterVisibility = detailModel.systemFilterVisibility;
		this.glassThicknessFilterVisibility = detailModel.glassThicknessFilterVisibility;
		this.innerDiameterFilterVisibility = detailModel.innerDiameterFilterVisibility;
		this.outerDiameterFilterVisibility = detailModel.outerDiameterFilterVisibility;
		this.ringDiameterFilterVisibility = detailModel.ringDiameterFilterVisibility;
		this.crossSectionFilterVisibility = detailModel.crossSectionFilterVisibility;
		this.assemblyGroupFilterVisibility = detailModel.assemblyGroupFilterVisibility;
	}

	public rules(): ValidationRule[] {
		return [];
	}

}
