import {ICartShopSimple} from "@/models/cart/CartShopModels";
import {RawLocation} from "vue-router";
import {Routes} from "@/helpers/constants";
import VueI18n from "vue-i18n";
import i18n from "@/plugins/i18n";
import {ICartAdminSimple} from "@/models/cart/CartAdminModels";
import TranslateResult = VueI18n.TranslateResult;

export default class CartHelper {

	public static detailRoute(id: string): RawLocation {
		return {
			name: Routes.cart,
			params: {
				id: id,
			},
		};
	}

	public static detailRouteAdmin(id: string): RawLocation {
		return {
			name: Routes.adminCart,
			params: {
				id: id,
			},
		};
	}

	public static checkoutRoute(id: string): RawLocation {
		return {
			name: Routes.checkout,
			params: {
				id: id,
			},
		};
	}

	public static label(cart: ICartShopSimple | null): string | TranslateResult {
		if (cart === null) return i18n.t('cart.singular');
		if (cart.name !== null) return cart.name;

		return i18n.t('cart.terms.fallbackLabel', {
			date: cart.createdAt === null ? '?' : i18n.d(new Date(cart.createdAt), 'dayMonthYearShort'),
		});
	}

	public static sort(a: ICartShopSimple | ICartAdminSimple, b: ICartShopSimple | ICartAdminSimple): number {
		if (a.createdAt !== null && b.createdAt !== null) return a.createdAt.localeCompare(b.createdAt) * -1;
		if (a.createdAt !== null && b.createdAt === null) return -1;
		if (a.createdAt === null && b.createdAt !== null) return 1;
		if (a.name !== null && b.name !== null) return a.name.localeCompare(b.name);
		if (a.name === null && b.name !== null) return 1;
		if (a.name !== null && b.name === null) return -1;
		if (a.updatedAt !== null && b.updatedAt !== null) return a.updatedAt.localeCompare(b.updatedAt) * -1;
		if (a.updatedAt !== null && b.updatedAt === null) return -1;
		if (a.updatedAt === null && b.updatedAt !== null) return 1;
		return 0;
	}

}
