import {RawLocation} from "vue-router";
import {Routes} from "@/helpers/constants";

export default class ShoppingListHelper {
	public static detailRouteAdmin(id: string): RawLocation {
		return {
			name: Routes.adminShoppingList,
			params: {
				id: id,
			},
		};
	}
}
