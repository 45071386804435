import IFormModel from "@/models/IFormModel";
import ShoppingListPositionCreate from "@/models/shopping-list-position/ShoppingListPositionCreate";
import {ValidationRule} from "@/declarations/ValidationRule";
import ShoppingListPositionRules from "@/models/shopping-list-position/ShoppingListPositionRules";

export default class ShoppingListCreate implements IFormModel {

	public name: string | null = null;
	public positions: ShoppingListPositionCreate[] = [];

	reset(): void {
		this.name = null;
		this.positions = [];
	}

	rules(property: string): ValidationRule[] {
		switch (property) {
			case 'itemId':
				return ShoppingListPositionRules.itemIdRules;
			case 'quantity':
				return ShoppingListPositionRules.quantityRules;
			default:
				return [];
		}
	}
}
