import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";
import AttachmentRules from "@/models/attachment/AttachmentRules";
import TranslatedValueCreate from "@/models/translated-value/TranslatedValueCreate";
import IModel from "@/models/IModel";

export default class AttachmentCreate implements IFormModel {

	public name: TranslatedValueCreate = new TranslatedValueCreate();
	public model: IModel | null = null;
	public file: File | null = null;

	public reset(): void {
		this.name = new TranslatedValueCreate();
		this.model = null;
		this.file = null;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'file':
				return AttachmentRules.fileRules;
			default:
				return [];
		}
	}

}
