import Vue from "vue";
import AxiosService from "@/services/AxiosService";
import IGlobalConfigServiceAdmin from "@/services/definition/IGlobalConfigServiceAdmin";
import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";
import GlobalConfigUpdateMinimumOrderSurcharge from "@/models/global-config/GlobalConfigUpdateMinimumOrderSurcharge";
import GlobalConfigUpdateExternalSource from "@/models/global-config/GlobalConfigUpdateExternalSource";
import GlobalConfigUpdateTrimmingCost from "@/models/global-config/GlobalConfigUpdateTrimmingCost";
import GlobalConfigUpdateBulkyGoodsSurcharge from "@/models/global-config/GlobalConfigUpdateBulkyGoodsSurcharge";

export default class AxiosGlobalConfigServiceAdmin extends AxiosService implements IGlobalConfigServiceAdmin {

	public static readonly BASE_URL = '/admin/global-config';
	public static readonly BASE_URL_COMMON = '/global-config';

	protected defineBaseUrl(): string {
		return AxiosGlobalConfigServiceAdmin.BASE_URL;
	}

	public globalConfig(): Promise<IGlobalConfigAdmin> {
		return Vue.$axios.get<IGlobalConfigAdmin>(this.url()).then(res => res.data);
	}

	public updateExternalSource(model: GlobalConfigUpdateExternalSource): Promise<null> {
		return Vue.$axios.put(this.url('private-customer-external-id', AxiosGlobalConfigServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateMinimumOrderSurcharge(model: GlobalConfigUpdateMinimumOrderSurcharge): Promise<null> {
		return Vue.$axios.put(this.url('minimum-order-surcharge', AxiosGlobalConfigServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateBulkyGoodsSurcharge(model: GlobalConfigUpdateBulkyGoodsSurcharge): Promise<null> {
		return Vue.$axios.put(this.url('bulky-goods-surcharge', AxiosGlobalConfigServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateTrimmingCost(model: GlobalConfigUpdateTrimmingCost): Promise<null> {
		return Vue.$axios.put(this.url('trimming-cost', AxiosGlobalConfigServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}
}
