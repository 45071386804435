import IFormModel from "@/models/IFormModel";
import {FrameAssemblyInstallation, FrameAssemblyMeasuringPoint, FrameAssemblyType} from "@/helpers/constants";
import {ValidationRule} from "@/declarations/ValidationRule";

export default class FrameAssemblyCreate implements IFormModel {

	public frameType: FrameAssemblyType = FrameAssemblyType.frame;
	public width: number = 1000;
	public height: number = 1000;
	public measuringPoint: FrameAssemblyMeasuringPoint = FrameAssemblyMeasuringPoint.rebate;
	public installation: FrameAssemblyInstallation = FrameAssemblyInstallation.frame;
	public description: string | null = null;

	reset(): void {
		this.description = null;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	rules(property: string): ValidationRule[] {
		return [];
	}

}
