import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";

export default abstract class ModelUpdate<T> implements IFormModel {

	private detailModel!: T;

	public setDetailModel(detailModel: T): void {
		this.detailModel = detailModel;
		this.reset();
	}

	public reset(): void {
		this.initializeWithDetailModelData(this.detailModel);
	}

	public abstract rules(property: string): ValidationRule[];

	protected abstract initializeWithDetailModelData(detailModel: T): void;

}

