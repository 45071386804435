import ModelUpdate from "@/models/ModelUpdate";
import {IAddressFields} from "@/models/address/AddressModels";
import {ValidationRule} from "@/declarations/ValidationRule";
import AddressRules from "@/models/address/AddressRules";
import {Salutation} from "@/helpers/constants";

export default class AddressUpdate extends ModelUpdate<IAddressFields> {

	public label!: string | null;
	public salutation!: Salutation;
	public title!: string | null;
	public firstname!: string | null;
	public middlename!: string | null;
	public lastname!: string | null;
	public company!: string | null;
	public companySector!: string | null;
	public role!: string | null;
	public address1!: string | null;
	public address2!: string | null;
	public street!: string | null;
	public houseNumber!: string | null;
	public poBox!: string | null;
	public zip!: string | null;
	public city!: string | null;
	public county!: string | null;
	public state!: string | null;
	public countryCode!: string | null;

	public constructor(model: IAddressFields) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IAddressFields): void {
		this.label = detailModel.label;
		this.salutation = detailModel.salutation;
		this.title = detailModel.title;
		this.firstname = detailModel.firstname;
		this.middlename = detailModel.middlename;
		this.lastname = detailModel.lastname;
		this.company = detailModel.company;
		this.companySector = detailModel.companySector;
		this.role = detailModel.role;
		this.address1 = detailModel.address1;
		this.address2 = detailModel.address2;
		this.street = detailModel.street;
		this.houseNumber = detailModel.houseNumber;
		this.poBox = detailModel.poBox;
		this.zip = detailModel.zip;
		this.city = detailModel.city;
		this.county = detailModel.county;
		this.state = detailModel.state;
		this.countryCode = detailModel.countryCode;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'salutation':
				return AddressRules.salutationRules;
			case 'firstname':
				return AddressRules.firstnameRules;
			case 'lastname':
				return AddressRules.lastnameRules;
			case 'company':
				return AddressRules.companyRules;
			case 'street':
				return AddressRules.streetRules;
			case 'houseNumber':
				return AddressRules.houseNumberRules;
			case 'zip':
				return AddressRules.zipRules;
			case 'city':
				return AddressRules.cityRules;
			case 'countryCode':
				return AddressRules.countryCodeRules;
			default:
				return [];
		}
	}


}
