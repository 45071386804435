import ItemListFilter from "@/models/item/ItemListFilter";
import {ItemState} from "@/helpers/constants";
import StringHelper from "@/helpers/StringHelper";

export default class ItemListFilterAdmin extends ItemListFilter {

	public static readonly defaultStateFilter = [ItemState.active];
	public readonly defaultStateFilterInternal: ItemState[] = [];

	public states: ItemState[] = [];
	public externalId: string | null = null;
	public relatedToItemIds: string[] = [];
	public metaDescription: string | null = null;
	public metaKeywords: string | null = null;

	public constructor(defaultStateFilter: ItemState[] | null = null) {
		super();
		this.defaultStateFilterInternal = defaultStateFilter === null ? ItemListFilterAdmin.defaultStateFilter : defaultStateFilter;
		this.states = this.defaultStateFilterInternal;
	}

	public clear() {
		super.clear();
		this.states = this.defaultStateFilterInternal;

		this.externalId = null;
		this.relatedToItemIds = [];
		this.metaDescription = null;
		this.metaKeywords = null;
	}

	public hasFilter(): boolean {
		if (super.hasFilter()
			|| this.states.length !== this.defaultStateFilterInternal.length
			|| this.states.some(s => !this.defaultStateFilterInternal.includes(s))) {
			return true;
		}

		return !StringHelper.allEmpty([
			this.externalId, this.metaDescription, this.metaKeywords
		]);
	}

}
