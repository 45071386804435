import Vue from 'vue';
import {Vue as _Vue} from "vue/types/vue";
import {VFileInput} from "vuetify/lib";
import Icon from "@/plugins/icons";

const fileInput = VFileInput as unknown as typeof _Vue;

export default Vue.extend({
	name: 'AsiFileInputSimple',
	extends: fileInput,
	props: {
		hideDetails: {
			type: String,
			default: 'auto',
		},
		filled: {
			type: Boolean,
			default: true,
		},
		chips: {
			type: Boolean,
			default: true,
		},
		showSize: {
			type: Boolean,
			default: true,
		},
		counter: {
			type: Boolean,
			default: true,
		},
		truncateLength: {
			type: Number,
			default: 30,
		},
		dense: {
			type: Boolean,
			default: true,
		},
		flat: {
			type: Boolean,
			default: true,
		},
		solo: {
			type: Boolean,
			default: true,
		},
		prependIcon: {
			type: String,
			default: Icon.upload,
		},
	},
});
