import {ValidationRule} from "@/declarations/ValidationRule";
import ModelUpdate from "@/models/ModelUpdate";
import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";

export default class TranslatedValueUpdate extends ModelUpdate<ITranslatedValue | null> implements ITranslatedValue {

	public de!: string | null;
	public en!: string | null;
	public fr!: string | null;

	public constructor(model: ITranslatedValue | null) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: ITranslatedValue | null): void {
		this.de = detailModel?.de ?? null;
		this.en = detailModel?.en ?? null;
		this.fr = detailModel?.fr ?? null;
	}

	public rules(): ValidationRule[] {
		return [];
	}

}
