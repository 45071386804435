import ModelUpdate from "@/models/ModelUpdate";
import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
import {ValidationRule} from "@/declarations/ValidationRule";
import TranslatedValueUpdate from "@/models/translated-value/TranslatedValueUpdate";

export default class CategoryUpdateDescription extends ModelUpdate<ICategoryAdmin> {

	public description!: TranslatedValueUpdate;

	public constructor(model: ICategoryAdmin) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: ICategoryAdmin): void {
		this.description = new TranslatedValueUpdate(detailModel.description);
	}

	public rules(): ValidationRule[] {
		return [];
	}

}
