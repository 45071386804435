import ModelUpdate from "@/models/ModelUpdate";
import {IItemAdminListEntry} from "@/models/item/ItemAdminModels";
import {ValidationRule} from "@/declarations/ValidationRule";
import TranslatedValueUpdate from "@/models/translated-value/TranslatedValueUpdate";
import {ItemType} from "@/helpers/constants";

export default class ItemUpdateBasicData extends ModelUpdate<IItemAdminListEntry> {

	public itemType!: ItemType;
	public remoteUrl!: string | null;
	public itemNumber!: string | null;
	public alternativeItemNumber!: string | null;
	public itemNumberShort!: string | null;
	public externalId!: string | null;
	public name!: TranslatedValueUpdate;
	public description!: TranslatedValueUpdate | null;
	public description2!: TranslatedValueUpdate | null;
	public description3!: TranslatedValueUpdate | null;
	public description4!: TranslatedValueUpdate | null;
	public description5!: TranslatedValueUpdate | null;

	public constructor(model: IItemAdminListEntry) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IItemAdminListEntry): void {
		this.itemType = detailModel.itemType;
		this.remoteUrl = detailModel.remoteUrl;
		this.itemNumber = detailModel.itemNumber;
		this.alternativeItemNumber = detailModel.alternativeItemNumber;
		this.itemNumberShort = detailModel.itemNumberShort;
		this.externalId = detailModel.externalId;
		this.name = new TranslatedValueUpdate(detailModel.name);
		this.description = new TranslatedValueUpdate(detailModel.description);
		this.description2 = new TranslatedValueUpdate(detailModel.description2);
		this.description3 = new TranslatedValueUpdate(detailModel.description3);
		this.description4 = new TranslatedValueUpdate(detailModel.description4);
		this.description5 = new TranslatedValueUpdate(detailModel.description5);
	}

	public rules(): ValidationRule[] {
		return [];
	}
}
