import Vue from "vue";
import AxiosService from "@/services/AxiosService";
import IOrderServiceShop from "@/services/definition/IOrderServiceShop";
import {IOrderShop, IOrderShopListEntry} from "@/models/order/OrderShopModels";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import IOrderPaymentResponse from "@/models/IOrderPaymentResponse";
import OrderListFilterShop from "@/models/order/OrderListFilterShop";
import ICreateResponse from "@/models/ICreateResponse";

export default class AxiosOrderServiceShop extends AxiosService implements IOrderServiceShop {

	public static readonly BASE_URL = '/shop/orders';
	public static readonly BASE_URL_COMMON = '/orders';
	public static readonly BASE_URL_PAYMENT = '/payments';

	protected defineBaseUrl(): string {
		return AxiosOrderServiceShop.BASE_URL;
	}

	public order(id: string): Promise<IOrderShop> {
		return Vue.$axios.get<IOrderShop>(this.url(id)).then(res => res.data);
	}

	public orders(filter: OrderListFilterShop | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IOrderShopListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['orderNumber'];
			options.sortDesc = [true];
		}

		return Vue.$axios.get<IPaginatedResponse<IOrderShopListEntry>>(this.url(), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public reinitializePayment(id: string, paymentTypeId: string): Promise<IOrderPaymentResponse> {
		return Vue.$axios.post(this.url(`${id}/reinitialize-payment`, AxiosOrderServiceShop.BASE_URL_COMMON), {
			paymentTypeId: paymentTypeId,
		}).then(res => res.data);
	}

	public assertPayment(paymentId: string): Promise<null> {
		return Vue.$axios.post(this.url(`${paymentId}/assert`, AxiosOrderServiceShop.BASE_URL_PAYMENT)).then(res => res.data);
	}

	public reorder(id: string): Promise<ICreateResponse> {
		return Vue.$axios.post(this.url(`${id}/reorder`, AxiosOrderServiceShop.BASE_URL_COMMON)).then(res => res.data);
	}

}
