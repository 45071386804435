import AxiosService from "@/services/AxiosService";
import IShoppingListServiceAdmin from "@/services/definition/IShoppingListServiceAdmin";
import ShoppingListPositionCreate from "@/models/shopping-list-position/ShoppingListPositionCreate";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import {IShoppingListAdmin, IShoppingListAdminListEntry} from "@/models/shopping-list/ShoppingListAdminModels";
import ShoppingListListFilter from "@/models/shopping-list/ShoppingListListFilter";
import ICreateResponse from "@/models/ICreateResponse";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Vue from "vue";

export default class AxiosShoppingListServiceAdmin extends AxiosService implements IShoppingListServiceAdmin {

	public static readonly BASE_URL = '/shopping-lists';
	public static readonly BASE_URL_ADMIN = '/admin/shopping-lists';

	public addPosition(id: string, model: ShoppingListPositionCreate): Promise<ICreateResponse> {
		return Vue.$axios.put(this.url(`${id}/positions`, AxiosShoppingListServiceAdmin.BASE_URL), model).then(res => res.data);
	}

	protected defineBaseUrl(): string {
		return AxiosShoppingListServiceAdmin.BASE_URL;
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id, AxiosShoppingListServiceAdmin.BASE_URL)).then(res => res.data);
	}

	public shoppingList(id: string): Promise<IShoppingListAdmin> {
		return Vue.$axios.get<IShoppingListAdmin>(this.url(id, AxiosShoppingListServiceAdmin.BASE_URL_ADMIN)).then(res => res.data);
	}

	public shoppingLists(filter: ShoppingListListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IShoppingListAdminListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['name'];
			options.sortDesc = [false];
		}

		return Vue.$axios.get<IPaginatedResponse<IShoppingListAdminListEntry>>(this.url(null, AxiosShoppingListServiceAdmin.BASE_URL_ADMIN), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public updateName(id: string, name: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/name`, AxiosShoppingListServiceAdmin.BASE_URL), {
			name: name,
		}).then(res => res.data);
	}
}
