import {CustomerState, CustomerType} from "@/helpers/constants";
import IListFilter from "@/models/IListFilter";
import StringHelper from "@/helpers/StringHelper";

export default class CustomerListFilter implements IListFilter {

	public static readonly defaultStateFilter = [CustomerState.active];
	public readonly defaultStateFilterInternal: CustomerState[] = [];

	public fulltext: string | null = null;

	public states: CustomerState[] = [];
	public name: string | null = null;
	public types: CustomerType[] = [];
	public notes: string | null = null;

	public constructor(defaultStateFilter: CustomerState[] | null = null) {
		this.defaultStateFilterInternal = defaultStateFilter === null ? CustomerListFilter.defaultStateFilter : defaultStateFilter;
		this.states = this.defaultStateFilterInternal;
	}

	public clear(): void {
		this.fulltext = null;
		this.states = this.defaultStateFilterInternal;
		this.name = null;
		this.types = [];
		this.notes = null;
	}

	public hasFilter(): boolean {
		if (this.states.length !== this.defaultStateFilterInternal.length
			|| this.states.some(s => !this.defaultStateFilterInternal.includes(s))) {
			return true;
		}

		return !StringHelper.allEmpty([this.fulltext, this.name, this.notes])
			|| this.types.length > 0;
	}

}
