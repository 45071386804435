import * as Msal from "msal";
import {AuthenticationParameters} from "msal";

export default class MsalHelper {

	//createMsalInstance
	public static createInstance(clientId: string): Msal.UserAgentApplication {
		const msalConfig: Msal.Configuration = {
			auth: {
				clientId: clientId,
				authority: `https://login.microsoftonline.com/common`,
				redirectUri: window.location.origin + '/login',
				postLogoutRedirectUri: window.location.origin + '/login',
			},
		};
		return new Msal.UserAgentApplication(msalConfig);
	}

	//createMsalAuthParams
	public static createAuthParams(): AuthenticationParameters {
		return {
			forceRefresh: true,
			scopes: ['user.read', 'email'],
			extraScopesToConsent: ['email']
		};
	}

}
