import Vue, {PluginObject} from "vue";
import {Tenant} from "@/models/ui/Tenant";

const isMkShop = parseInt(process.env.VUE_APP_TENANT) == Tenant.mk;
const isHerzogShop = parseInt(process.env.VUE_APP_TENANT) == Tenant.herzog;
const isTenant = parseInt(process.env.VUE_APP_TENANT) == Tenant.herzog ? Tenant.herzog : Tenant.mk;

const Plugin = {
	install: function (vueInstance: typeof Vue): void {
		vueInstance.$isMkShop = isMkShop;
		vueInstance.$isHerzogShop = isHerzogShop;
		vueInstance.$isTenant = isTenant;

		Object.defineProperties(vueInstance.prototype, {
			$isMkShop: {
				get() {
					return isMkShop;
				}
			},
			$isHerzogShop: {
				get() {
					return isHerzogShop;
				}
			},
			$isTenant: {
				get() {
					return isTenant;
				}
			},
		});
	}
} as PluginObject<any>;

Vue.use(Plugin.install);

export default Plugin;
