import IListFilter from "@/models/IListFilter";
import StringHelper from "@/helpers/StringHelper";

export default class ShipmentTypeListFilter implements IListFilter {

	public fulltext: string | null = null;

	public name: string | null = null;

	public clear(): void {
		this.fulltext = null;
		this.name = null;
	}

	public hasFilter(): boolean {
		return !StringHelper.allEmpty([this.fulltext, this.name]);
	}

}
