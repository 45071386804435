import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";
import CartPositionCreate from "@/models/cart-position/CartPositionCreate";

export default class CartCreate implements IFormModel {

	public customerId: string | null = null;
	public name: string | null = null;
	public positions: CartPositionCreate[] = [];

	public reset(): void {
		this.customerId = null;
		this.name = null;
		this.positions = [];
	}

	public rules(): ValidationRule[] {
		return [];
	}

}
