import RequiredValidator from "@/validators/RequiredValidator";
import {NumberValidator} from "@/validators/NumberValidator";

export default class PromotionRules {

	public static readonly itemIdRules = [
		(new RequiredValidator('item.singular')).validationRule(),
	];

	public static readonly minimumOrderPriceRules = [
		(new RequiredValidator('promotion.minimumOrderPrice')).validationRule(),
		(new NumberValidator('promotion.minimumOrderPrice', true, 0)).validationRule(),
	];

	public static readonly validFromRules = [
		(new RequiredValidator('promotion.validFrom')).validationRule(),
	];

	public static readonly validToRules = [
		(new RequiredValidator('promotion.validTo')).validationRule(),
	];

}
