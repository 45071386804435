import RequiredValidator from "@/validators/RequiredValidator";

export default class AddressRules {

	public static readonly salutationRules = [
		(new RequiredValidator('address.salutation')).validationRule(),
	];

	public static readonly firstnameRules = [
		(new RequiredValidator('address.firstname')).validationRule(),
	];

	public static readonly lastnameRules = [
		(new RequiredValidator('address.lastname')).validationRule(),
	];

	public static readonly companyRules = [
		(new RequiredValidator('address.company')).validationRule(),
	];

	public static readonly streetRules = [
		(new RequiredValidator('address.street')).validationRule(),
	];

	public static readonly houseNumberRules = [
		(new RequiredValidator('address.houseNumber')).validationRule(),
	];

	public static readonly zipRules = [
		(new RequiredValidator('address.zip')).validationRule(),
	];

	public static readonly cityRules = [
		(new RequiredValidator('address.city')).validationRule(),
	];

	public static readonly countryCodeRules = [
		(new RequiredValidator('address.countryCode')).validationRule(),
	];

}
