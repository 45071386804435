import AxiosService from "@/services/AxiosService";
import IItemServiceAdmin from "@/services/definition/IItemServiceAdmin";
import {IItemAdmin, IItemAdminListEntry} from "@/models/item/ItemAdminModels";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Vue from "vue";
import ItemListFilterAdmin from "@/models/item/ItemListFilterAdmin";
import LocaleHelper from "@/helpers/LocaleHelper";
import AttachmentCreate from "@/models/attachment/AttachmentCreate";
import ICreateResponse from "@/models/ICreateResponse";
import AttachmentUpdate from "@/models/attachment/AttachmentUpdate";
import {ItemState} from "@/helpers/constants";
import ItemUpdateBasicData from "@/models/item/ItemUpdateBasicData";
import ItemUpdateSalesData from "@/models/item/ItemUpdateSalesData";
import ItemUpdateAttributes from "@/models/item/ItemUpdateAttributes";
import ItemUpdateMetaData from "@/models/item/ItemUpdateMetaData";

export default class AxiosItemServiceAdmin extends AxiosService implements IItemServiceAdmin {

	public static readonly BASE_URL = '/admin/items';
	public static readonly BASE_URL_COMMON = '/items';

	protected defineBaseUrl(): string {
		return AxiosItemServiceAdmin.BASE_URL;
	}

	public item(id: string): Promise<IItemAdmin> {
		return Vue.$axios.get<IItemAdmin>(this.url(id)).then(res => res.data);
	}

	public items(filter: ItemListFilterAdmin | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IItemAdminListEntry>> {
		const opt = new AsiListTableOptions();
		opt.sortBy = options !== null ? options.sortBy : ['name'];
		opt.sortDesc = options !== null ? options.sortDesc : [false];
		opt.page = options !== null ? options.page : 1;
		opt.itemsPerPage = options !== null ? options.itemsPerPage : 0;

		const lang = LocaleHelper.currentLanguage();
		opt.sortBy = opt.sortBy.map(field => {
			return field.startsWith('name') ? `name.${lang}` : field;
		});

		return Vue.$axios.get<IPaginatedResponse<IItemAdminListEntry>>(this.url(), {
			params: {
				...filter,
				...opt
			}
		}).then(res => res.data);
	}

	public updateState(id: string, state: ItemState): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/state`, AxiosItemServiceAdmin.BASE_URL_COMMON), {
			state: state,
		}).then(res => res.data);
	}

	public updateBasicData(id: string, model: ItemUpdateBasicData): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/basic-data`, AxiosItemServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateSalesData(id: string, model: ItemUpdateSalesData): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/sales-data`, AxiosItemServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateMetaData(id: string, model: ItemUpdateMetaData): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/meta-data`, AxiosItemServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateAttributes(id: string, model: ItemUpdateAttributes): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/item-attribute`, AxiosItemServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public uploadAdditionalAttachment(model: AttachmentCreate, onProgress: ((event: ProgressEvent) => void)): Promise<ICreateResponse> {
		if (model.model === null || model.file === null) {
			console.error('model and file must be set to upload attachment');
		}

		const formData = new FormData();
		formData.append('attachment', model.file as Blob, model.file?.name);

		return Vue.$axios.post<ICreateResponse>(this.url(`${model.model?.id}/additional-attachments`, AxiosItemServiceAdmin.BASE_URL_COMMON), formData, {
			onUploadProgress: onProgress,
		}).then(res => res.data);
	}

	public deleteAdditionalAttachment(subjectId: string, attachmentId: string): Promise<null> {
		return Vue.$axios.delete(this.url(`${subjectId}/additional-attachments/${attachmentId}`, AxiosItemServiceAdmin.BASE_URL_COMMON)).then(res => res.data);
	}

	public updateAdditionalAttachment(itemId: string, attachmentId: string, model: AttachmentUpdate): Promise<null> {
		return Vue.$axios.put(this.url(`${itemId}/additional-attachments/${attachmentId}/name`, AxiosItemServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public uploadGalleryImage(model: AttachmentCreate, onProgress: ((event: ProgressEvent) => void)): Promise<ICreateResponse> {
		if (model.model === null || model.file === null) {
			console.error('model and file must be set to upload image gallery attachment');
		}

		const formData = new FormData();
		formData.append('attachment', model.file as Blob, model.file?.name);

		return Vue.$axios.post<ICreateResponse>(this.url(`${model.model?.id}/gallery-attachments`, AxiosItemServiceAdmin.BASE_URL_COMMON), formData, {
			onUploadProgress: onProgress,
		}).then(res => res.data);
	}

	public setGalleryImageAvatar(subjectId: string, imageId: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${subjectId}/avatar`, AxiosItemServiceAdmin.BASE_URL_COMMON), {
			avatarId: imageId,
		}).then(res => res.data);
	}

	public deleteGalleryImage(subjectId: string, imageId: string): Promise<null> {
		return Vue.$axios.delete(this.url(`${subjectId}/gallery-attachments/${imageId}`, AxiosItemServiceAdmin.BASE_URL_COMMON)).then(res => res.data);
	}

	public addRelatedItem(id: string, relatedItemId: string): Promise<ICreateResponse> {
		return Vue.$axios.post<ICreateResponse>(this.url(`${id}/related-items`, AxiosItemServiceAdmin.BASE_URL_COMMON), {
			relatedItemId: relatedItemId
		}).then(res => res.data);
	}

	public deleteRelatedItem(id: string, relatedItemId: string): Promise<null> {
		return Vue.$axios.delete(this.url(`${id}/related-items/${relatedItemId}`, AxiosItemServiceAdmin.BASE_URL_COMMON)).then(res => res.data);
	}
}
