import ModelUpdate from "@/models/ModelUpdate";
import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";
import {ValidationRule} from "@/declarations/ValidationRule";

export default class GlobalConfigUpdateBulkyGoodsSurcharge extends ModelUpdate<IGlobalConfigAdmin> {

	public amount!: number | null;

	public constructor(model: IGlobalConfigAdmin) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IGlobalConfigAdmin): void {
		this.amount = detailModel.bulkyGoodsSurchargeAmount?.amount ?? null;
	}

	public rules(): ValidationRule[] {
		return [];
	}

}
