import IListFilter from "@/models/IListFilter";
import StringHelper from "@/helpers/StringHelper";

export default class ShoppingListListFilter implements IListFilter {

	public fulltext: string | null = null;

	public name: string | null = null;
	public customerName: string | null = null;
	public userName: string | null = null;

	public clear(): void {
		this.fulltext = null;
		this.name = null;
		this.customerName = null;
		this.userName = null;
	}

	public hasFilter(): boolean {
		return !StringHelper.allEmpty([this.fulltext, this.name, this.customerName, this.userName]);
	}

}
