import AxiosService from "@/services/AxiosService";
import Vue from "vue";
import ICountryService from "@/services/definition/ICountryService";

export default class AxiosCountryService extends AxiosService implements ICountryService {

	public static readonly BASE_URL = '/countries';

	protected defineBaseUrl(): string {
		return AxiosCountryService.BASE_URL;
	}

	public countries(): Promise<string[]> {
		return Vue.$axios.get<string[]>(this.url()).then(res => res.data);
	}

}
