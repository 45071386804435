export default class MailTemplateInfo {

	public fieldName!: string;
	public fieldDescription!: string | null;

	constructor(fieldName: string, fieldDescription: string | null) {
		this.fieldName = fieldName;
		this.fieldDescription = fieldDescription;
	}
}
