import ICartServiceShop from "@/services/definition/ICartServiceShop";
import CartPositionCreate from "@/models/cart-position/CartPositionCreate";
import ICreateResponse from "@/models/ICreateResponse";
import AxiosService from "@/services/AxiosService";
import Vue from "vue";
import {ICartShop, ICartShopListEntry} from "@/models/cart/CartShopModels";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import CartListFilter from "@/models/cart/CartListFilter";
import CartCreate from "@/models/cart/CartCreate";
import {IAddressFields} from "@/models/address/AddressModels";
import IOrderPaymentResponse from "@/models/IOrderPaymentResponse";
import FrameAssemblyUpdate from "@/models/frame-assembly/FrameAssemblyUpdate";

export default class AxiosCartServiceShop extends AxiosService implements ICartServiceShop {

	public static readonly BASE_URL = '/shop/carts';
	public static readonly BASE_URL_COMMON = '/carts';

	protected defineBaseUrl(): string {
		return AxiosCartServiceShop.BASE_URL;
	}

	public cart(id: string): Promise<ICartShop> {
		return Vue.$axios.get<ICartShop>(this.url(id)).then(res => res.data);
	}

	public carts(filter: CartListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<ICartShopListEntry>> {
		if (options === null) {
			options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.sortBy = ['name'];
			options.sortDesc = [false];
		}

		return Vue.$axios.get<IPaginatedResponse<ICartShopListEntry>>(this.url(), {
			params: {
				...filter,
				...options
			}
		})
			.then(res => res.data);
	}

	public create(model: CartCreate): Promise<ICreateResponse> {
		return Vue.$axios.post(this.url(null, AxiosCartServiceShop.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateCustomer(cartId: string, customerId: string): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/customer`, AxiosCartServiceShop.BASE_URL_COMMON), {
			customerId: customerId,
		}).then(res => res.data);
	}

	public updateName(cartId: string, name: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/name`, AxiosCartServiceShop.BASE_URL_COMMON), {
			name: name,
		}).then(res => res.data);
	}

	public updateOneTimeEmail(cartId: string, email: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/one-time-email`, AxiosCartServiceShop.BASE_URL_COMMON), {
			email: email,
		}).then(res => res.data);
	}

	public updateOneTimePhone(cartId: string, phone: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/one-time-phone`, AxiosCartServiceShop.BASE_URL_COMMON), {
			phone: phone,
		}).then(res => res.data);
	}

	public updateRemark(id: string, remark: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/remark`, AxiosCartServiceShop.BASE_URL_COMMON), {
			remark: remark,
		}).then(res => res.data);
	}

	public updateShipmentType(id: string, shipmentTypeId: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/shipment-type`, AxiosCartServiceShop.BASE_URL_COMMON), {
			shipmentTypeId: shipmentTypeId,
		}).then(res => res.data);
	}

	public updatePaymentType(id: string, paymentTypeId: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/payment-type`, AxiosCartServiceShop.BASE_URL_COMMON), {
			paymentTypeId: paymentTypeId,
		}).then(res => res.data);
	}

	public updateCustomerReference(id: string, customerReference: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/customer-reference`, AxiosCartServiceShop.BASE_URL_COMMON), {
			customerReference: customerReference,
		}).then(res => res.data);
	}

	public updateShippingAddress(cartId: string, addressId: string | null, addressFields: IAddressFields = {} as IAddressFields): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/shipping-address`, AxiosCartServiceShop.BASE_URL_COMMON), {
			addressId: addressId,
			...addressFields,
		}).then(res => res.data);
	}

	public updateBillingAddress(cartId: string, addressId: string | null, addressFields: IAddressFields = {} as IAddressFields): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/billing-address`, AxiosCartServiceShop.BASE_URL_COMMON), {
			addressId: addressId,
			...addressFields,
		}).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id, AxiosCartServiceShop.BASE_URL_COMMON)).then(res => res.data);
	}

	public checkout(id: string): Promise<IOrderPaymentResponse> {
		return Vue.$axios.post(this.url(`${id}/checkout`, AxiosCartServiceShop.BASE_URL_COMMON)).then(res => res.data);
	}

	public addPosition(id: string, model: CartPositionCreate): Promise<ICreateResponse> {
		return Vue.$axios.post(this.url(`${id}/positions`, AxiosCartServiceShop.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateQuantity(cartId: string, positionId: string, quantity: number): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/positions/${positionId}/quantity`, AxiosCartServiceShop.BASE_URL_COMMON), {
			quantity: quantity,
		}).then(res => res.data);
	}

	public updateFrameAssembly(cartId: string, positionId: string, frameAssembly: FrameAssemblyUpdate|null): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/positions/${positionId}/frame-assembly`, AxiosCartServiceShop.BASE_URL_COMMON), {
			frameAssembly: frameAssembly,
		}).then(res => res.data);
	}

	public updatePositionCustomerReference(cartId: string, positionId: string, customerReference: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${cartId}/positions/${positionId}/customer-reference`, AxiosCartServiceShop.BASE_URL_COMMON), {
			customerReference: customerReference,
		}).then(res => res.data);
	}

	public removePosition(cartId: string, positionId: string): Promise<null> {
		return Vue.$axios.delete(this.url(`${cartId}/positions/${positionId}`, AxiosCartServiceShop.BASE_URL_COMMON)).then(res => res.data);
	}

	public clearPositions(cartId: string): Promise<null> {
		return Vue.$axios.delete(this.url(`${cartId}/positions`, AxiosCartServiceShop.BASE_URL_COMMON)).then(res => res.data);
	}

}
