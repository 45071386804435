import {ValidationRule} from "@/declarations/ValidationRule";
import ModelUpdate from "@/models/ModelUpdate";
import {IUserShop} from "@/models/user/UserShopModels";
import {IUserAdmin} from "@/models/user/UserAdminModels";
import UserRules from "@/models/user/UserRules";

export default class UserUpdatePersonalData extends ModelUpdate<IUserShop | IUserAdmin> {

	public timeZone!: string;
	public locale!: string;

	public constructor(model: IUserShop | IUserAdmin) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IUserShop | IUserAdmin): void {
		this.timeZone = detailModel.timeZone;
		this.locale = detailModel.locale;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'timeZone':
				return UserRules.timeZoneRules;
			case 'locale':
				return UserRules.localeRules;
			default:
				return [];
		}
	}

}
