import IFormModel from "@/models/IFormModel";
import {Salutation} from "@/helpers/constants";
import {ValidationRule} from "@/declarations/ValidationRule";
import ContactFormRules from "@/models/contact/ContactFormRules";

export default class ContactFormCreate implements IFormModel {

	public company: string | null = null;
	public salutation: Salutation = Salutation.mr;
	public firstname: string | null = null;
	public lastname: string | null = null;
	public street: string | null = null;
	public houseNumber: string | null = null;
	public zip: string | null = null;
	public city: string | null = null;
	public phone: string | null = null;
	public email: string | null = null;
	public message: string | null = null;
	public locale: string | null = null;

	reset(): void {
		this.company = null;
		this.salutation = Salutation.mr;
		this.firstname = null;
		this.lastname = null;
		this.street = null;
		this.houseNumber = null;
		this.zip = null;
		this.city = null;
		this.email = null;
		this.phone = null;
		this.message = null;
		this.locale = null;
	}

	rules(property: string): ValidationRule[] {
		switch (property) {
			case 'salutation':
				return ContactFormRules.salutationRules;
			case 'firstname':
				return ContactFormRules.firstnameRules;
			case 'lastname':
				return ContactFormRules.lastnameRules;
			case 'street':
				return ContactFormRules.streetRules;
			case 'houseNumber':
				return ContactFormRules.houseNumberRules;
			case 'zip':
				return ContactFormRules.zipRules;
			case 'city':
				return ContactFormRules.cityRules;
			case 'phone':
				return ContactFormRules.phoneRules;
			case 'email':
				return ContactFormRules.emailRules;
			case 'message':
				return ContactFormRules.messageRules;
			default:
				return [];
		}
	}
}
