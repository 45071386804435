import RequiredValidator from "@/validators/RequiredValidator";

export default class CartPositionRules {

	public static readonly itemIdRules = [
		(new RequiredValidator('item.singular')).validationRule(),
	];

	public static readonly quantityRules = [
		(new RequiredValidator('cartPosition.quantity')).validationRule(),
	];

}
